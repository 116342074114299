import React, { useState, useEffect } from "react"

import "katex/dist/katex.min.css"
import TeX from "@matejmazur/react-katex"
const _ = String.raw

const EquationComponent = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { extended, content, idChapterContent } = componentInfo
  const [value, setValue] = useState(content)

  function handleChange(event) {
    inteligentChangeSensorAlpha()
    setValue(event.target.value)
    updateComponent(event.target.value)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(value)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 500
      }

      return component
    })

    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div className="math_component_center">
        <h5>Wpisz wyrażenie matematyczne</h5>
        <textarea
          className="math_component_area_resize"
          value={value === "undefined" ? " " : value}
          onChange={handleChange}
          spellCheck={false}
        />
        {/* You can pass styles or classNames */}
        <h5 style={{ marginTop: "30px" }}>Wynik:</h5>
        <TeX
          block
          className="output"
          // you can change directly KaTeX options!
          settings={{ macros: { "*": _`\cdot` } }}
        >
          {value === "undefined" ? " " : value}
        </TeX>
        <h5>Podstawowe działania</h5>
        <div className="equation_instruction">
          <ul>
            <li className="equation_ul-margin">
              Ułamki: Wpisujemy \frac oraz licznik i mianownik{" "}
              <span className="equation_bold">
                np: \frac
                {"{30}"}
                {"{10}"}
              </span>
            </li>
            <li className="equation_ul-margin">
              Pierwiastki: Wpisujemy \sqrt oraz liczbe{" "}
              <span className="equation_bold">np: \sqrt40</span>
            </li>
            <li className="equation_ul-margin">
              Procenty: Wpisujemy \%{" "}
              <span className="equation_bold">np: 60\%</span>
            </li>
          </ul>
          <div>
            <ul>
              <li className="equation_ul-margin">
                Index dolny: Do liczby dodajemy _zmienna{" "}
                <span className="equation_bold">np: 40_3</span>
              </li>

              <li className="equation_ul-margin">
                Potęga: Do liczby dodajmy strzałkę do góry ^
                <span className="equation_bold"> np: 4^3</span>{" "}
              </li>
              <li className="equation_ul-margin">
                tekst: Wpisujemy \text i treść w nawiasach klamrowych, zamiast
                spacji używamy kropki{" "}
                <span className="equation_bold">
                  np: \text
                  {"{przykladowy.tekst}"}
                </span>
              </li>
              <li>
                Dwa indeksy: Wpisujemy zmienną oraz w nawiasach wąsatych
                pierwszą wartość dolną odzielamy ją \atop oraz wpisujemy wartość
                górną{" "}
                <span className="equation_bold">
                  np:
                  {"5" + "{" + "1" + "\\" + "atop2" + "}"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <h5>Pomoc </h5>
        <a
          href="https://katex.org/docs/supported.html#operators"
          target="_blank"
        >
          https://katex.org/docs/supported.html#operators
        </a>
      </div>
    </React.Fragment>
  )
}
export default EquationComponent
