import { Table, Col, CardTitle } from "reactstrap"
import buildFormData from "./../../functions/buildFormData"

import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import { StoreContext } from "./../../context/Store"
import Loader from "./../UI/Loader"

const TeacherTab = ({ idUser, usersData, isUserLoading }) => {
  const { api } = useContext(StoreContext)
  const queryClient = useQueryClient()

  const acceptSchool = useMutation(
    "teacher_school_premission",
    selectedSchool =>
      api.post(
        "/admin/users/activate_teacher_school",
        buildFormData({
          idUser: selectedSchool.idUser,
          idSchool: selectedSchool.idSchool,
        })
      ),
    {
      enabled: idUser ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        queryClient.invalidateQueries(["user", idUser])
      },
    }
  )

  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }
  const tabSubjectData = usersData?.data[0]?.subjects?.map(subjects => (
    <ul>
      <li className="me-1">{subjects?.name}</li>
    </ul>
  ))

  const tabSchoolsData = usersData?.data[0]?.schools?.map(schools => (
    <ul>
      <li className="teacher_school_li">
        {schools?.name}
        {schools?.active === 0 ? (
          <button
            type="button"
            className="btn btn-primary ms-5"
            onClick={() => {
              let currentPreson = {
                idUser: idUser,
                idSchool: schools.idSchool,
              }
              acceptSchool.mutate(currentPreson)
            }}
          >
            Zatwierdź szkołe
          </button>
        ) : (
          <span className="teacher_school_accepted">SZKOŁA ZATWIERDZONA</span>
        )}
      </li>
    </ul>
  ))
  return (
    <div style={styles}>
      <Col md="12">
        <CardTitle className="h4 pb-3">Lista szkół</CardTitle>
        {isUserLoading ? (
          <Loader />
        ) : (
          <Table className="table-bordered ">
            <thead>
              <tr>
                <th>Szkoły</th>

                <th>Przedmioty</th>
              </tr>
            </thead>
            <tbody>
              <>
                <tr>
                  <td>{tabSchoolsData}</td>
                  <td>{tabSubjectData}</td>
                </tr>
              </>
            </tbody>
          </Table>
        )}
      </Col>
    </div>
  )
}
export default TeacherTab
