import React, { useEffect, useRef } from "react"

import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import CustomTestComponentRenderer from "../../../../../../components/CustomNodeContentRenderer/CustomTestComponentRenderer"

import { useState } from "react"
import { Input, Label } from "reactstrap"
import PointCounter from "./PointCounter"
import Wysiwgw from "components/Common/Wyswigw"

const QuestionOrder = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  chapterType,
  inteligentChangeSensorAlpha,
  invalidAnswers,
  chapterComponentsToDelete,
}) => {
  const divRef = useRef()
  const { extended, content, idChapterContent } = componentInfo
  const [options, setOptions] = useState(
    content
      ? content?.options
      : [
          {
            option: "",
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
            order: 0,
          },
          {
            option: "",
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
            order: 1,
          },
        ]
  )
  const [question, setQuestion] = useState(content ? content?.question : "")
  const [points, setPoints] = useState(content ? content?.score : 1)

  useEffect(() => {
    setQuestion(content ? content?.question : "")
  }, [chapterComponentsToDelete?.length])

  const updateComponent = (options, question) => {
    let height = 0

    switch (chapterType) {
      case "stage_test":
        height = options ? options?.length * 90 + 600 : 310
        break
      default:
        height = options ? options?.length * 90 + 550 : 180
    }
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = {
          options: options,
          question: question,
          score: points,
        }
        component.height = height
      }
      return component
    })
    setChapterComponents(newComponents)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(options, question, points)
    }
  }, [extended])

  useEffect(() => {
    updateComponent(options, question, points)
  }, [points])

  const addOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const newOptions = addNodeUnderParent({
      treeData: options,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        option: "",
        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
    }).treeData
    setOptions(newOptions)
    updateComponent(newOptions, question, points)
    setPoints(points)
  }
  const enoughAnswers = () => {
    return componentInfo?.content?.options?.length <= 2
  }

  const changeListElement = (tree, id, value) => {
    tree = tree?.map((element, index) => {
      if (index === id) {
        value = [...value]
        value = value.filter(char => char != "\n")

        return { option: value.join("") }
      } else {
        return element
      }
    })
    return tree
  }

  const handleChangeName = (index, value) => {
    inteligentChangeSensorAlpha()
    invalidAnswers()
    let tree = options.map(option => option)
    tree = changeListElement(tree, index, value)
    setOptions(tree)
  }

  const handleOnBlur = () => {
    updateComponent(options, question)
  }

  const removeOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const remove = removeNodeAtPath({
      treeData: options,
      path: rowInfo.path,
      getNodeKey,
    })

    setOptions(remove)
    updateComponent(remove, question)
  }

  function handleChangeQuestion(event, editor) {
    inteligentChangeSensorAlpha()
    const data = editor.getData()
    setQuestion(data)
    updateComponent(options, data)
  }

  const getNodeKey = ({ treeIndex }) => treeIndex
  const componentHeight = options ? options?.length * 90 : 90

  if (!extended) {
    return null
  }
  return (
    <React.Fragment>
      <div className="componentField">
        <Label>Pytanie</Label>
        <Wysiwgw value={question} content={handleChangeQuestion}></Wysiwgw>
      </div>
      {chapterType === "stage_test" ? (
        <div className="points">
          <Label>Punkty za poprawną odpowiedź</Label>
          <PointCounter
            inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
            chapterType={chapterType}
            element={points}
            setPoints={setPoints}
          />
        </div>
      ) : null}
      <div className="componentField">
        <Label>Odpowiedzi</Label>
      </div>
      <div style={{ height: componentHeight + 100 + "px" }} ref={divRef}>
        <button
          disabled={options?.length > 9}
          onClick={() => addOption({ path: [], node: { parent: 0 } })}
          className="btn btn-success"
        >
          Dodaj
        </button>
        <SortableTree
          nodeContentRenderer={CustomTestComponentRenderer}
          treeData={options}
          onChange={treeData => {
            inteligentChangeSensorAlpha()
            setOptions(treeData, points)
            updateComponent(treeData, question)
          }}
          maxDepth={1}
          rowHeight={90}
          generateNodeProps={rowInfo => {
            rowInfo.node.order = rowInfo.treeIndex
            let nodeProps = {
              title: (
                <Input
                  type="textarea"
                  maxLength={140}
                  rows={2}
                  className="question_order_input"
                  value={rowInfo.node.option}
                  placeholder={"Nowy komponent"}
                  resize="none"
                  onChange={e =>
                    handleChangeName(rowInfo.treeIndex, e.target.value)
                  }
                  onBlur={handleOnBlur}
                  style={{ border: "none" }}
                />
              ),

              subtitle:
                rowInfo.node.option?.length < 160 ? (
                  <p className="char_counter subtitle_wrapper">
                    {" "}
                    {rowInfo.node.option?.length + "/160"}
                  </p>
                ) : (
                  <p className="char_counter subtitle_wrapper red">
                    {" "}
                    {rowInfo.node.option?.length + "/160"}
                  </p>
                ),

              buttons: [
                <button
                  className="btn btn-primary button_order_input"
                  key={rowInfo.node.id}
                  onClick={() => removeOption(rowInfo)}
                  disabled={enoughAnswers()}
                >
                  Usuń
                </button>,
              ],
            }
            return nodeProps
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default QuestionOrder
