import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
} from "reactstrap"
import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import { StoreContext } from "../../context/Store.js"
import buildFormData from "functions/buildFormData.js"
import SweetAlert from "react-bootstrap-sweetalert"
import { useForm } from "react-hook-form"

const FormValidations = props => {
  const queryClient = useQueryClient()
  const [name, setName] = useState(props.name)
  const [surname, setSurname] = useState(props.surname)
  const [email, setEmail] = useState(props.email)
  const [phone, setPhone] = useState(props.phone)
  const [zipCode] = useState(props.zipCode)
  const [birthdate] = useState(props.birthdate)
  const [last_logged_in] = useState(props.last_logged_in)
  const [created_at] = useState(props.created_at.date)
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [idRank, setIdRank] = useState(props.idRank)
  const [idAdmin] = useState(props.idAdmin)
  const { api } = useContext(StoreContext)

  const mutation = useMutation(
    () =>
      api.post(
        "/admin/edit",
        buildFormData({
          name: name,
          surname: surname,
          email: email,
          phone: phone,
          zipCode: zipCode,
          birthdate: birthdate,
          idAdmin: idAdmin,
          idRank: idRank,
        })
      ),
    {
      onSuccess: () => {
        setsuccess_dlg(true)
        setTimeout(function () {
          queryClient.invalidateQueries("admin")
        }, 1000)
      },
      onError: () => {
        seterror_dlg(true)
      },
    }
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm()

  function surnameChange(e) {
    setSurname(e.target.value)
  }

  function nameChange(e) {
    setName(e.target.value)
  }

  function emailChange(e) {
    setEmail(e.target.value)
  }

  function phoneChange(e) {
    setPhone(e.target.value)
  }

  const styles = {
    flexDirection: "column",
    flexWrap: "wrap",
  }

  function handleChange(event) {
    event.target.value === "Admin" ? setIdRank(1) : setIdRank(2)
  }

  const readyOnlyColorInput = {
    backgroundColor: "rgb(240,240,240)",
  }

  const onSubmit = () => {
    mutation.mutate({
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      birthdate: birthdate,
      zipCode: zipCode,
      idAdmin: idAdmin,
      idRank: idRank,
    })
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title pb-4">Dane Administratora</h4>

                  <Form
                    style={styles}
                    onSubmit={handleSubmit(onSubmit)}
                    className="needs-validation"
                    method="post"
                    id="tooltipForm"
                  >
                    <Row>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Imię</Label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            {...register("name", {
                              required: true,
                            })}
                            style={errors.name ? { borderColor: "red" } : null}
                            value={name}
                            onChange={nameChange}
                          />

                          {errors.name && (
                            <p className="error">
                              Podane imię jest niepoprawne!
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Nazwisko</Label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            {...register("surname", {
                              required: true,
                            })}
                            style={
                              errors.surname ? { borderColor: "red" } : null
                            }
                            value={surname}
                            onChange={surnameChange}
                          />

                          {errors.surname && (
                            <p className="error">
                              Podane nazwisko jest niepoprawne!
                            </p>
                          )}
                          <div className="error"></div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="position-relative">
                          <Label>E-mail</Label>

                          <input
                            required
                            type="email"
                            className="form-control"
                            value={email}
                            {...register("email", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            style={errors.email ? { borderColor: "red" } : null}
                            onChange={emailChange}
                          />
                        </div>

                        {errors.email && (
                          <p className="error">
                            Podany adress email jest niepoprawny!
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Label>Rola</Label>
                        <select
                          className="form-select"
                          onChange={handleChange}
                          defaultValue={idRank === 1 ? "Admin" : "Redaktor"}
                        >
                          <option value="Admin">Admin</option>
                          <option value="Redaktor">Redaktor</option>
                        </select>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Numer telefonu</Label>

                          <input
                            required
                            type="phone"
                            className="form-control"
                            {...register("phone", {
                              minLength: 9,
                              maxLength: 11,
                              pattern: /^[0-9\b]+$/,
                            })}
                            style={errors.phone ? { borderColor: "red" } : null}
                            value={phone}
                            onChange={phoneChange}
                          />

                          {errors.phone && (
                            <p className="error">
                              Podany numer telefonu jest niepoprawny!
                            </p>
                          )}
                        </div>
                      </Col>
                      <hr></hr>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Data urodzenia</Label>
                          <input
                            required
                            type="date"
                            className="form-control"
                            value={birthdate?.slice(0, 10)}
                            readOnly
                            style={readyOnlyColorInput}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Data rejestracji</Label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={created_at?.slice(0, 10)}
                            style={readyOnlyColorInput}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Ostatnie logowanie</Label>
                          <input
                            type="readOnly"
                            className="form-control"
                            value={last_logged_in}
                            readOnly
                            style={readyOnlyColorInput}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div>
                      {success_dlg ? (
                        <SweetAlert
                          success
                          title="Edycja przebiegła pomyślnie"
                          onConfirm={() => {
                            setsuccess_dlg(false)
                            props.closePop(false)
                          }}
                        ></SweetAlert>
                      ) : null}

                      {error_dlg ? (
                        <SweetAlert
                          error
                          title="wystąpił błąd"
                          onConfirm={() => {
                            seterror_dlg(false)
                          }}
                        ></SweetAlert>
                      ) : null}
                      <div className="d-flex justify-content-end pt-3 ">
                        <Button color="success" type="submit" id="sa-success">
                          Zapisz zmiany
                        </Button>
                      </div>
                      {success_msg ? (
                        <SweetAlert
                          title="Operacja udana!"
                          success
                          confirmBtnBsStyle="success"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => {
                            setsuccess_msg(false)
                            setsuccess_dlg(false)
                          }}
                          onCancel={() => {
                            setsuccess_msg(false)
                          }}
                        >
                          Edycja udana
                        </SweetAlert>
                      ) : null}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormValidations
