import React from "react"
import { useState, useEffect } from "react"
import Wysiwgw from "components/Common/Wyswigw"
import { Label } from "reactstrap"

const Answer = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  chapterComponentsToDelete,
}) => {
  const { idChapterContent, extended, content, content2 } = componentInfo
  const [questionContent, setQuestionContent] = useState(
    content ?? `<p style="text-align:justify;"></p>`
  )
  const [answerContent, setAnswerContent] = useState(
    content2 ?? `<p style="text-align:justify;"></p>`
  )

  useEffect(() => {
    setQuestionContent(content ?? `<p style="text-align:justify;"></p>`)
    setAnswerContent(content2 ?? `<p style="text-align:justify;"></p>`)
  }, [chapterComponentsToDelete?.length, componentInfo.order])

  function questionHandler(event, editor) {
    const data = editor.getData()
    setQuestionContent(data)
    updateComponent(data, answerContent)
  }

  function answerHandler(event, editor) {
    const data = editor.getData()
    setAnswerContent(data)
    updateComponent(questionContent, data)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(questionContent, answerContent)
    }
  }, [extended])

  const updateComponent = (opt, correct) => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.content2 = correct
        component.height = 840
      }
      return component
    })
    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <div className="pb-5">
          <Label>Pytanie</Label>
          <Wysiwgw
            content={questionHandler}
            value={questionContent}
            Toolbar={["bold", "italic", "undo", "redo"]}
          />
        </div>
        <div>
          <Label>Odpowiedź</Label>
          <Wysiwgw
            content={answerHandler}
            value={answerContent}
            Toolbar={["bold", "italic", "undo", "redo"]}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Answer
