import React from "react"
import MetaTags from "react-meta-tags"

import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery } from "react-query"

import { useState } from "react"
import { StoreContext } from "../context/Store"
import { useContext } from "react"
import UiModal from "components/UI/UiModal"
import UniversalTab from "./UniversalTab"

const Admins = () => {
  const { api } = useContext(StoreContext)
  const [page, setPage] = useState(1)
  const [maxAmountOfPages, setMaxAmountOfPages] = useState(0)
  const [order_by, setOrder_by] = useState("idAdmin")
  const [search, setSearch] = useState("")
  const [amount] = useState(40)
  const [thOrder, setThOrder] = useState(false)
  const [order_type, setOrder_Type] = useState("desc")

  const getUrlData = (order_by, page, search, order_type, amount) =>
    api.get(
      `admin/list?order_by=${order_by}&page=${page}&search=${search}&order_type=${order_type}&amount=${amount}`
    )

  const { data, isFetching, refetch, isLoading } = useQuery(
    ["admin", page, order_by, search, order_type],
    () => getUrlData(order_by, page, search, order_type, amount),
    {
      onSuccess: () => {
        setSearch(search)
      },
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )

  function setTabOrder_By(variable) {
    return setOrder_by(variable)
  }

  function setTabOrder_Type() {
    return setOrder_Type(thOrder ? "desc" : "asc")
  }

  function setTabThOrder() {
    return setThOrder(!thOrder)
  }

  const thData = [
    {
      text: "ID",
      orderValue: "idAdmin",
    },
    {
      text: "imię",
      orderValue: "name",
    },

    {
      text: "Nazwisko",
      orderValue: "surname",
    },
    {
      text: "E-mail",
      orderValue: "email",
    },
    {
      text: "Rola",
      orderValue: "rankName",
    },
    {
      text: "Nr tel",
      orderValue: "phone",
    },

    {
      text: "Data urodzenia",
      orderValue: "birthdate",
    },
    {
      text: "Data rejestracji",
      orderValue: "created_at",
    },
    {
      text: "Status",
      orderValue: "active",
    },
    {
      text: "Ostatnie logowanie",
      orderValue: "last_logged_in",
    },
  ]

  const tabContent =
    data?.data?.data.length > 0 ? (
      data?.data?.data?.map(admin => (
        <tr key={admin?.idAdmin}>
          <td>{admin?.idAdmin}</td>
          <td>{admin?.name}</td>
          <td>{admin?.surname}</td>
          <td>{admin?.email}</td>
          <td>{admin?.rankName}</td>
          <td>{admin?.phone}</td>

          <td>{admin?.birthdate?.slice(0, 11)}</td>
          <td>{admin?.created_at?.date?.slice(0, 16)}</td>
          {admin.active ? <td>Aktywny</td> : <td>Nieaktywny</td>}
          <td>{admin?.last_logged_in?.slice(0, 16)}</td>
          <td>
            <UiModal {...admin} page={page} refetch={refetch} />
          </td>
        </tr>
      ))
    ) : (
      <h1 className="lack_of_results">Brak wyników</h1>
    )

  return (
    <React.Fragment>
      <div style={{ position: "relative", mineight: "calc( 100vh - 70px )" }}>
        <MetaTags>
          <title>Lista administracji How2Edu</title>
        </MetaTags>

        <div>
          <Breadcrumbs
            addAdminButton={true}
            isPrimary={true}
            button={false}
            onClick=""
            buttonName="Dodaj administratora"
            maintitle="Panel"
            title="Admin"
            breadcrumbItem="Lista administracji"
            newPerson="Nowy Administrator"
            url={"/dashboard"}
          />
          <UniversalTab
            isLoading={isLoading}
            amountOfElementsOnPage={10}
            tabTitle={"Lista Administracji"}
            tableStyles={" table-striped mb-0"}
            sortOrderType={"desc"}
            data={data}
            getUrlData={getUrlData}
            orderTableBy={order_by}
            isFetching={isFetching}
            setTabOrder_By={setTabOrder_By}
            setTabOrder_Type={setTabOrder_Type}
            thOrder={thOrder}
            setThOrder={setTabThOrder}
            setSearchWord={setSearch}
            thData={thData}
            tableContent={tabContent}
            refetch={refetch}
            search={search}
            order_by={order_by}
            order_type={order_type}
            page={page}
            setPage={setPage}
            amount={amount}
            setMaxAmountOfPages={setMaxAmountOfPages}
            maxAmountOfPages={maxAmountOfPages}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Admins
