import Editor from "ckeditor5-custom-build/build/ckeditor"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { useEffect, useState } from "react"

const Wysiwgw = ({ content, value, height }) => {
  const editorHeight = height ? height : "300px"
  const [editorValue, setEditorValue] = useState(value)

  useEffect(() => {
    setEditorValue(value)
  }, [value])

  return (
    <div>
      <CKEditor
        onReady={editor => {
          editor.editing.view.change(writer => {
            writer.setStyle(
              "height",
              editorHeight,
              editor.editing.view.document.getRoot()
            )
          })
        }}
        editor={Editor}
        config={{
          htmlSupport: {
            allow: [
              {
                name: /^(p|span|div|img|iframe|table|tbody|thead|tfoot|tr|td|th|col|colgroup|caption|hr|br|ul|ol|li|blockquote|em|h[2-6])$/,
                attributes: {
                  dir: true,
                  align: true,
                  alt: true,
                  title: true,
                  id: true,
                  name: true,
                },
                classes: true,
                styles: {
                  color: true,
                  "text-align": true,
                },
              },
            ],
          },
          alignment: {
            options: ["justify", "center", "right", "left"],
          },
          removePlugins: ["Title"],
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
        }}
        onChange={(event, editor) => content(event, editor)}
        data={editorValue}
      />
    </div>
  )
}

export default Wysiwgw
