import { useState, useEffect } from "react"
import Wysiwgw from "components/Common/Wyswigw"
import React from "react"

const Important = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
  chapterComponentsToDelete,
}) => {
  const { idChapterContent, extended, content, setChange } = componentInfo
  const [importantMessContent, setImportantMessContent] = useState(
    content ?? `<p style="text-align:justify;"></p>`
  )

  function importantMessHandler(event, editor) {
    const data = editor.getData()
    inteligentChangeSensorAlpha()
    setImportantMessContent(data)
    updateComponent(data)
  }
  useEffect(() => {
    setImportantMessContent(content)
  }, [chapterComponentsToDelete?.length])

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(importantMessContent)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 400
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <Wysiwgw
          content={importantMessHandler}
          value={importantMessContent}
          Toolbar={[
            "bold",
            "italic",
            "undo",
            "redo",
            "superscript",
            "subscript",
          ]}
        />
      </div>
    </React.Fragment>
  )
}

export default Important
