import React, { useContext, useState } from "react"
import { Modal } from "reactstrap"
import ValidationPassword from "../ResetRecoverpswd/ValidationPassword"
import { useCookies } from "react-cookie"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
} from "reactstrap"
import { StoreContext } from "context/Store"

const ToolDropMenu = props => {
  const { setIsUserLogged } = useContext(StoreContext)
  const [cookies, setCookies, removeCookie] = useCookies()
  const [setting_Menu, setsetting_Menu] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
    removeBodyCss()
  }
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const styles = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }
  return (
    <div>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_fullscreen()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Zmiana hasła
          </h5>
          <Button
            onClick={() => {
              setmodal_xlarge(false)
            }}
            style={{ minHeight: "16%" }}
            className="close position-absolute"
            data-dismiss="modal"
            aria-label="Close"
          ></Button>
        </div>

        <div className="modal-body">
          <ValidationPassword />
        </div>
      </Modal>
      <div className="d-flex flex-row ">
        <div>
          <button
            type="button"
            onClick={() => {
              toggleFullscreen()
            }}
            className="btn  noti-icon waves-effect mt-1 "
            data-toggle="fullscreen"
          >
            <i className="mdi mdi-fullscreen"></i>
          </button>
        </div>

        <Dropdown
          isOpen={setting_Menu}
          toggle={() => {
            setsetting_Menu(!setting_Menu)
          }}
        >
          <DropdownToggle className="mt-1 me-1">
            <i className="mdi mdi-cog "></i>
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem value="changePassword" onClick={() => tog_xlarge()}>
              Zmień hasło
            </DropdownItem>
          </DropdownMenu>
          <DropdownToggle className="mt-1">
            <Link
              to="/"
              onClick={() => {
                removeCookie("token")
                setIsUserLogged(false)
              }}
            >
              <i className="ion ion-md-exit "></i>
            </Link>
          </DropdownToggle>
        </Dropdown>
      </div>
    </div>
  )
}

export default ToolDropMenu
