import React from "react"
import { useContext } from "react"
import { useMutation } from "react-query"
import { StoreContext } from "../../context/Store"
import buildFormData from "../../functions/buildFormData"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, Row } from "reactstrap"
import { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import bg from "../../assets/images/bg.jpg"

const RecoverPassword = () => {
  const { logo } = useContext(StoreContext)
  const [email, setEmail] = useState("")
  const { api } = useContext(StoreContext)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [errorContent, setErrorContent] = useState("")
  function EmailSetHandler(e) {
    setEmail(e.target.value)
  }
  const mutation = useMutation(
    () =>
      api.post(
        "/admin/reset_password",
        buildFormData({
          email: email,
        })
      ),
    {
      onSuccess: () => {
        setsuccess_dlg(true)
      },
      onError: error => {
        if (
          error?.response.data.errors.email ===
          "The email field must contain a valid email address."
        ) {
          setErrorContent("Podany adres Email jest niepoprawny")
          seterror_dlg(true)
        }
        if (
          error?.response.data.errors.email ===
          "The email field must contain a previously existing value in the database."
        ) {
          setErrorContent(
            "Podany adres Email nie istnieje na platformie How2Edu"
          )
          seterror_dlg(true)
        }
      },
    }
  )

  return (
    <React.Fragment>
      <MetaTags>
        <title>How2Edu</title>
      </MetaTags>
      <div
        className="accountbg"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${bg})`,
        }}
      ></div>

      <div className="wrapper-page account-page-full">
        <Card className="shadow-none">
          <div className="card-block">
            <div className="account-box">
              <div className="card-box shadow-none p-4">
                <div className="p-2">
                  <div className="text-center mt-4">
                    <Link to="/">
                      <img src={logo} height="52" alt="logo" />
                    </Link>
                  </div>

                  <h4 className="font-size-18 mt-5 text-center">
                    Zrestartuj Hasło
                  </h4>

                  <form className="mt-4" action="#">
                    <div className="alert alert-success mt-4" role="alert">
                      Podaj swój Email. Dalsze instrukcje zostaną wysłane na
                      twoją skrzynke pocztową.
                    </div>

                    <div className="mt-5">
                      <label htmlFor="useremail" className="mb-">
                        Email
                      </label>
                      <div className="mb-2">
                        <input
                          onChange={EmailSetHandler}
                          placeholder="Wpisz swój email"
                          type="email"
                          className="form-control"
                          id="useremail"
                          placehold
                        />
                      </div>
                    </div>

                    <Row>
                      <div className="col-12 text-end mt-3">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                          onClick={event => {
                            event.preventDefault()
                            mutation.mutate({
                              email: email,
                            })
                          }}
                        >
                          Zrestartuj
                        </button>
                      </div>
                      {success_dlg ? (
                        <SweetAlert
                          success
                          title="Wygenerowane hasło zostało wysłane na twoją poczte!"
                          onConfirm={() => {
                            setsuccess_dlg(false)
                          }}
                        ></SweetAlert>
                      ) : null}

                      {error_dlg ? (
                        <SweetAlert
                          error
                          title={errorContent}
                          onConfirm={() => {
                            seterror_dlg(false)
                          }}
                        ></SweetAlert>
                      ) : null}
                    </Row>

                    <Row className=" mb-0">
                      <div className="col-12 mt-3"></div>
                    </Row>
                  </form>

                  <div className="mt-5 pt-4 text-center">
                    <p>
                      Przypomnialeś sobie?{" "}
                      <Link to="/" className="fw-medium text-primary">
                        {" "}
                        Zaloguj się!{" "}
                      </Link>{" "}
                    </p>
                    <p>© {new Date().getFullYear()} How2Edu </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default RecoverPassword
