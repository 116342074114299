import { useState, createContext, useEffect } from "react"
import React from "react"
import { useCookies } from "react-cookie"
import SidebarLinks from "conf/configuration"
import Logo from "conf/logo"
import axios from "axios"

export const StoreContext = createContext()

const StoreProvider = ({ children }) => {
  //url params
  const urlParams = new URL(document.location).searchParams
  const access = [
    [""],
    [
      "Dashboard",
      "Multibooki",
      "Fiszki",
      "Samopoczucie",
      "Użytkownicy",
      "Administratorzy",
      "Kategorie",
      "Abonamenty",
      "Kategorie testów",
    ],
    ["Dashboard", "Multibooki", "Fiszki", "Samopoczucie"],
  ]

  //auth
  const [isUserLogged, setIsUserLogged] = useState()
  const [userRole, setUserRole] = useState(0)
  const [userAccess, setUserAccess] = useState([])
  const [cookies] = useCookies()
  const [navigationLinks] = useState(SidebarLinks)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [logo] = useState(Logo)
  const [sideBarToggleFunction, setSideBarToggleFunction] = useState("")
  const [multibooksSetUp, setMultibooksSetUp] = useState("")

  //axios instances
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
    headers: {
      "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY,
      "Ocp-Apim-Trace": true,
      Authorization: `Bearer ${cookies.token}`,
      "Content-Type": "multipart/form-data",
      "Accept-Language": urlParams.get("lang") ?? "pl",
    },
  })

  useEffect(() => {
    if (userRole !== undefined) {
      setUserAccess(access[userRole])
    }
  }, [userRole])

  return (
    <StoreContext.Provider
      value={{
        sideBarToggleFunction,
        setSideBarToggleFunction,
        isUserLogged,
        setIsSidebarOpen,
        isSidebarOpen,
        setIsUserLogged,
        api,
        navigationLinks,
        logo,
        userRole,
        setUserRole,
        userAccess,
        setUserAccess,
        multibooksSetUp,
        setMultibooksSetUp,
        // language: urlParams.get("lang") ?? "pl",
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
