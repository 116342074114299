import { Card, Col, CardBody, Input, Label } from "reactstrap"

import { useContext, useEffect, useState } from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"

import { StoreContext } from "./../../../context/Store"

import Breadcrumbs from "./../../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"

import buildFormData from "./../../../functions/buildFormData"
import Loader from "components/UI/Loader"

import Wyswigw from "./../../../components/Common/Wyswigw"
import SweetAlert from "react-bootstrap-sweetalert"

import { useHistory, Link } from "react-router-dom"

const NewFlashCard = props => {
  const queryClient = useQueryClient()

  const { api } = useContext(StoreContext)

  const [title, setTitle] = useState("")

  const [description, setDescription] = useState("")

  const [error_dlg, seterror_dlg] = useState(false)

  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const { idFlashcard } = useParams()

  const [idFlashCard, setIdFlashCard] = useState(idFlashcard)

  const [confirm_alert, setconfirm_alert] = useState(false)

  const [success_msg, setsuccess_msg] = useState(false)

  const history = useHistory()

  const { isLoading, data: flashCardData } = useQuery(
    ["flashcard", idFlashCard],
    () =>
      api.post(
        `/admin/flashcards/show`,
        buildFormData({
          idFlashcard: idFlashcard,
        })
      ),
    {
      enabled: idFlashcard ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,

      onSuccess: data => {
        setTitle(data.data.words)
        setDescription(data.data.text)
      },
    }
  )

  const addNewFlashCard = useMutation(
    () =>
      api.post(
        "/admin/flashcards/add",
        buildFormData({
          words: title,
          text: description,
        })
      ),

    {
      onSuccess: data => {
        setIdFlashCard(data.data.data)
        setsuccess_msg(true)
        queryClient.invalidateQueries("flashcards")
        setIsButtonEnabled(false)
        history.push(`/fiszki`)
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )
  const updateCurrentFlashCard = useMutation(
    () =>
      api.post(
        "/admin/flashcards/update",
        buildFormData({
          words: title,
          text: description,
          idFlashcard: idFlashCard,
        })
      ),

    {
      onSuccess: () => {
        setsuccess_msg(true)

        setIsButtonEnabled(false)
        queryClient.invalidateQueries("flashcards")
        history.push(`/fiszki`)
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )
  const removeCurrentFlashCard = useMutation(
    () =>
      api.post(
        "/admin/flashcards/remove",
        buildFormData({
          idFlashcard: idFlashCard,
        })
      ),

    {
      onSuccess: data => {
        queryClient.invalidateQueries("flashcards")

        setIdFlashCard("")
        history.push(`/fiszki`)
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )

  function titleHandler(e) {
    setTitle(e.target.value)
  }

  function descroptionHandler(event, editor) {
    const flashCardContent = editor.getData()
    setDescription(flashCardContent)
  }

  useEffect(() => {
    if (flashCardData !== undefined) {
      setTitle(flashCardData?.data.words)
      setDescription(flashCardData?.data.text)
    }
  }, [flashCardData])

  return (
    <>
      <Breadcrumbs
        maintitle="Panel"
        breadcrumbItem={idFlashcard ? title : title ? title : "Nowa fiszka"}
        url={"/dashboard"}
        secondTitle="Lista fiszek"
        secondUrl={"/fiszki"}
      />
      <Col>
        <div className="d-flex  justify-content-between">
          <Card style={{ width: "100%" }}>
            <div style={{ marginTop: "50px" }}></div>

            {isLoading ? (
              <div className="pb-5">
                <Loader />
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-between ">
                  <div className="d-flex justify-content-start">
                    <Link
                      to="/fiszki"
                      className="btn btn-primary"
                      style={{ marginLeft: "20px" }}
                    >
                      Powrót
                    </Link>
                  </div>
                  <div>
                    {idFlashCard ? (
                      <button
                        className="btn btn-danger"
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Usuń fiszkę
                      </button>
                    ) : null}
                  </div>
                </div>
                <CardBody>
                  <Label>Słowa kluczowe:</Label>
                  <h3>
                    <Input
                      style={{ margin: "10px 0px 40px", width: "100%" }}
                      value={title}
                      onChange={titleHandler}
                      placeholder="słowa kluczowe podajemy po przecinku."
                    ></Input>
                  </h3>
                  <Label>Definicja:</Label>

                  <Wyswigw
                    value={description}
                    content={descroptionHandler}
                    Toolbar={["bold", "italic", "undo", "redo"]}
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="btn btn-primary "
                      style={{ marginLeft: "0px" }}
                      disabled={isButtonEnabled}
                      onClick={() => {
                        setIsButtonEnabled(true)
                        idFlashCard
                          ? updateCurrentFlashCard.mutate(
                              buildFormData({
                                words: title,
                                text: description,
                                idFlashcard: idFlashCard,
                              })
                            )
                          : addNewFlashCard.mutate(
                              buildFormData({
                                words: title,
                                text: description,
                              })
                            )
                      }}
                    >
                      Zapisz
                    </button>
                  </div>

                  {success_msg ? (
                    <SweetAlert
                      title="Sukces!"
                      success
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setsuccess_msg(true)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                      Proces przebiegł pomyślnie
                    </SweetAlert>
                  ) : null}
                  {error_dlg ? (
                    <SweetAlert
                      error
                      title="Błąd operacja nie udana"
                      onConfirm={() => {
                        seterror_dlg(false)
                      }}
                    ></SweetAlert>
                  ) : null}

                  <div className="p-3"></div>
                  {confirm_alert ? (
                    <SweetAlert
                      title="Jesteś pewny?"
                      warning
                      showCancel
                      confirmButtonText="Usuń"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        removeCurrentFlashCard.mutate(
                          buildFormData({
                            idFlashcard: idFlashCard,
                          }),
                          setIsButtonEnabled(false),
                          setconfirm_alert(false)
                        )
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      Akcja będzie nieodwracalna!
                    </SweetAlert>
                  ) : null}
                </CardBody>
              </div>
            )}
          </Card>
        </div>
      </Col>
    </>
  )
}
export default NewFlashCard
