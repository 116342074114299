import { Label, Input, Col } from "reactstrap"
import { useState, useEffect } from "react"

import Select from "react-select"

const UsersRoleInputs = ({
  idRank,
  careTakerEmail,
  schoolsFor,
  lessonType,
  schools,
  subject,
  idUser,
  setSchools,
  setSubject,
  parentEmail,
  usersData,
  schoolsData,
  subjectsData,
}) => {
  const [schoolList, setSchoolList] = useState([])
  const [subjectList, setSubjectList] = useState([])

  useEffect(() => {
    if (idRank === 4 && idUser)
      setSubject(
        usersData?.data[0]?.subjects?.map(subject => {
          return { label: subject.name, value: subject.idSubject }
        })
      )
    if (idRank === 4 && idUser)
      setSchools(
        usersData?.data[0]?.schools?.map(schools => {
          return { label: schools.name, value: schools.idSchool }
        })
      )
  }, [usersData])

  useEffect(() => {
    setSchoolList(
      schoolsData?.data.map(schools => {
        return { label: schools.name, value: schools.idSchool }
      })
    )
    setSubjectList(
      subjectsData?.data.map(subjects => {
        return { label: subjects.name, value: subjects.idSubject }
      })
    )
  }, [schoolsData, subjectsData])

  return (
    <>
      {idRank === 3 ? (
        <Col md="4">
          <div className="mb-3 position-relative">
            <Label>E-mail opiekuna</Label>
            <Input
              type="email"
              className="form-control"
              onChange={careTakerEmail}
              value={parentEmail}
            />
          </div>
        </Col>
      ) : idRank === 4 ? (
        <>
          <hr></hr>
          <br></br>
          <div className="d-flex justify-content-start">
            <Col lg="4">
              <div className="mb-3">
                <label className="control-label">Szkoły</label>
                <Select
                  styles={{ height: " 200px" + "!important" }}
                  isMulti={true}
                  onChange={schoolsFor}
                  options={schoolList}
                  classNamePrefix="select2-selection"
                  value={idRank === 4 ? (idUser ? schools : null) : null}
                />
              </div>
            </Col>
            <Col lg="4" className="ms-5">
              <div className="mb-3">
                <Label>Przedmioty</Label>
                <Select
                  isMulti={true}
                  onChange={lessonType}
                  options={subjectList}
                  value={idRank === 4 ? (idUser ? subject : null) : null}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </div>
        </>
      ) : idRank === 3 ? (
        <Col lg="4">
          <div className="mb-3">
            <Label>E-mail opiekuna</Label>
            <Select
              isMulti={true}
              onChange={careTakerEmail}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      ) : null}
    </>
  )
}
export default UsersRoleInputs
