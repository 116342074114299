import { useEffect, useState } from "react"
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import { Input, Label } from "reactstrap"
import Select from "react-select"
import CustomTestComponentRenderer from "../../../../../../components/CustomNodeContentRenderer/CustomTestComponentRenderer"
import SweetAlert from "react-bootstrap-sweetalert"
import Fact from "./Fact"
import Question from "./Question"
import Tekst from "./Tekst"
import Important from "./Important"
import Answer from "./Answer"
import Image from "./Image"
import Note from "./Note"
import Audio from "./Audio"
import Video from "./Video"
import QuestionChoice from "./QuestionChoice"
import QuestionOrder from "./QuestionOrder"
import QuestionSlider from "./QuestionSlider"
import Animation from "./Animation"
import EquationComponent from "./EquationComponent"
import Materials from "./Materials"
import { DndContext } from "react-dnd"
const Section = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  chapterType,
  invalidAnswers,
  inteligentChangeSensorAlpha,
}) => {
  const { extended, childs, idChapterContent } = componentInfo

  const [sectionComponents, setSectionComponents] = useState()
  const [sectionComponentsToDelete, setSectionComponentsToDelete] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [pageHeight, setPageHeight] = useState()
  const [error_dlg, seterror_dlg] = useState(false)
  const [score, setScore] = useState(1)
  const [canDrag, setCanDrag] = useState(true)

  useEffect(() => {
    if (childs) {
      setSectionComponents(childs)
    }
  }, [childs])

  useEffect(() => {
    if (!childs) {
      setSectionComponents([])
    }
  }, [])

  useEffect(() => {
    if (componentInfo.height === 53) {
      updateComponent(sectionComponents)
    }
  }, [extended, pageHeight])

  useEffect(() => {
    setPageHeight(
      sectionComponents?.length > 0
        ? sectionComponents.reduce(
            (prev, component) => {
              return {
                height:
                  Number(prev.height) +
                  (component.extended === true
                    ? Number(component.height) + 30
                    : 64),
              }
            },
            { height: 100 }
          )
        : { height: 64 }
    )
  }, [sectionComponents])

  useEffect(() => {
    updateComponent(sectionComponents)
  }, [pageHeight])
  useEffect(() => {
    setCanDrag(
      chapterComponents.map(component => {
        return (
          component?.childs?.filter(child => child.extended === true).length > 0
        )
      })
    )
  }, [chapterComponents])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.childs = opt
        component.height = pageHeight?.height + 50
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  const addSectionComponent = rowInfo => {
    const newSectionComponents = addNodeUnderParent({
      treeData: sectionComponents,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        title: "",

        type: null,
        idChapterContent:
          "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
        height: 53,
      },
    }).treeData
    setSectionComponents(newSectionComponents)
    updateComponent(newSectionComponents)
  }

  const copySectionComponent = (rowInfo, component) => {
    const node = { ...component }
    node.idChapterContent =
      "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0
    const newSectionComponents = addNodeUnderParent({
      treeData: sectionComponents,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,

      getNodeKey,
      newNode: node,
    }).treeData

    setSectionComponents(newSectionComponents)
    updateComponent(newSectionComponents)
  }

  const changeListElement = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.idChapterContent === id) {
        element.title = value
        element.isChanged = true
      }
    })
  }

  const handleChangeName = (id, value) => {
    inteligentChangeSensorAlpha()
    const tree = sectionComponents.map(component => component)
    changeListElement(tree, id, value, 1)
    setSectionComponents(tree)
  }

  const handleSelectChange = (id, value) => {
    inteligentChangeSensorAlpha()
    const tree = sectionComponents.map(component => component)
    changeComponentType(tree, id, value, 1)
    setSectionComponents(tree)
    editSectionComponent(id)
  }

  const changeComponentType = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.idChapterContent == id) {
        element.type = value
        element.isChanged = true
      }
    })
  }

  const removeSectionComponent = rowInfo => {
    inteligentChangeSensorAlpha()
    const toDelete = sectionComponentsToDelete
    toDelete.push(rowInfo.node.id)
    setSectionComponentsToDelete(toDelete)
    setSectionComponents(
      removeNodeAtPath({
        treeData: sectionComponents,
        path: rowInfo.path,
        getNodeKey,
      })
    )
  }

  const editSectionComponent = id => {
    const comp = sectionComponents.map(component => component)

    for (let component of comp) {
      if (component.idChapterContent == id) {
        if (!component.extended) {
          component.extended = true
        } else {
          component.extended = false
        }
      }
    }
    setSectionComponents(comp)
  }

  const getNodeKey = ({ treeIndex }) => treeIndex

  if (!extended) {
    return null
  }

  return (
    <div style={{ height: pageHeight?.height + 0 + "px", overflow: "visible" }}>
      <button
        style={{ marginTop: "20px" }}
        onClick={() => addSectionComponent({ path: [], node: { parent: 0 } })}
        className="btn btn-success"
      >
        Nowy komponent
      </button>
      <DndContext.Consumer>
        {({ dragDropManager }) => (
          <SortableTree
            dragDropManager={dragDropManager}
            nodeContentRenderer={CustomTestComponentRenderer}
            canDrag={!canDrag[0]}
            treeData={sectionComponents}
            onChange={treeData => {
              inteligentChangeSensorAlpha()
              setSectionComponents(treeData)
            }}
            maxDepth={1}
            rowHeight={(node, element) => {
              return element.extended ? Number(element.height) + 30 : 64
            }}
            generateNodeProps={rowInfo => {
              const { type } = rowInfo.node

              const componentTypes = [
                { label: "Ciekawostka", value: "fact" },
                { label: "Pytajnik", value: "question" },
                { label: "Tekst", value: "text" },
                { label: "Ważna informacja", value: "important" },
                { label: "Pytanie z odpowiedzią", value: "answer" },
                { label: "Notatka", value: "note" },
                { label: "Grafika", value: "image" },
                { label: "Materiały", value: "download_materials" },
                {
                  label: "Wyrażenia matematyczne",
                  value: "math_equation",
                },
                { label: "Wideo", value: "video" },
                { label: "Audio", value: "audio" },
                { label: "Pytanie kolejność", value: "question_order" },
                { label: "Pytanie suwak", value: "question_slider" },
                { label: "Pytanie wyboru", value: "question_choice" },
                {
                  label: "Komponent animacji",
                  value: "animation_component",
                },
              ]
              const componentScopeTypes = [
                { label: "Ciekawostka", value: "fact" },
                { label: "Pytajnik", value: "question" },
                { label: "Tekst", value: "text" },
                { label: "Ważna informacja", value: "important" },
                { label: "Pytanie z odpowiedzią", value: "answer" },
                { label: "Notatka", value: "note" },
                { label: "Materiały", value: "download_materials" },
                { label: "Grafika", value: "image" },
                {
                  label: "Wyrażenia matematyczne",
                  value: "math_equation",
                },
                { label: "Wideo", value: "video" },
                { label: "Audio", value: "audio" },
                {
                  label: "Komponent animacji",
                  value: "animation_component",
                },
              ]

              if (componentInfo.title === "result_section_title") {
                componentTypes.splice(9, 3)
                componentTypes.splice(5, 1)
              }

              if (chapterType === "gallup_test") {
                componentTypes.splice(11, 1)
             
                
              }

              let subtitle
              switch (rowInfo.node.type) {
                case "fact":
                  subtitle = "Ciekawostka"
                  break
                case "question":
                  subtitle = "Pytajnik"
                  break
                case "text":
                  subtitle = "Tekst"
                  break
                case "download_materials":
                  subtitle = "Dokumenty"
                  break
                case "important":
                  subtitle = "Ważna informacja"
                  break
                case "answer":
                  subtitle = "Pytanie i odpowiedź"
                  break
                case "math_equation":
                  subtitle = "Wyrażenie matematyczne"
                  break
                case "image":
                  subtitle = "Grafika"
                  break
                case "note":
                  subtitle = "Notatka"
                  break
                case "audio":
                  subtitle = "Audio"
                  break
                case "video":
                  subtitle = "Wideo"
                  break
                case "question_order":
                  subtitle = "Pytanie kolejność"
                  break
                case "question_slider":
                  subtitle = "Pytanie suwak"
                  break
                case "animation_component":
                  subtitle = "Komponent animacji"
                  break
                case "question_choice":
                  subtitle = "Pytanie wyboru"
                  break
                case "section":
                  subtitle = "Sekcja"
                  break

                default:
                  subtitle = ""
              }
              let component = null
              switch (type) {
                case "fact":
                  component = (
                    <Fact
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "question":
                  component = (
                    <Question
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "download_materials":
                  component = (
                    <Materials
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "text":
                  component = (
                    <Tekst
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      change={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "math_equation":
                  component = (
                    <EquationComponent
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "important":
                  component = (
                    <Important
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "animation_component":
                  component = (
                    <Animation
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "answer":
                  component = (
                    <Answer
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "image":
                  component = (
                    <Image
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "note":
                  component = (
                    <Note
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "audio":
                  component = (
                    <Audio
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "video":
                  component = (
                    <Video
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                    />
                  )
                  break
                case "question_order":
                  component = (
                    <QuestionOrder
                      invalidAnswers={invalidAnswers}
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                      chapterType={chapterType}
                      setScore={setScore}
                    />
                  )
                  break
                case "question_choice":
                  component = (
                    <QuestionChoice
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                      chapterType={chapterType}
                      setScore={setScore}
                    />
                  )
                  break
                case "question_slider":
                  component = (
                    <QuestionSlider
                      invalidAnswers={invalidAnswers}
                      componentInfo={rowInfo.node}
                      chapterComponents={sectionComponents}
                      setChapterComponents={setSectionComponents}
                      inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                      chapterType={chapterType}
                      setScore={setScore}
                    />
                  )
                  break
                default:
                  component = null
              }

              let nodeProps = {
                title: (
                  <>
                    <Input
                      className="title_input"
                      value={rowInfo.node.title}
                      placeholder={"Nowy komponent"}
                      onChange={e =>
                        handleChangeName(
                          rowInfo.node.idChapterContent,
                          e.target.value
                        )
                      }
                      style={{ border: "none" }}
                    />
                  </>
                ),

                subtitle: !subtitle ? (
                  <Select
                    className="subtitle_select"
                    options={
                      String(componentInfo.idChapterContent).includes("temp") &&
                      componentInfo.category_id
                        ? componentScopeTypes
                        : componentTypes
                    }
                    placeholder={"Wybierz typ"}
                    onChange={e =>
                      handleSelectChange(rowInfo.node.idChapterContent, e.value)
                    }
                  />
                ) : (
                  <div className="subtitle_wrapper"> {subtitle}</div>
                ),

                buttons: [
                  <button
                    disabled={!rowInfo.node.type}
                    style={{
                      marginRight: "10px",
                      width: "72px",
                      marginTop: "1px",
                    }}
                    className="btn btn-primary"
                    key={rowInfo.node.id}
                    onClick={() => {
                      editSectionComponent(
                        rowInfo.node.idChapterContent,
                        rowInfo.node.title
                      )
                    }}
                  >
                    {rowInfo.node.extended ? "Zwiń" : "Rozwiń"}
                  </button>,
                  <button
                    style={{
                      marginRight: "10px",
                      width: "72px",
                      marginTop: "1px",
                      display: type === "section" ? "none" : "block",
                    }}
                    className="btn btn-primary"
                    onClick={() =>
                      copySectionComponent(
                        { path: [], node: { parent: 0 } },
                        rowInfo.node
                      )
                    }
                  >
                    Duplikuj
                  </button>,
                  <button
                    className="btn btn-primary"
                    key={rowInfo.node.id}
                    onClick={() => removeSectionComponent(rowInfo)}
                  >
                    Usuń
                  </button>,
                ],

                objects: [<div>{component}</div>],
              }
              return nodeProps
            }}
          />
        )}
      </DndContext.Consumer>
      {pageHeight?.height > 500 ? (
        <button
          style={{ marginTop: "20px", transform: "translateY(-120px)" }}
          onClick={() => addSectionComponent({ path: [], node: { parent: 0 } })}
          className="btn btn-success"
        >
          Nowy komponent
        </button>
      ) : null}
      {success_msg ? (
        <SweetAlert
          title="Sukces!"
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
          Proces przebiegł pomyślnie
        </SweetAlert>
      ) : null}
      {error_dlg ? (
        <SweetAlert
          error
          title="Błąd operacja nie udana"
          onConfirm={() => {
            seterror_dlg(false)
          }}
        ></SweetAlert>
      ) : null}
    </div>
  )
}

export default Section
