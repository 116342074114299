import Breadcrumb from "components/Common/Breadcrumb"
import { Col, Card, CardBody, Label, Input, CardTitle } from "reactstrap"
import UploadAndDisplayImages from "./Multibook/Subcomponents/UploadAndDisplayImages"
import { useState, useContext, useEffect } from "react"
import { useQuery, useMutation } from "react-query"
import buildFormData from "../functions/buildFormData"
import { StoreContext } from "../context/Store"
import SweetAlert from "react-bootstrap-sweetalert"
import Loader from "components/UI/Loader"
import { MetaTags } from "react-meta-tags"

const MoodCheck = () => {
  const { api } = useContext(StoreContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFile2, setSelectedFile2] = useState(null)
  const [selectedFile3, setSelectedFile3] = useState(null)
  const [selectedFile4, setSelectedFile4] = useState(null)
  const [selectedFile5, setSelectedFile5] = useState(null)
  const [selectedFile6, setSelectedFile6] = useState(null)
  const [selectedFile7, setSelectedFile7] = useState(null)
  const [mondayTitle, setMondayTitle] = useState("")
  const [thuesdayTitle, setThusdayTitle] = useState("")
  const [wednesdayTitle, setWednesdayTitle] = useState("")
  const [thursdayTitle, setThursdayTitle] = useState("")
  const [fridayTitle, setFridayTitle] = useState("")
  const [saturdayTitle, setSaturdayTitle] = useState("")
  const [sundayTitle, setSundayTitle] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [delay, setDelay] = useState(true)

  const { data: imagesData, isFetching } = useQuery(
    "moods",
    () => api.get(`/admin/moods`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setSelectedFile(data?.data[0].image)
        setSelectedFile2(data?.data[1].image)
        setSelectedFile3(data?.data[2].image)
        setSelectedFile4(data?.data[3].image)
        setSelectedFile5(data?.data[4].image)
        setSelectedFile6(data?.data[5].image)
        setSelectedFile7(data?.data[6].image)
        setMondayTitle(data?.data[0].text)
        setThusdayTitle(data?.data[1].text)
        setWednesdayTitle(data?.data[2].text)
        setThursdayTitle(data?.data[3].text)
        setFridayTitle(data?.data[4].text)
        setSaturdayTitle(data?.data[5].text)
        setSundayTitle(data?.data[6].text)
      },
    }
  )

  const addNewMoods = useMutation(
    () =>
      api.post(
        "/admin/moods/save",
        buildFormData({
          content: content2,
        })
      ),
    {
      onSuccess: data => {
        setsuccess_dlg(true)

        setIsButtonDisabled(false)
      },
      retry: false,
      refetchOnWindowFocus: false,
      onError: error => {
        seterror_dlg(true)
      },
    }
  )

  useEffect(() => {
    setSelectedFile(imagesData?.data[0].image)
    setSelectedFile2(imagesData?.data[1].image)
    setSelectedFile3(imagesData?.data[2].image)
    setSelectedFile4(imagesData?.data[3].image)
    setSelectedFile5(imagesData?.data[4].image)
    setSelectedFile6(imagesData?.data[5].image)
    setSelectedFile7(imagesData?.data[6].image)
    setMondayTitle(imagesData?.data[0].text)
    setThusdayTitle(imagesData?.data[1].text)
    setWednesdayTitle(imagesData?.data[2].text)
    setThursdayTitle(imagesData?.data[3].text)
    setFridayTitle(imagesData?.data[4].text)
    setSaturdayTitle(imagesData?.data[5].text)
    setSundayTitle(imagesData?.data[6].text)
  }, [imagesData])

  function mondayTitleHanlder(e) {
    setMondayTitle(e.target.value)
  }
  function thuesdayTitleHanlder(e) {
    setThusdayTitle(e.target.value)
  }
  function wednesdayTitleHanlder(e) {
    setWednesdayTitle(e.target.value)
  }
  function thursdayTitleHanlder(e) {
    setThursdayTitle(e.target.value)
  }
  function fridayTitleHanlder(e) {
    setFridayTitle(e.target.value)
  }
  function saturdayTitleHanlder(e) {
    setSaturdayTitle(e.target.value)
  }
  function sundayTitleHanlder(e) {
    setSundayTitle(e.target.value)
  }

  const content = [
    {
      idMood: 1,
      day: "Monday",
      image: selectedFile,
      text: mondayTitle,
    },
    {
      idMood: 2,
      day: "Tuesday",
      image: selectedFile2,
      text: thuesdayTitle,
    },
    {
      idMood: 3,
      day: "Wednesday",
      image: selectedFile3,
      text: wednesdayTitle,
    },
    {
      idMood: 4,
      day: "Thursday",
      image: selectedFile4,
      text: thursdayTitle,
    },
    {
      idMood: 5,
      day: "Friday",
      image: selectedFile5,
      text: fridayTitle,
    },
    {
      idMood: 6,
      day: "Saturday",
      image: selectedFile6,
      text: saturdayTitle,
    },
    {
      idMood: 7,
      day: "Sunday",
      image: selectedFile7,
      text: sundayTitle,
    },
  ]
  const content2 = [
    {
      idMood: 1,
      day: "Monday",
      image: selectedFile?.url === undefined ? selectedFile : selectedFile?.url,
      text: mondayTitle,
    },
    {
      idMood: 2,
      day: "Tuesday",
      image:
        selectedFile2?.url === undefined ? selectedFile2 : selectedFile2?.url,
      text: thuesdayTitle,
    },
    {
      idMood: 3,
      day: "Wednesday",
      image:
        selectedFile3?.url === undefined ? selectedFile3 : selectedFile3?.url,
      text: wednesdayTitle,
    },
    {
      idMood: 4,
      day: "Thursday",
      image:
        selectedFile4?.url === undefined ? selectedFile4 : selectedFile4?.url,
      text: thursdayTitle,
    },
    {
      idMood: 5,
      day: "Friday",
      image:
        selectedFile5?.url === undefined ? selectedFile5 : selectedFile5?.url,
      text: fridayTitle,
    },
    {
      idMood: 6,
      day: "Saturday",
      image:
        selectedFile6?.url === undefined ? selectedFile6 : selectedFile6?.url,
      text: saturdayTitle,
    },
    {
      idMood: 7,
      day: "Sunday",
      image:
        selectedFile7?.url === undefined ? selectedFile7 : selectedFile7?.url,
      text: sundayTitle,
    },
  ]
  if (delay) {
    setTimeout(function () {
      setDelay(false)
    }, 10)
    return (
      <div
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50% -50%)",
        }}
      >
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <MetaTags>
        <title>Samopoczucie How2Edu</title>
      </MetaTags>
      <Breadcrumb
        maintitle="Panel"
        breadcrumbItem="Samopoczucie"
        url={"/dashboard"}
      />
      <Col>
        <div>
          <Card style={{ width: "100%" }}>
            <div className="w-100">
              <CardBody style={{ minHeight: "142px" }}>
                <CardTitle className="h4">Samopoczucie</CardTitle>
                {isFetching ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-50% -50%)",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="d-flex justify-content-end ">
                        <div>
                          <button
                            className="btn btn-primary"
                            disabled={addNewMoods.isLoading}
                            onClick={() => {
                              addNewMoods.mutate()
                              setIsButtonDisabled(true)
                            }}
                            style={{
                              opacity: addNewMoods.isLoading ? "50%" : "100%",
                            }}
                          >
                            Zapisz
                          </button>
                          {success_dlg ? (
                            <SweetAlert
                              success
                              title={"Operacja udana"}
                              onConfirm={() => {
                                setsuccess_dlg(false)
                              }}
                            ></SweetAlert>
                          ) : null}
                          {error_dlg ? (
                            <SweetAlert
                              error
                              title={"Wystąpił błąd"}
                              onConfirm={() => {
                                seterror_dlg(false)
                              }}
                            ></SweetAlert>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex flex-row bd-highlight flex-wrap justify-content-center">
                        <div className="p-4">
                          <h4 className="mb-2">Poniedziałek</h4>
                          <div className="d-flex justify-content-start ">
                            <div>
                              <UploadAndDisplayImages
                                setFile={setSelectedFile}
                                cover={selectedFile}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  onChange={mondayTitleHanlder}
                                  value={mondayTitle}
                                  placeholder="wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <h4 className="mb-2">Wtorek</h4>
                          <div className="d-flex justify-content-start ">
                            <div className=" ">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile2}
                                cover={selectedFile2}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  onChange={thuesdayTitleHanlder}
                                  value={thuesdayTitle}
                                  placeholder="wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <h4 className="mb-2">Środa</h4>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile3}
                                cover={selectedFile3}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  value={wednesdayTitle}
                                  onChange={wednesdayTitleHanlder}
                                  placeholder="Wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-4 ">
                          <h4 className="">Czwartek</h4>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile4}
                                cover={selectedFile4}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  value={thursdayTitle}
                                  onChange={thursdayTitleHanlder}
                                  placeholder="wpisz ciekwą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <h4 className="">Piątek</h4>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile5}
                                cover={selectedFile5}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  value={fridayTitle}
                                  onChange={fridayTitleHanlder}
                                  placeholder="wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <h4 className="">Sobota</h4>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile6}
                                cover={selectedFile6}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  value={saturdayTitle}
                                  onChange={saturdayTitleHanlder}
                                  placeholder="wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <h4 className="">Niedziela</h4>
                          <div className="d-flex justify-content-start">
                            <div className="">
                              <UploadAndDisplayImages
                                setFile={setSelectedFile7}
                                cover={selectedFile7}
                                changeTitle={true}
                              />

                              <div className="mt-3">
                                <Label>Pytanie</Label>
                                <Input
                                  value={sundayTitle}
                                  onChange={sundayTitleHanlder}
                                  placeholder="wpisz ciekawą fraze"
                                ></Input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </div>
          </Card>
        </div>
      </Col>
    </div>
  )
}

export default MoodCheck
