import React, { useEffect, useState } from "react"
import { Row, Col, Card, Form, Label, Input } from "reactstrap"
import Dropzone from "react-dropzone"
import { isString } from "@microsoft/applicationinsights-core-js"

const Video = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { extended, content, content2, idChapterContent } = componentInfo
  const [selectedFiles, setselectedFiles] = useState(content ? [content] : [""])
  const [isFileSelected, setIsFileSelected] = useState(content ? true : false)

  const [selectedImage, setSelectedImage] = useState(content2 ? content2 : null)
  const [isDeleted, setIsDeleted] = useState(true)

  const center = {
    display: "block",
    marginLeft: "auto",
    marignRight: "auto",
    width: "64%",
    marginTop: "40px",
  }

  useEffect(() => {
    if (typeof selectedImage !== "string") {
      setIsDeleted(false)
    }
  }, [selectedImage, content2])

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(selectedFiles[0], selectedImage)
    }
  }, [extended])

  const updateComponent = (opt, correct) => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.content2 = correct
        component.height = 480
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
    updateComponent(files[0], selectedImage)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <Label>Okładka</Label>
      <div style={{ maxWidth: "50%" }} className="d-flex justify-content-start">
        <div>
          {selectedImage === null ? (
            <div
              className="dropzone"
              style={{ minHeight: "50px", height: "200px", padding: "0" }}
            >
              <i style={center} className="ion ion-md-image display-1"></i>
            </div>
          ) : (
            selectedImage && (
              <div>
                <div>
                  <img
                    style={{
                      minHeight: "50px",
                      height: "200px",
                      padding: "0",
                      maxWidth: "300px",
                    }}
                    className={"dropzone"}
                    alt="Nie znaleziono!"
                    src={
                      isDeleted
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                    }
                  />
                  <br />

                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                      inteligentChangeSensorAlpha()
                      setSelectedImage(null)
                      setIsDeleted(false)
                      updateComponent(selectedFiles[0], null)
                    }}
                  >
                    Usuń
                  </button>
                </div>
              </div>
            )
          )}
          {selectedImage ? null : (
            <Input
              className="mt-1"
              type="file"
              name="myImage"
              accept="image/png,image/jpeg,image/jpg"
              onChange={event => {
                inteligentChangeSensorAlpha()
                setSelectedImage(event.target.files[0])
                updateComponent(selectedFiles[0], event.target.files[0])
                setIsDeleted(false)
              }}
            />
          )}
        </div>
        <div style={{ marginLeft: "20px" }}>
          <p>Obsługiwane typy</p>
          <ul>
            <li>image/png</li>
            <li>image/jpeg</li>
            <li>image/jpg</li>
          </ul>
          
        </div>

      </div>
      <Label className="mt-4">Plik Video</Label>

      <Form
        className="d-flex flex-row bd-highlight"
        style={{ maxWidth: "300px" }}
      >
        {!isFileSelected ? (
          <div className="fileComponent">
            <Dropzone
              accept={"video/mp4"}
              onDrop={acceptedFiles => {
                inteligentChangeSensorAlpha()
                setIsFileSelected(true)
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="btn btn border-3 border-secondary"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="mdi mdi-cloud-upload display-6  text-muted"></i>
                    <h4 className="display-7">Dodaj plik wideo</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div style={{ marginLeft: "20px" }}>
              <p>Obsługiwone typy:</p>
              <ul>
                <li>video/mp4</li>
              </ul>
              
            </div>
          </div>
        ) : (
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border-3 border-secondary dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      {/* <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light "
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col> */}
                      <Col className="d-flex flex-row bd-highlight">
                        <p style={{ lineHeight: "35px" }}>
                          {isString(selectedFiles[0]) ? <a href={content} target="_blank">{content}</a> : f.name}
                        </p>

                        <div className="ps-3">
                          <button
                            type="button"
                            onClick={() => {
                              inteligentChangeSensorAlpha()
                              setIsFileSelected(false)
                              setselectedFiles([""])
                              updateComponent([""], selectedImage)
                            }}
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Usuń
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>
        )}
      </Form>
    </React.Fragment>
  )
}

export default Video
