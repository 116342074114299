import { useContext, useEffect, useState } from "react"
import { StoreContext } from "context/Store"
import { useQuery, useMutation, useQueryClient } from "react-query"
import buildFormData from "functions/buildFormData"
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import Breadcrumbs from "../../../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import Select from "react-select"
import { useHistory, useParams } from "react-router-dom"
import CustomChapterContentRenderer from "../../../../../components/CustomNodeContentRenderer/CustomChapterContentRenderer"
import SweetAlert from "react-bootstrap-sweetalert"
import Loader from "./../../../../../components/UI/Loader"
import Fact from "./ChapterComponents/Fact"
import Question from "./ChapterComponents/Question"
import Tekst from "./ChapterComponents/Tekst"
import Important from "./ChapterComponents/Important"
import Answer from "./ChapterComponents/Answer"
import Image from "./ChapterComponents/Image"
import Note from "./ChapterComponents/Note"
import Audio from "./ChapterComponents/Audio"
import Video from "./ChapterComponents/Video"
import QuestionOrder from "./ChapterComponents/QuestionOrder"
import QuestionChoice from "./ChapterComponents/QuestionChoice"
import QuestionSlider from "./ChapterComponents/QuestionSlider"
import Section from "./ChapterComponents/Section"
import MoodQuestion from "./ChapterComponents/MoodQuestion"
import Scope from "./ChapterComponents/Scope"
import { Label, Alert } from "reactstrap"
import Animation from "./ChapterComponents/Animation"
import TitleModal from "components/Common/TitleModal"
import EquationComponent from "./../ChapterContent/ChapterComponents/EquationComponent"
import Materials from "./ChapterComponents/Materials"
import { DndContext } from "react-dnd"
const ChapterContent = () => {
  const { api } = useContext(StoreContext)
  const history = useHistory()
  const queryClient = useQueryClient()
  const [chapterComponents, setChapterComponents] = useState([])
  const [chapterComponentsToDelete, setChapterComponentsToDelete] = useState([])
  const [pageHeight, setPageHeight] = useState()
  const [success_msg, setsuccess_msg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [multibookName, setMultibookName] = useState("")
  const [chapterType, setChapterType] = useState("")
  const [chapterTypeName, setChapterTypeName] = useState("")
  const { chapterId, multibookId } = useParams()
  const [chapters, setChapters] = useState([])
  const [index, setIndex] = useState()
  const [result, setResult] = useState()
  const [visibility, setVisibility] = useState()
  const [subjectList, setSubjectList] = useState([])
  const [selectedSubject, setSelectedSubject] = useState("")
  const [selectedSubjectLabel, setSelectedSubjectLabel] = useState("")
  const [titleModal, setTitleModal] = useState(false)
  const [categories, setCategories] = useState([])
  const [change, setChange] = useState(false)
  const [missingContentArray, setMissingContentArray] = useState("")

  const { isFetching: isCategoryFetching, data: testCategoryData } = useQuery(
    "testCategories",
    () => api.get(`/admin/section_categories`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setCategories(
          data?.data?.data?.map(category => {
            return {
              title: category.name,
              id: category.idSectionCategory,
            }
          }),
          0
        )
      },
    }
  )

  let sectionCategories = categories.map(category => {
    return {
      label: category.title,
      value: category.id,
    }
  })
  chapterComponents?.map(component => {
    sectionCategories.map((category, index) => {
      if (category.value === component?.category?.category_id) {
        sectionCategories.splice(index, 1)
      }
    })
  })
  const {
    data: treeContent,
    refetch: refetchChaptersContent,
    isFetching: isFetchingContent,
    isLoading: isLoadingContent,
  } = useQuery(
    ["chapter", multibookId, chapterId],
    () =>
      api.post(
        `/admin/multibooks/chapters/content`,

        buildFormData({
          idChapter: chapterId,
        })
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        const type = data.data.chapter.type
        setSelectedSubject(data?.data?.chapter.subject_id)
        setChapterType(type)
        switch (type) {
          case "basic":
            setChapterTypeName("Podstawowy")
            break
          case "stage_test":
            setChapterTypeName("Test Etapowy")
            break
          case "gallup_test":
            setChapterTypeName("Test Gallupa")
            break
          case "gibs_test":
            setChapterTypeName("Test Gibsa")
            break
        }
        setChapterComponents(
          data.data.chapter.content.length < 1
            ? [
                {
                  title: "",
                  type:
                    type === "basic" || type === "gibs_test" ? "" : "section",
                  idChapterContent:
                    "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
                  height: 53,
                },
              ]
            : data.data.chapter.content
                ?.sort((a, b) => (a.order < b.order ? -1 : 1))
                .map(component => {
                  component.height = 53
                  component.extended = false

                  return component
                })
        )
        if (type === "stage_test") {
          setResult(data.data.scope?.scope)
        }
        if (type === "gallup_test") {
          data.data.scope?.scope
            ? setResult(data.data.scope?.scope?.scopes)
            : setResult([])
        }
      },
    }
  )
  const {
    data: chaptersData,
    isFetching: isChaptersFetching,
    isLoading: isChaptersLoading,
    refetch: refetchChapters,
  } = useQuery(
    ["chapters", multibookId, chapterId],
    () =>
      api.post(
        `/admin/multibooks/chapters`,
        buildFormData({
          idMultibook: multibookId,
        })
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setChapters(
          data?.data?.data
            .sort((a, b) => {
              return a.order > b.order ? 1 : -1
            })
            .map(chapter => chapter.idChapter)
        )
        setIndex(chapters.indexOf(Number(chapterId)))
      },
    }
  )
  useEffect(() => {
    setIndex(chapters.indexOf(Number(chapterId)))
  }, [chapters])
  const {
    isFetching: isMultibookFetching,
    isLoading: isMultibookLoading,
    data: multibookData,
  } = useQuery(
    ["multibook", multibookId],
    () =>
      api.post(
        `/admin/multibooks/show`,
        buildFormData({
          idMultibook: multibookId,
        })
      ),
    {
      enabled: multibookId ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setMultibookName(data.data.multibook.title)
        setVisibility(data.data.multibook.visibility)
      },
    }
  )
  const { data: subjects } = useQuery(
    "subjects",
    () => api.get(`/admin/multibooks/get_subjects`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setSubjectList(
          data?.data.map(subject => {
            return {
              label: subject.name,
              value: subject.idSubject,
            }
          })
        )
      },
    }
  )
  useEffect(() => {
    if (
      treeContent !== undefined &&
      multibookData !== undefined &&
      chaptersData !== undefined &&
      testCategoryData !== undefined
    ) {
      setCategories(
        testCategoryData?.data?.data?.map(category => {
          return {
            title: category.name,
            id: category.idSectionCategory,
          }
        }),
        0
      )
      setSubjectList(
        subjects?.data.map(subject => {
          return {
            label: subject.name,
            value: subject.idSubject,
          }
        })
      )
      setChapters(
        chaptersData?.data?.data
          .sort((a, b) => {
            return a.order > b.order ? 1 : -1
          })
          .map(chapter => chapter.idChapter)
      )
      setIndex(chapters.indexOf(Number(chapterId)))
      setMultibookName(multibookData.data.multibook.title)
      setVisibility(multibookData.data.multibook.visibility)
      const type = treeContent?.data.chapter.type

      setSelectedSubject(treeContent?.data?.chapter.subject_id)

      setChapterType(type)
      switch (type) {
        case "basic":
          setChapterTypeName("Podstawowy")
          break
        case "stage_test":
          setChapterTypeName("Test Etapowy")
          break
        case "gallup_test":
          setChapterTypeName("Test Gallupa")
          break
        case "gibs_test":
          setChapterTypeName("Test Gibsa")
          break
      }

      setChapterComponents(
        treeContent?.data.chapter.content.length < 1
          ? [
              {
                title: "",
                type: type === "basic" || type === "gibs_test" ? "" : "section",
                idChapterContent:
                  "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
                height: 53,
              },
            ]
          : treeContent?.data.chapter.content
              ?.sort((a, b) => (a.order < b.order ? -1 : 1))
              .map(component => {
                component.height = 53
                component.extended = false

                return component
              })
      )
      if (type === "stage_test") {
        setResult(treeContent?.data.scope?.scope)
      }
      if (type === "gallup_test") {
        treeContent?.data.scope?.scope
          ? setResult(treeContent?.data.scope?.scope?.scopes)
          : setResult([])
      }
    }
  }, [treeContent, testCategoryData])

  const updateExamContent = useMutation(
    () =>
      api.post(
        "/admin/multibooks/chapters/exam/save",
        buildFormData({
          idChapter: chapterId,
          subject_id: selectedSubject,
          scope:
            chapterType === "gallup_test"
              ? {
                  scopes: result.map(res => {
                    return {
                      type: res.type,
                      title: res.title,
                      height: res.height,
                      idChapterContent: res.idChapterContent,
                      category_id: res.category_id,
                      childs: res.childs?.map(child => {
                        if (String(child.idChapterContent).includes("temp")) {
                          return {
                            type: child.type,
                            score: child?.content?.score
                              ? child?.content?.score
                              : 1,
                            idChapterContent: child.idChapterContent,
                            content: child.content,
                            content2: child.content2,
                            title: child.title,
                          }
                        } else {
                          return {
                            score: child.content.score
                              ? child.content.score
                              : 1,
                            type: child.type,
                            idContent: child.idChapterContent,
                            content: child.content,
                            content2: child.content2,
                            title: child.title,
                          }
                        }
                      }),
                    }
                  }),
                }
              : result,
          content: chapterComponents.map(component => {
            if (String(component.idChapterContent).includes("temp")) {
              return {
                type: component.type,
                title: component.title,
                category_id: component.category?.category_id,

                childs: component.childs?.map(child => {
                  if (String(child.idChapterContent).includes("temp")) {
                    if (
                      child?.content?.content?.nm !== undefined ||
                      child?.content?.content !== undefined
                    ) {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,

                        content: child.content.url,
                        content2: child.content2,
                        title: child.title,
                      }
                    }
                    if (
                      child.type === "animation_component" &&
                      child?.content.url !== undefined
                    ) {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,
                        content: child.content.url,
                        content2: child.content.url,

                        title: child.title,
                      }
                    }
                    if (child.type === "math_equation") {
                      return {
                        type: child.type,
                        score: child.content?.score ? child.content?.score : 1,
                        content: child.content,
                        content2: child.content2,
                        title: child.title,
                      }
                    }
                    return {
                      type: child.type,
                      score: child.content?.score ? child.content?.score : 1,
                      content: child.content,
                      content2: child.content2,
                      title: child.title,
                    }
                  } else {
                    return {
                      type: child.type,
                      score: child?.content?.score ? child?.content?.score : 1,
                      idContent: child.idChapterContent,
                      content: child.content,
                      content2: child.content2,
                      title: child.title,
                    }
                  }
                }),
              }
            } else {
              return {
                type: component.type,
                idContent: component.idChapterContent,
                category_id: component.category?.category_id,
                title: component.title,
                childs: component.childs?.map(child => {
                  if (String(child.idChapterContent).includes("temp")) {
                    if (
                      child?.content?.content?.nm !== undefined ||
                      child?.content?.content !== undefined
                    ) {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,

                        content: child.content.url,
                        content2: child.content2,
                        title: child.title,
                      }
                    }

                    if (
                      child.type === "animation_component" &&
                      child?.content.url !== undefined
                    ) {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,
                        content: child.content.url,
                        content2: child.content.url,
                        title: child.title,
                      }
                    }

                    if (child.type === "math_equation") {
                      return {
                        type: child.type,
                        score: child.content?.score ? child.content?.score : 1,
                        content: child.content,
                        content2: child.content2,
                        title: child.title,
                      }
                    }

                    return {
                      type: child.type,
                      score: child?.content?.score ? child?.content?.score : 1,
                      content: child.content,
                      content2: child.content2,
                      title: child.title,
                    }
                  } else {
                    if (child.type === "math_equation") {
                      return {
                        type: child.type,
                        score: child.content?.score ? child.content?.score : 1,
                        content: child.content,
                        content2: child.content2,
                        title: child.title,
                      }
                    }
                    if (
                      child?.content?.content?.nm !== undefined ||
                      child?.content?.content !== undefined
                    ) {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,
                        idContent: child.idChapterContent,
                        content: child.content.url,
                        content2: child.content2,
                        title: child.title,
                      }
                    } else {
                      return {
                        score: child.content.score ? child.content?.score : 1,
                        type: child.type,
                        idContent: child.idChapterContent,
                        content: child.content,
                        content2: child.content2,
                        title: child.title,
                      }
                    }
                  }
                }),
              }
            }
          }),
        })
      ),
    {
      onSuccess: () => {
        setChange(false)
        setChapterComponents(treeContent?.data.chapter.content)
        queryClient.invalidateQueries("chapter", multibookId, chapterId)
        queryClient.invalidateQueries("chapters", multibookId, chapterId)

        setsuccess_msg(true)
      },
    }
  )

  const updateChapterContent = useMutation(
    () =>
      api.post(
        "/admin/multibooks/chapters/save",
        buildFormData({
          idChapter: chapterId,
          content: chapterComponents.map(component => {
            if (!String(component.idChapterContent).includes("temp")) {
              if(component.type === "question_mood"){
               return {
                  type: component.type,
                  content: component.content,
                 
                  title: component.title,
                  extended: true,
                }
              }
              if (component?.type === "math_equation") {
                return {
                  type: component.type,
                  content: component.content,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              }
              if (
                component?.content?.content?.nm !== undefined ||
                component?.content?.content !== undefined
              ) {
                return {
                  type: component.type,
                  content: component.content.url,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              }
              if (component?.content?.content?.nm === undefined) {
                return {
                  type: component.type,
                  content: component.content,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              }
            } else {
              if(component.type === "question_mood"){
                return {
                   type: component.type,
                   content: component.content,
                  
                   title: component.title,
                   extended: true,
                 }
               }
              if (
                component?.content?.content?.nm !== undefined ||
                component?.content?.content !== undefined
              ) {
                return {
                  type: component.type,
                  content: component.content.url,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              }
              if (
                component.type === "animation_component" &&
                component?.content.url !== undefined
              ) {
                return {
                  score: component.content.score ? component.content?.score : 0,
                  type: component.type,
                  content: component.content.url,
                  content2: component.content.url,

                  title: component.title,
                }
              }
              if (component?.type === "math_equation") {
                return {
                  type: component.type,
                  content: component.content,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              } else {
                return {
                  type: component.type,
                  content: component.content,
                  content2: component.content2,
                  title: component.title,
                  extended: true,
                }
              }
            }
          }),
        })
      ),
    {
      onSuccess: () => {
        setChange(false)
        queryClient.invalidateQueries("chapter", multibookId, chapterId)
        queryClient.invalidateQueries("chapters", multibookId, chapterId)
        refetchChapters()
        refetchChaptersContent()
        setsuccess_msg(true)
      },
    }
  )

  function inteligentChangeSensorAlpha() {
    setChange(true)
  }
  function selectSubjectHandler(e) {
    inteligentChangeSensorAlpha()
    setSelectedSubject(e.value)
  }

  useEffect(() => {
    setPageHeight(
      chapterComponents.length > 0
        ? chapterComponents.reduce(
            (prev, component) => {
              return {
                height:
                  Number(prev.height) +
                  (component.extended === true
                    ? Number(component.height) + 30
                    : 64),
              }
            },
            { height: 0 }
          )
        : { height: 60 }
    )
  }, [chapterComponents])

  const addChapterComponent = rowInfo => {
    inteligentChangeSensorAlpha()
    const newChapterComponents = addNodeUnderParent({
      treeData: chapterComponents,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,

      getNodeKey,
      newNode: {
        title: "",
        type:
          chapterType === "basic" || chapterType === "gibs_test"
            ? null
            : "section",
        category: { name: "", category_id: "" },
        idChapterContent:
          "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
        height: 53,

        isChanged:
          chapterType === "basic" || chapterType === "gibs_test" ? false : true,
        extended:
          chapterType === "basic" || chapterType === "gibs_test" ? false : true,
      },
    }).treeData
    setChapterComponents(newChapterComponents)
  }

  const copySectionComponent = (rowInfo, component) => {
    inteligentChangeSensorAlpha()
    const node = { ...component }

    let components = chapterComponents
    node.idChapterContent =
      "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0

    for (let i = 0; node.childs.length > i; i++) {
      let burzopies = "temp" + Math.floor(Math.random() * (1000000 - i)) + i

      node.childs[i].idChapterContent = burzopies
    }

    node.childs[0].idChapterContent =
      "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0

    const newChapterComponents = addNodeUnderParent({
      newNode: {
        idChapterContent: node.idChapterContent,
        idChapter: node.idChapter,
        type: node.type,
        title: node.title,
        content: node.content,
        order: node.order,
        created_at: node.created_at,
        updated_at: node.updated_at,
        content2: node.content2,
        parent: node.parent,
        score: node.score === 0 ? 1 : node.score,
        category: node.category,
        category_id: node.category_id,
        height: node.height,
        extended: node.extended,
        childs: node.childs.map(child => ({
          content: child.content,
          content2: child.content2,
          created_at: child.created_at,
          idChapter: child.idChapter,
          idChapterContent:
            "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
          order: child.order,
          parent: child.parent,
          score: child.score,
          title: child.title,
          type: child.type,
          updated_at: child.updated_at,
        })),
      },
      treeData: components,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
    }).treeData

    setChapterComponents(newChapterComponents)
  }

  const copyChapterComponent = (rowInfo, component) => {
    inteligentChangeSensorAlpha()
    const node = { ...component }
    node.idChapterContent =
      "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0
    const newChapterComponents = addNodeUnderParent({
      treeData: chapterComponents,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,

      getNodeKey,
      newNode: node,
    }).treeData

    setChapterComponents(newChapterComponents)
  }

  const changeListElement = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.idChapterContent == id) {
        element.title = value
        element.isChanged = true
      }
    })
  }

  const handleChangeName = (id, value) => {
    inteligentChangeSensorAlpha()
    const tree = chapterComponents.map(component => component)
    changeListElement(tree, id, value, 1)
    setChapterComponents(tree)
    setChange(true)
  }
  const handleSelectChange2 = (id, value) => {
    const tree = chapterComponents.map(component => component)

    changeSubjectType(tree, id, value, 1)
    setChapterComponents(tree)
  }
  const changeSubjectType = (tree, id, value) => {
    inteligentChangeSensorAlpha()
    tree?.forEach(element => {
      if (element.id == id) {
        element.subject_id = value
      }
    }, setChange(true))
  }

  const handleSelectChange = (id, value) => {
    const tree = chapterComponents.map(component => component)
    changeComponentType(tree, id, value, 1)
    setChapterComponents(tree)
    editChapterComponent(id)
    setChange(true)
  }

  const handleSectionCategoryChange = (id, name, value) => {
    inteligentChangeSensorAlpha()
    const tree = chapterComponents.map(component => {
      if (id === component.idChapterContent) {
        component.category = { name: name, category_id: value }
      }
      return component
    })
    const newResult = result?.map(res => res)
    newResult.push({
      extended: false,
      height: 110,
      title: name,
      type: "section",
      category: name,
      category_id: value,
      idChapterContent: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
    })

    setResult(newResult)
    setChapterComponents(tree)
  }

  const changeComponentType = (tree, id, value) => {
    inteligentChangeSensorAlpha()
    tree?.forEach(element => {
      if (element.idChapterContent == id) {
        element.type = value
        element.isChanged = true
      }
    }, setChange(true))
  }

  useEffect(() => {
    getSelectedSubject()
  }, [selectedSubject, treeContent])

  const getSelectedSubject = () => {
    setSelectedSubjectLabel(
      subjectList?.find(element => element.value === selectedSubject)
    )
  }

  const isThereEmptyType = () => {
    const variable = chapterComponents.map(
      component =>
        component.childs?.filter(type => type.type === "" || type.type === null)
          .length > 0
    )

    return variable.filter(boolean => boolean === true).length > 0
  }

  const isThereQuestionType = () => {
    let ArrayofQuestions

    if (chapterType === "stage_test" || chapterType === "gallup_test") {
      ArrayofQuestions = chapterComponents.map(
        components =>
          components.childs?.filter(
            type =>
              type.type === "question_order" ||
              type.type === "question_slider" ||
              type.type === "question_choice"
          ).length > 0
      )
    } else {
      ArrayofQuestions = chapterComponents.map(component => true)
    }

    return (
      ArrayofQuestions?.filter(variable => variable === true).length !==
      chapterComponents.length
    )
  }

  const invalidAnswers = () => {
    if (chapterComponents !== undefined && chapterType === "stage_test") {
      let QuestionSliderAnswers
      QuestionSliderAnswers = chapterComponents?.map(components =>
        components.childs?.filter(
          type =>
            type.type === "question_slider" ||
            type.type === "question_choice" ||
            type.type === "question_order"
        )
      )
      let questionChoiceArray = []

      QuestionSliderAnswers[0]?.map(component => {
        for (let i = 0; component?.content?.options.length - 1 > i; i++) {
          if (component?.type === "question_choice") {
            questionChoiceArray.push(
              component?.content.options[0].content ===
                component?.content.options[i + 1].content
            )
          }
        }

        for (let i = 0; component?.content?.options.length - 1 > i; i++) {
          if (component?.type !== "question_choice") {
            questionChoiceArray.push(
              component?.content.options[0].option ===
                component?.content.options[i + 1].option
            )
          }
        }
      })

      return (
        questionChoiceArray.filter(variable => variable === true).length > 0
      )
    }
  }

  const removeChapterComponent = rowInfo => {
    inteligentChangeSensorAlpha()
    const toDelete = chapterComponentsToDelete

    toDelete.push(rowInfo.node.idChapterContent)

    chapterComponents.map(components => {
      if (components.idChapterContent === rowInfo.node.idChapterContent) {
        rowInfo.node.content = components.content
      }
    })

    setChange(true)
    if (chapterType === "gallup_test") {
      const newResult = result.filter(
        res => res.category_id != rowInfo.node.category?.category_id
      )
      setResult(newResult)
    }

    const newChapterComponents = chapterComponents.filter(
      component => component.idChapterContent !== rowInfo.node.idChapterContent
    )

    setChapterComponents(newChapterComponents)
    setChapterComponentsToDelete(toDelete)
  }

  const extendAll = () => {
    const newChapterComponents = chapterComponents.map(component => {
      component.extended = false
      return component
    })

    setChapterComponents(newChapterComponents)
  }

  const editChapterComponent = id => {
    const comp = chapterComponents.map(component => component)

    for (let component of comp) {
      if (component.idChapterContent == id) {
        if (!component.extended) {
          component.extended = true
        } else {
          component.extended = false
        }
      }
    }
    setChapterComponents(comp)
  }

  const editResult = id => {
    const res = result.map(component => component)
    for (let component of res) {
      if (component.idChapterContent == id) {
        if (!component.extended) {
          component.extended = true
        } else {
          component.extended = false
        }
      }
    }
    setResult(res)
  }

  const handleSubmitChanges = () => {
    if (chapterType === "basic" || chapterType === "gibs_test") {
      updateChapterContent.mutate()
    } else {
      updateExamContent.mutate()
    }
  }

  const getNodeKey = ({ treeIndex }) => treeIndex

  return (
    <>
      <Breadcrumbs
        maintitle="Panel"
        secondTitle="multibooki"
        secondUrl="/multibooki"
        thirdTitle={multibookName}
        thirdUrl={
          multibookId
            ? `/multibooki/multibook${multibookId}`
            : `/multibooki/multibook`
        }
        breadcrumbItem={treeContent?.data[0]?.title}
        url={"/dashboard"}
      />

      <Col style={{ minHeight: "730px" }}>
        <Card>
          <CardBody
            style={{
              minHeight: "260px",
              height:
                chapterType === "basic" || chapterType === "gibs_test"
                  ? pageHeight?.height + 200 + "px"
                  : pageHeight?.height + 370 + "px",
            }}
          >
            {isChaptersLoading ||
            isLoadingContent ||
            isMultibookLoading ||
            isFetchingContent ||
            treeContent === undefined ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div style={{ overflow: "visible" }}>
                <CardTitle className="h4">
                  {treeContent?.data.chapter.title +
                    " (" +
                    chapterTypeName +
                    ")"}
                </CardTitle>
                {invalidAnswers() ? (
                  <Alert color="warning" className="mb-2">
                    Uwaga: Niektóre komponenty pytające posiadają takie same
                    odpowiedzi co może powodować problem z poprawnym sumowaniem
                    punktów w multibooku.
                  </Alert>
                ) : null}

                {chapterComponents.length === 0 ||
                isThereEmptyType() ||
                isThereQuestionType() ? (
                  <div className="pt-3">
                    {!isMultibookFetching ||
                    !isFetchingContent ||
                    isChaptersFetching ? (
                      <Alert color="danger" className="mb-2">
                        {chapterComponents.length === 0
                          ? "Uwaga: Multibook musi posiadać chociaż jeden komponent inaczej nie będzie mógł zostać wyświetlony w aplikacji ani zostać zapisany!"
                          : isThereEmptyType()
                          ? "Uwaga: Musisz przydzielić typ poszczególnym komponentom jeśli chcesz zapisać swoje postępy!"
                          : isThereQuestionType()
                          ? "Uwaga: Sekcje muszą posiadać przynajmniej po jednym komponencie pytającym by opcja zapisu była dostępna."
                          : "To teoretycznie nie powinno nigdy się wyświetlić xD"}
                      </Alert>
                    ) : null}
                  </div>
                ) : null}
                {chapterType === "gallup_test" &&
                result[0]?.childs?.length === undefined ? (
                  <Alert color="danger">
                    Sekcja wyniku musi posiadać conajmniej jeden komponent by
                    opcja zapisu była dostępna!
                  </Alert>
                ) : null}
                {chapterType === "gallup_test" &&
                result[0]?.childs?.length < 1 ? (
                  <Alert color="danger">
                    Uwaga:Sekcja wyniku musi posiadać conajmniej jeden komponent
                    by opcja zapisu była dostępna!
                  </Alert>
                ) : null}
                <div
                  style={{
                    height:
                      pageHeight?.height +
                      (chapterType !== "basic" && chapterType !== "gibs_test"
                        ? 200
                        : 300) +
                      "px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "60px" }}
                      onClick={() => {
                        if (change) {
                          setTitleModal(true)
                        } else {
                          history.push("/multibooki/multibook" + multibookId)
                        }
                      }}
                    >
                      Powrót do multibooka
                    </button>
                    {titleModal ? (
                      <TitleModal
                        chapterComponents={treeContent}
                        setChapterComponents={setChapterComponents}
                        chapterId={chapterId}
                        inMultibook={true}
                        isThereEmptyType={isThereEmptyType}
                        isThereQuestionType={isThereQuestionType}
                        multibookId={multibookId}
                        titleModal={titleModal}
                        setTitleModal={setTitleModal}
                        updateExams={updateExamContent}
                        chapterType={chapterType}
                        updateChapter={updateChapterContent}
                      />
                    ) : null}

                    <button
                      disabled={
                        updateChapterContent.isLoading ||
                        updateExamContent.isLoading ||
                        chapterComponents.length === 0 ||
                        isThereEmptyType() ||
                        isThereQuestionType() ||
                        (chapterType === "gallup_test" &&
                          result[0]?.childs?.length < 1)
                      }
                      className="btn btn-danger"
                      onClick={handleSubmitChanges}
                    >
                      Zapisz
                    </button>
                  </div>

                  <button
                    disabled={
                      chapterType === "gallup_test" &&
                      sectionCategories.length < 1
                    }
                    onClick={() => {
                      addChapterComponent({ path: [], node: { parent: 0 } })
                    }}
                    className="btn btn-success"
                  >
                    {chapterType === "basic" || chapterType === "gibs_test"
                      ? "Nowy Komponent"
                      : "Nowa Sekcja"}
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      extendAll()
                    }}
                    className="btn btn-primary"
                  >
                    Zwiń wszystkie
                  </button>
                  <div className="d-flex justify-content-end">
                    {chapterType === "stage_test" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20%",
                          justifyContent: "end",
                        }}
                      >
                        <Label>Przedmiot</Label>
                        <Select
                          options={subjectList}
                          onChange={selectSubjectHandler}
                          value={selectedSubjectLabel}
                        />
                      </div>
                    ) : null}
                  </div>
                  <DndContext.Consumer>
                    {({ dragDropManager }) => (
                      <SortableTree
                        dragDropManager={dragDropManager}
                        canDrag={
                          chapterComponents.filter(
                            component => component.extended
                          ).length > 0
                            ? false
                            : true
                        }
                        nodeContentRenderer={CustomChapterContentRenderer}
                        treeData={chapterComponents}
                        onChange={treeData => {
                          inteligentChangeSensorAlpha()
                          setChapterComponents(treeData)
                        }}
                        maxDepth={1}
                        rowHeight={(node, element) => {
                          return element.extended ? element.height + 30 : 64
                        }}
                        generateNodeProps={rowInfo => {
                          const { type } = rowInfo.node

                          const componentTypes = [
                            { label: "Ciekawostka", value: "fact" },
                            { label: "Pytajnik", value: "question" },
                            { label: "Tekst", value: "text" },
                            { label: "Ważna informacja", value: "important" },
                            { label: "Pytanie z odpowiedzią", value: "answer" },
                            { label: "Notatka", value: "note" },
                            { label: "Grafika", value: "image" },
                            {
                              label: "Wyrażenia matematyczne",
                              value: "math_equation",
                            },
                            { label: "Materiały", value: "download_materials" },
                            { label: "Wideo", value: "video" },
                            { label: "Audio", value: "audio" },

                            {
                              label: "Komponent animacji",
                              value: "animation_component",
                            },

                            {
                              label: "Pytanie suwak",
                              value: "question_slider",
                            },
                            {
                              label: "Pytanie wyboru",
                              value: "question_choice",
                            },
                            {
                              label: "Pytanie kolejność",
                              value: "question_order",
                            },
                            {
                              label: "Pytanie samopoczucia",
                              value: "question_mood",
                            },
                          ]
                          if (chapterType !== "gibs_test") {
                            componentTypes.splice(15, 1)
                          }

                          let subtitle
                          let category = rowInfo.node.category?.name
                          switch (rowInfo.node.type) {
                            case "fact":
                              subtitle = "Ciekawostka"
                              break
                            case "question":
                              subtitle = "Pytajnik"
                              break
                            case "text":
                              subtitle = "Tekst"
                              break
                            case "important":
                              subtitle = "Ważna informacja"
                              break
                            case "question_mood":
                              subtitle = "Pytanie samopoczucia"
                              break
                            case "math_equation":
                              subtitle = "Wyrażenie matematyczne"
                              break
                            case "download_materials":
                              subtitle = "Dokumenty"
                              break
                            case "answer":
                              subtitle = "Pytanie i odpowiedź"
                              break
                            case "image":
                              subtitle = "Grafika"
                              break
                            case "note":
                              subtitle = "Notatka"
                              break
                            case "audio":
                              subtitle = "Audio"
                              break
                            case "video":
                              subtitle = "Wideo"
                              break
                            case "question_order":
                              subtitle = "Pytanie kolejność"
                              break
                            case "question_slider":
                              subtitle = "Pytanie suwak"
                              break
                            case "question_choice":
                              subtitle = "Pytanie wyboru"
                              break
                            case "animation_component":
                              subtitle = "Komponent animacji"
                              break
                            case "section":
                              if (chapterType !== "gallup_test")
                                subtitle = "Sekcja"
                              break
                            default:
                              subtitle = ""
                          }
                          let component = null

                          switch (type) {
                            case "fact":
                              component = (
                                <Fact
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "question":
                              component = (
                                <Question
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "download_materials":
                              component = (
                                <Materials
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "text":
                              component = (
                                <Tekst
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  change={inteligentChangeSensorAlpha}
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "important":
                              component = (
                                <Important
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "answer":
                              component = (
                                <Answer
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "math_equation":
                              component = (
                                <EquationComponent
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "image":
                              component = (
                                <Image
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "note":
                              component = (
                                <Note
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "audio":
                              component = (
                                <Audio
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "animation_component":
                              component = (
                                <Animation
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "video":
                              component = (
                                <Video
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "question_order":
                              component = (
                                <QuestionOrder
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  chapterType={chapterType}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  invalidAnswers={invalidAnswers}
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "question_choice":
                              component = (
                                <QuestionChoice
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  chapterType={chapterType}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "question_slider":
                              component = (
                                <QuestionSlider
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  chapterType={chapterType}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                  invalidAnswers={invalidAnswers}
                                  chapterComponentsToDelete={
                                    chapterComponentsToDelete
                                  }
                                />
                              )
                              break
                            case "question_mood":
                              component = (
                                <MoodQuestion
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  chapterType={chapterType}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            case "section":
                              component = (
                                <Section
                                  invalidAnswers={invalidAnswers}
                                  index={rowInfo.treeIndex}
                                  componentInfo={rowInfo.node}
                                  chapterComponents={chapterComponents}
                                  setChapterComponents={setChapterComponents}
                                  chapterType={chapterType}
                                  result={result}
                                  setResult={setResult}
                                  inteligentChangeSensorAlpha={
                                    inteligentChangeSensorAlpha
                                  }
                                />
                              )
                              break
                            default:
                              component = null
                          }

                          let nodeProps = {
                            title: (
                              <Input
                                style={{ padding: "1px", border: "none" }}
                                className="title_input"
                                value={rowInfo.node.title}
                                placeholder={
                                  chapterType === "basic" ||
                                  chapterType === "gibs_test"
                                    ? "Nowy komponent"
                                    : "Nowa sekcja"
                                }
                                onChange={e =>
                                  handleChangeName(
                                    rowInfo.node.idChapterContent,
                                    e.target.value
                                  )
                                }
                              />
                            ),

                            subtitle:
                              !subtitle && !category ? (
                                chapterType !== "gallup_test" ? (
                                  <Select
                                    styles={{ marginBottom: "100px" }}
                                    className="subtitle_select"
                                    options={componentTypes}
                                    placeholder={"Wybierz typ"}
                                    onChange={e =>
                                      handleSelectChange(
                                        rowInfo.node.idChapterContent,
                                        e.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Select
                                    styles={{ marginBottom: "100px" }}
                                    className="subtitle_select"
                                    options={sectionCategories}
                                    placeholder={"Wybierz kategorię"}
                                    onChange={e => {
                                      handleSectionCategoryChange(
                                        rowInfo.node.idChapterContent,
                                        e.label,
                                        e.value
                                      )
                                    }}
                                  />
                                )
                              ) : (
                                <div className="subtitle_wrapper">
                                  {" "}
                                  {chapterType === "gallup_test"
                                    ? rowInfo.node.category.name
                                    : subtitle}
                                </div>
                              ),

                            buttons: [
                              <button
                                disabled={!rowInfo.node.type}
                                style={{
                                  marginRight: "10px",
                                  width: "72px",
                                  marginTop: "1px",
                                }}
                                className="btn btn-primary"
                                key={rowInfo.node.id}
                                onClick={() => {
                                  editChapterComponent(
                                    rowInfo.node.idChapterContent,
                                    rowInfo.node.title
                                  )
                                }}
                              >
                                {rowInfo.node.extended ? "Zwiń" : "Rozwiń"}
                              </button>,
                              chapterType === "stage_test" ? (
                                <button
                                  className="btn btn-primary"
                                  style={{
                                    marginRight: "10px",
                                    width: "72px",
                                    marginTop: "1px",
                                  }}
                                  onClick={() =>
                                    copySectionComponent(
                                      { path: [], node: { parent: 0 } },
                                      rowInfo.node
                                    )
                                  }
                                  disabled={rowInfo?.node?.childs?.length < 1}
                                >
                                  Duplikuj
                                </button>
                              ) : (
                                ""
                              ),
                              <button
                                style={{
                                  marginRight: "10px",
                                  width: "72px",
                                  marginTop: "1px",
                                  display:
                                    type === "section" ? "none" : "block",
                                }}
                                className="btn btn-primary"
                                onClick={() =>
                                  copyChapterComponent(
                                    { path: [], node: { parent: 0 } },
                                    rowInfo.node
                                  )
                                }
                              >
                                Duplikuj
                              </button>,
                              <button
                                disabled={chapterComponents.length < 2}
                                style={{ marginTop: "1px" }}
                                className="btn btn-primary"
                                key={rowInfo.node.id}
                                onClick={() => removeChapterComponent(rowInfo)}
                              >
                                Usuń
                              </button>,
                            ],

                            objects: [<div>{component}</div>],
                          }
                          return nodeProps
                        }}
                      />
                    )}
                  </DndContext.Consumer>
                  {pageHeight?.height > 400 ? (
                    <>
                      {" "}
                      <button
                        style={{
                          position: "relative",
                          transform:
                            chapterType === "basic" ||
                            chapterType === "gibs_test"
                              ? "translateY(-300px)"
                              : "translateY(-200px)",
                        }}
                        onClick={() => {
                          addChapterComponent({ path: [], node: { parent: 0 } })
                        }}
                        className="btn btn-success"
                      >
                        {chapterType === "basic" || chapterType === "gibs_test"
                          ? "Nowy Komponent"
                          : "Nowa Sekcja"}
                      </button>
                      <button
                        style={{
                          position: "relative",
                          transform:
                            chapterType === "basic" ||
                            chapterType === "gibs_test"
                              ? "translateY(-300px)"
                              : "translateY(-200px)",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          extendAll()
                        }}
                        className="btn btn-primary"
                      >
                        Zwiń wszystkie
                      </button>
                      <div
                        style={{
                          position: "relative",
                          transform:
                            chapterType === "basic" ||
                            chapterType === "gibs_test"
                              ? "translateY(-300px)"
                              : "translateY(-200px)",
                          marginLeft: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Link
                          to={
                            multibookId
                              ? `/multibooki/multibook${multibookId}`
                              : `/multibooki/multibook`
                          }
                          className="btn btn-primary"
                          style={{ marginRight: "60px" }}
                        >
                          Powrót do multibooka
                        </Link>

                        <button
                          disabled={
                            updateChapterContent.isLoading ||
                            updateExamContent.isLoading ||
                            chapterComponents.length === 0 ||
                            isThereEmptyType() ||
                            isThereQuestionType()
                          }
                          className="btn btn-danger"
                          onClick={handleSubmitChanges}
                        >
                          Zapisz
                        </button>
                      </div>
                    </>
                  ) : null}

                  {success_msg ? (
                    <SweetAlert
                      title="Sukces!"
                      success
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                      Proces przebiegł pomyślnie
                    </SweetAlert>
                  ) : null}
                  {error_dlg ? (
                    <SweetAlert
                      error
                      title="Błąd operacja nie udana"
                      onConfirm={() => {
                        seterror_dlg(false)
                      }}
                    ></SweetAlert>
                  ) : null}
                </div>
              </div>
            )}
          </CardBody>
        </Card>
        <Card>
          {chapterType === "stage_test" || chapterType === "gallup_test" ? (
            <CardBody>
              {chapterType === "stage_test" ? (
                <Scope
                  result={result}
                  setResult={setResult}
                  inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
                />
              ) : null}
              {result && chapterType === "gallup_test" ? (
                <div
                  style={{
                    height: Number(result[0]?.height) + 300 + "px",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Label>
                    <div className="h4 card-title">Sekcja wyniku</div>
                  </Label>
                  <DndContext.Consumer>
                    {({ dragDropManager }) => (
                      <SortableTree
                        dragDropManager={dragDropManager}
                        nodeContentRenderer={CustomChapterContentRenderer}
                        treeData={result}
                        onChange={treeData => setResult(treeData)}
                        maxDepth={1}
                        color={"red"}
                        rowHeight={(node, element) => {
                          return element.extended ? element.height + 30 : 64
                        }}
                        generateNodeProps={rowInfo => {
                          let nodeProps = {
                            title: (
                              <Input
                                style={{ padding: "1px", border: "none" }}
                                className="title_input"
                                value="Wynik"
                                disabled
                              />
                            ),
                            subtitle: (
                              <div className="subtitle_wrapper">
                                {rowInfo.node.title}
                              </div>
                            ),
                            buttons: [
                              <button
                                disabled={!rowInfo.node.type}
                                style={{
                                  width: "72px",
                                  marginTop: "1px",
                                }}
                                className="btn btn-primary"
                                key={rowInfo.node.id}
                                onClick={() => {
                                  editResult(
                                    rowInfo.node.idChapterContent,
                                    rowInfo.node.title
                                  )
                                }}
                              >
                                {rowInfo.node.extended ? "Zwiń" : "Rozwiń"}
                              </button>,
                              ,
                            ],

                            objects: [
                              <Section
                                index={rowInfo.treeIndex}
                                componentInfo={rowInfo.node}
                                chapterComponents={result}
                                setChapterComponents={setResult}
                                chapterType={chapterType}
                                inteligentChangeSensorAlpha={
                                  inteligentChangeSensorAlpha
                                }
                              />,
                            ],
                          }
                          return nodeProps
                        }}
                      />
                    )}
                  </DndContext.Consumer>
                </div>
              ) : null}
            </CardBody>
          ) : null}
        </Card>
      </Col>
    </>
  )
}

export default ChapterContent
