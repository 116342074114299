import React, { useContext, useEffect, useRef } from "react"

import SimpleBar from "simplebar-react"

import MetisMenu from "metismenujs"
import { NavLink } from "react-router-dom"
import { StoreContext } from "context/Store"

const SidebarContent = () => {
  const { navigationLinks, userAccess } = useContext(StoreContext)

  const ref = useRef()
  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        // if (pathName === items[i].pathname) {
        //   matchingMenuItem = items[i]
        //   break
        // }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }



  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const links = navigationLinks
    .filter(link => {
      for (let acc of userAccess) {
        if (link.name === acc) {
          return true
        }
      }
    })
    .map(link => (
      <li key={link.directiory + "li"}>
        <NavLink to={link.directiory} className="waves-effect">
          <i className={link.icon}></i>

          <span>{link.name}</span>
        </NavLink>
      </li>
    ))

  if (!userAccess) {
    return null
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul
            className="metismenu list-unstyled"
            id="side-menu"
            style={{ color: "whie" }}
          >
            <li className="menu-title ">Menu</li>
            {links}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent
