import React from "react"
import ReactDOM from "react-dom"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import "./conf/azureInsights"
import StoreProvider from "./context/Store"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StoreProvider>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </StoreProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById("root")
)
reportWebVitals()
