import React from "react"
import { useState, useEffect } from "react"
import { Input, Label } from "reactstrap"

const Note = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { extended, content, idChapterContent } = componentInfo
  const [noteContent, setNoteContent] = useState(
    content ? content?.placeholder : ""
  )
  const [noteHeader, setNoteHeader] = useState(content ? content?.header : "")

  function noteContentHandler(e) {
    inteligentChangeSensorAlpha()
    setNoteContent(e.target.value)
    updateComponent(e.target.value, noteHeader)
  }

  function noteHeaderHandler(e) {
    inteligentChangeSensorAlpha()
    setNoteHeader(e.target.value)
    updateComponent(noteContent, e.target.value)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(noteContent, noteHeader)
    }
  }, [extended])

  const updateComponent = (placeholder, header) => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = { placeholder: placeholder, header: header }
        component.height = 200
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <Label>Nagłówek</Label>
        <Input onChange={noteHeaderHandler} value={noteHeader}></Input>
      </div>
      <div>
        <Label className="mt-3">Placeholder</Label>
        <Input onChange={noteContentHandler} value={noteContent}></Input>
      </div>
    </React.Fragment>
  )
}
export default Note
