import React, { useState } from "react"
import { Button } from "reactstrap"
import { useMutation, useQueryClient } from "react-query"
import { StoreContext } from "../../context/Store.js"
import buildFormData from "functions/buildFormData.js"
import SweetAlert from "react-bootstrap-sweetalert"
import { useContext } from "react"

const DangerAlert = ({
  URL,

  closePop,
  buttonName,
  name,
  surname,
  id,
  refetch,
}) => {
  const queryClient = useQueryClient()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)

  const [ID] = useState(id)
  const { api } = useContext(StoreContext)

  const mutation = useMutation(
    () =>
      api.post(
        URL,
        buildFormData({
          idAdmin: ID,
          idUser: ID,
        })
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin")
        queryClient.invalidateQueries("filtredUsers")

        refetch()
      },
      onError: () => {
        seterror_dlg(true)
      },
    }
  )
  const style = {
    display: "flex",
    flexDirection: "row-reverse",
  }

  return (
    <React.Fragment>
      <div style={style}>
        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => {
              mutation.mutate({ idAdmin: ID, idUser: ID })
              closePop(false)
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {error_dlg ? (
          <SweetAlert
            error
            title="Operacja nie udana"
            onConfirm={() => {
              seterror_dlg(false)
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        <div className="p-3">
          <Button
            color="danger"
            onClick={() => {
              setconfirm_alert(true)
            }}
            id="sa-success"
          >
            {buttonName}
          </Button>
        </div>
        {confirm_alert ? (
          <SweetAlert
            title={`Jesteś pewny że chcesz usunąć ${name} ${surname}?`}
            warning
            showCancel
            cancelBtnText={"Anuluj"}
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setsuccess_dlg(true)
              setdynamic_title("Usunięto")
              setdynamic_description("Usuwanie zakończyło się pomyślnie")
              setconfirm_alert(false)
            }}
            onCancel={() => setconfirm_alert(false)}
          >
            Ta decyzja będzie nieodwracalna
          </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default DangerAlert
