import React, { useEffect } from "react"
import { StoreContext } from "../../../context/Store"
import { useContext } from "react"
import { useState } from "react"
import useProtectedRoute from "hooks/useProtetedRoute"
import { boxSizing } from "@mui/system"

const Layout = props => {
  useProtectedRoute("/", false)
  let intViewportWidth = window.innerWidth
  const [intViewportWidthh, setIntViewPortWidth] = useState(intViewportWidth)

  const { isSidebarOpen, styleRefresh, sideBarToggleFunction } =
    useContext(StoreContext)

  window.onresize = () => widthChange(intViewportWidthh)
  function widthChange() {
    setIntViewPortWidth(window.innerWidth)
  }
  useEffect(() => {
    widthChange()
  }, [styleRefresh])

  const tabWithSidebar = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "70px",
    paddingLeft: isSidebarOpen ? "240px" : "70px",
    height: "calc( 100vh - 70px )",

    marginLeft: "15px",
    paddingRight: "15px",
    overflowY: "auto",
  }

  const tabWithoutSidebar = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "70px",
    paddingLeft: "80px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }

  return (
    <>
      <div>{props.header}</div>
      <div>{props.sidebar}</div>
      <div style={isSidebarOpen ? tabWithSidebar : tabWithoutSidebar}>
        <div style={{ height: "100%", width: "100%" }}>{props.content}</div>
      </div>
    </>
  )
}
export default Layout
