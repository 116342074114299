import React, { useEffect, useState } from "react"
import returnArrayOfNestedProperties from "functions/returnArrayOfNestedProperties.js"

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
} from "reactstrap"

import { useContext } from "react"
import { useMutation, useQueryClient, useQuery } from "react-query"
import { StoreContext } from "../../context/Store.js"
import buildFormData from "functions/buildFormData.js"

import SweetAlert from "react-bootstrap-sweetalert"
import UsersRoleInputs from "components/UserTabElements/UsersRoleInputs.js"

import TeacherTab from "./TeacherTab"
import StudentTab from "./StudentTab"
import ParentTab from "./ParentTab"
import { useForm } from "react-hook-form"
import Loader from "../UI/Loader.js"
const FormValidations = props => {
  const queryClient = useQueryClient()
  const [name, setName] = useState(props.name)
  const [surname, setSurname] = useState(props.surname)
  const [email, setEmail] = useState(props.email)
  const [phone, setPhone] = useState(props.phone)
  const [zipCode, setZipCode] = useState("")
  const [birthdate] = useState(props.birthdate)
  const [last_logged_in] = useState(props.last_logged_in)
  const [created_at] = useState(props.created_at.date)
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [idRank, setIdRank] = useState(props.idRank)
  const [idUser] = useState(props.idUser)
  const [schools, setSchools] = useState("")
  const [parentEmail, setParentEmail] = useState("")
  const [lesson, setLesson] = useState("")
  const [city, setCity] = useState("")
  const [adress, setAdress] = useState("")
  const [nip, setNip] = useState("")
  const [adminRights, setAdminRights] = useState(
    props.adminRights ? props.adminRights : false
  )
  const [userData, setUserData] = useState("")
  const { api } = useContext(StoreContext)
  const [accountType, setAccountType] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyCity, setCompanyCity] = useState("")
  const [companyZipCode, setCompanyZipCode] = useState("")
  const [companyAdress, setCompanyAdress] = useState("")
  const [errorContent, setErrorContent] = useState("")

  const { data: schoolsData } = useQuery(
    "schools",
    () => api.get("/school_list"),
    {
      enabled: idUser ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {},
    }
  )

  const { data: subjectsData } = useQuery(
    "subjects",
    () => api.get("/subject_list"),
    {
      enabled: idUser ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {},
    }
  )

  const { data: usersData, isFetching: isUserLoading } = useQuery(
    ["user", idUser],
    () =>
      api.post(
        "/admin/users/show",
        buildFormData({
          id: idUser,
        })
      ),
    {
      enabled: idUser ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setAccountType(data?.data[0]?.invoice?.account_type)
      },
    }
  )

  useEffect(() => {
    if (usersData !== undefined && idRank === 3) {
      if (usersData?.data[0].parent !== null)
        setParentEmail(usersData?.data[0].parent.email)
      else {
        setParentEmail("")
      }
    }
    if (usersData !== undefined && idRank === 2) {
      setAccountType(usersData?.data[0].invoice.account_type)
    }
    if (
      usersData !== undefined &&
      idRank === 2 &&
      usersData.data[0].invoice.account_type === "private"
    ) {
      setCity(usersData.data[0].invoice.city)
      setAdress(usersData.data[0].invoice.address)
      setZipCode(usersData.data[0].invoice.zipCode)
    }
    if (
      usersData !== undefined &&
      idRank === 2 &&
      usersData.data[0].invoice.account_type === "company"
    ) {
      setCompanyCity(usersData.data[0].invoice.company_city)
      setCompanyZipCode(usersData.data[0].invoice.company_zipCode)
      setCompanyAdress(usersData.data[0].invoice.company_address)
      setCompanyName(usersData.data[0].invoice.company_name)
      setNip(usersData.data[0].invoice.nip)
    }
  }, [usersData])

  const mutation = useMutation(
    () =>
      api.post(
        "/admin/users/update",
        buildFormData({
          name: name,
          surname: surname,
          private_name: name,
          private_surname: surname,
          email: email,
          phone: phone,
          zipCode: zipCode,
          private_zipCode: zipCode,
          birthdate: birthdate,
          idUser: idUser,
          private_address: adress,
          private_city: city,
          nip: nip,
          company_address: companyAdress,
          company_city: companyCity,
          company_name: companyName,
          company_zipCode: companyZipCode,
          idRank: idRank,
          parentEmail: parentEmail,
          adminRights: adminRights,
          account_type: accountType,
          schools:
            idRank === 4
              ? returnArrayOfNestedProperties(schools, "value")
              : null,
          subjects:
            idRank === 4
              ? returnArrayOfNestedProperties(lesson, "value")
              : null,
        })
      ),
    {
      onSuccess: () => {
        setsuccess_dlg(true)
        queryClient.invalidateQueries(["user", idUser])
        queryClient.invalidateQueries([
          "filtredUsers",
          props.order_by,
          props.page,
          props.search,
          props.order_type,
          props.amount,
        ])
      },
      onError: error => {
        if (error?.response?.data?.errors === "PARRENT_NOT_FOUND") {
          setErrorContent("Podany email rodzica nie istnieje")
          seterror_dlg(true)
        } else {
          setErrorContent("Wystąpił błąd")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.nip !== undefined) {
          setErrorContent("Niepoprawny NIP")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.company_address !== undefined) {
          setErrorContent("Niepoprawny adres firmy")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.company_name !== undefined) {
          setErrorContent("Niepoprawna nazwa firmy")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.company_zipCode !== undefined) {
          setErrorContent("Niepoprawny kod pocztowy")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.company_city !== undefined) {
          setErrorContent("Niepoprawnie wprowadzona nazwa miasta")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.private_city !== undefined) {
          setErrorContent("Niepoprawnie wprowadzona nazwa miasta")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.private_address !== undefined) {
          setErrorContent("Niepoprawnie wprowadzony adres")
          seterror_dlg(true)
        }
        if (error?.response?.data?.errors?.private_zipCode !== undefined) {
          setErrorContent("Niepoprawnie wprowadzony kod pocztowy")
          seterror_dlg(true)
        }
      },
    }
  )

  const handleChangeAdminRights = () => {
    setAdminRights(!adminRights)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  function teacherSchoolsChange(e) {
    setSchools(e)
  }
  function lessonChange(e) {
    setLesson(e)
  }
  function parentEmailChange(e) {
    setParentEmail(e.target.value)
  }
  function surnameChange(e) {
    setSurname(e.target.value)
  }
  function nameChange(e) {
    setName(e.target.value)
  }

  function emailChange(e) {
    setEmail(e.target.value)
  }

  function phoneChange(e) {
    setPhone(e.target.value)
  }
  function cityHandler(e) {
    setCity(e.target.value)
  }
  function nipHandler(e) {
    setNip(e.target.value)
  }
  function adressHandler(e) {
    setAdress(e.target.value)
  }
  function companyNameHandler(e) {
    setCompanyName(e.target.value)
  }
  function companyCityHandler(e) {
    setCompanyCity(e.target.value)
  }
  function companyZipCodeHandler(e) {
    setCompanyZipCode(e.target.value)
    setZipCode(e.target.value)
  }
  function companyAdressHandler(e) {
    setCompanyAdress(e.target.value)
  }
  const zipCodeHandler = e => {
    setZipCode(e.target.value)
  }

  if (usersData === undefined) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Loader />
      </div>
    )
  }

  const readyOnlyColorInput = {
    backgroundColor: "rgb(240,240,240)",
  }
  const styles = {
    flexDirection: "column",
    flexWrap: "wrap",
  }
  function handleChange(event) {
    return event.target.value === "Rodzic"
      ? setIdRank(2)
      : event.target.value === "Nauczyciel"
      ? setIdRank(4)
      : event.target.value === "Student"
      ? setIdRank(3)
      : null
  }

  function setCorrectTab(idRank) {
    if (userData !== undefined) {
      return idRank === 4 ? (
        <TeacherTab
          idUser={idUser}
          usersData={usersData}
          isUserLoading={isUserLoading}
        />
      ) : idRank === 3 ? (
        <StudentTab
          idUser={idUser}
          setParentEmail={setParentEmail}
          usersData={usersData}
          schoolsData={schoolsData}
        />
      ) : idRank === 2 ? (
        <ParentTab idUser={idUser} usersData={usersData} />
      ) : null
    }
  }

  const onSubmit = () => {
    mutation.mutate({
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      birthdate: birthdate,
      zipCode: zipCode,
      idUser: idUser,
      idRank: idRank,
      parentEmail: parentEmail,
      schools: schools,
      subjects: lesson,
    })
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title pb-4">Dane użytkownika</h4>
                  <hr></hr>

                  <form
                    style={styles}
                    onSubmit={handleSubmit(onSubmit)}
                    className="needs-validation"
                    method="post"
                    id="tooltipForm"
                  >
                    <Row>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Imię</Label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            {...register("name", {
                              required: true,
                            })}
                            value={name}
                            onChange={nameChange}
                          />
                          {errors.name && (
                            <p className="error">
                              Podane imię jest niepoprawne!
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Nazwisko</Label>

                          <input
                            required
                            type="text"
                            className="form-control"
                            {...register("surname", {
                              required: true,
                            })}
                            value={surname}
                            onChange={surnameChange}
                          />
                          {errors.surname && (
                            <p className="error">
                              Podane nazwisko jest niepoprawne!
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>E-mail</Label>

                          <input
                            required
                            type="email"
                            className="form-control"
                            {...register("email", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            value={email}
                            onChange={emailChange}
                          />
                          {errors.email && (
                            <p className="error">
                              Podany adress email jest niepoprawny!
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Label>Rola</Label>
                        <input
                          style={{ backgroundColor: " rgb(240, 240, 240)" }}
                          disabled={true}
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={
                            idRank === 2
                              ? "Rodzic"
                              : idRank === 4
                              ? "Nauczyciel"
                              : "Student"
                          }
                        ></input>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Numer telefonu</Label>
                          <input
                            required
                            type="phone"
                            className="form-control"
                            {...register("phone", {
                              minLength: 9,
                              maxLength: 11,
                              pattern: /^[0-9\b]+$/,
                            })}
                            value={phone}
                            onChange={phoneChange}
                          />
                          {errors.phone && (
                            <p className="error">
                              Podany numer telefonu jest niepoprawny!
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col
                        md="4"
                        className="d-flex justify-content-center align-items-center"
                        style={{ marginTop: "17px" }}
                      >
                        <Label for="admin_rights">Prawa administratora</Label>
                        <Input
                          id="admin_rights"
                          type="checkbox"
                          checked={adminRights}
                          onChange={handleChangeAdminRights}
                          className="ms-3"
                          style={{ marginBottom: "11px" }}
                        ></Input>
                      </Col>
                      {idRank !== 2 ? (
                        <UsersRoleInputs
                          {...props}
                          careTakerEmail={parentEmailChange}
                          schoolsFor={teacherSchoolsChange}
                          lessonType={lessonChange}
                          setSchools={setSchools}
                          setSubject={setLesson}
                          schools={schools}
                          subject={lesson}
                          idRank={idRank}
                          idUser={idUser}
                          parentEmail={parentEmail}
                          usersData={usersData}
                          schoolsData={schoolsData}
                          subjectsData={subjectsData}
                        />
                      ) : null}
                      <hr></hr>
                      {idRank === 2 && accountType === "private" ? (
                        <div className="parent_modal_inputs_div">
                          <div>
                            <Label>Miasto</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={cityHandler}
                              value={city ?? ""}
                              placeholder="wprowadź nazwę miasta"
                            ></Input>
                          </div>
                          <div>
                            <Label>Kod pocztowy</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={zipCodeHandler}
                              value={zipCode ?? ""}
                              placeholder="wprowadź kod pocztowy"
                            ></Input>
                          </div>
                          <div>
                            <Label>Adres</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={adressHandler}
                              value={adress ?? ""}
                              placeholder="wprowadź adres"
                            ></Input>
                          </div>
                        </div>
                      ) : idRank === 2 && accountType === "company" ? (
                        <div className="parent_modal_inputs_div">
                          <div>
                            <Label>Nazwa firmy</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={companyNameHandler}
                              value={companyName ?? ""}
                              placeholder="Wprowadź nazwe firmy"
                            ></Input>
                          </div>
                          <div>
                            <Label>Miasto</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={companyCityHandler}
                              value={companyCity ?? ""}
                              placeholder="wprowadź nazwe miasta"
                            ></Input>
                          </div>
                          <div>
                            <Label>Kod pocztowy</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={companyZipCodeHandler}
                              value={companyZipCode ?? ""}
                              placeholder="wprowadź kod pocztowy"
                            ></Input>
                          </div>
                          <div>
                            <Label>Adres firmy</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={companyAdressHandler}
                              value={companyAdress ?? ""}
                              placeholder="wprowadź adres firmy"
                            ></Input>
                          </div>
                          <div>
                            <Label>NIP</Label>
                            <Input
                              required
                              className="parent_modal_inputs"
                              onChange={nipHandler}
                              placeholder="wprowadź NIP"
                              value={nip ?? ""}
                            ></Input>
                          </div>
                        </div>
                      ) : null}
                      <hr></hr>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Data urodzenia</Label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            value={birthdate?.slice(0, 10)}
                            style={readyOnlyColorInput}
                            readOnly
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Data rejestracji</Label>
                          <Input
                            style={readyOnlyColorInput}
                            type="text"
                            className="form-control"
                            readOnly
                            value={created_at?.slice(0, 10)}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3 position-relative">
                          <Label>Ostatnie logowanie</Label>
                          <Input
                            type="readOnly"
                            className="form-control"
                            style={readyOnlyColorInput}
                            value={last_logged_in?.slice(0, 10)}
                            readOnly
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                    {setCorrectTab(idRank)}
                    <div>
                      {success_dlg ? (
                        <SweetAlert
                          success
                          title="Edycja przebiegła pomyślnie"
                          onConfirm={() => {
                            setsuccess_dlg(false)
                            props.closePop(false)
                          }}
                        ></SweetAlert>
                      ) : null}

                      {error_dlg ? (
                        <SweetAlert
                          error
                          title={errorContent}
                          onConfirm={() => {
                            seterror_dlg(false)
                          }}
                        ></SweetAlert>
                      ) : null}
                      <div className="d-flex justify-content-end ">
                        <Button
                          color="success"
                          type="submit"
                          id="sa-success"
                          disabled={mutation.isLoading}
                        >
                          Zapisz
                        </Button>
                      </div>
                      {success_msg ? (
                        <SweetAlert
                          title="Operacja udana!"
                          success
                          confirmBtnBsStyle="success"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => {
                            setsuccess_msg(false)
                            setsuccess_dlg(false)
                          }}
                          onCancel={() => {
                            setsuccess_msg(false)
                          }}
                        >
                          Edycja udana
                        </SweetAlert>
                      ) : null}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormValidations
