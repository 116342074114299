import React from "react"
import MetaTags from "react-meta-tags"

import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery } from "react-query"
import { useState, useContext } from "react"
import { StoreContext } from "../context/Store"
import UniversalTab from "./UniversalTab"
import UiModalUsers from "components/UI/UiModalUsers"

const Users = () => {
  const { api } = useContext(StoreContext)
  const [page, setPage] = useState(1)
  const [order_by, setOrder_by] = useState("idUser")
  const [search, setSearch] = useState("")
  const [amount] = useState(40)
  const [maxAmountOfPages, setMaxAmountOfPages] = useState("")
  const [order_type, setOrder_Type] = useState("desc")
  const [thOrder, setThOrder] = useState(false)

  const { data, isFetching, isLoading } = useQuery(
    ["filtredUsers", order_by, page, search, order_type, amount],
    () => getUrlData(order_by, page, search, order_type, amount, data),
    {
      onSuccess: () => {
        setSearch(search)
      },
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )

  function getUrlData(order_by, page, search, order_type, amount) {
    return api.get(
      `/admin/users?order_by=${order_by}&page=${page}&search=${search}&order_type=${order_type}&amount=${amount}`
    )
  }

  function setTabOrder_By(variable) {
    return setOrder_by(variable)
  }

  function setTabOrder_Type() {
    return setOrder_Type(thOrder ? "desc" : "asc")
  }

  function setTabThOrder() {
    return setThOrder(!thOrder)
  }

  const thData = [
    {
      text: "ID",
      orderValue: "idUser",
    },
    {
      text: "imię",
      orderValue: "name",
    },

    {
      text: "Nazwisko",
      orderValue: "surname",
    },
    {
      text: "E-mail",
      orderValue: "email",
    },
    {
      text: "Rola",
      orderValue: "rankName",
    },
    {
      text: "Nr tel",
      orderValue: "phone",
    },
    {
      text: "Kod pocztowy",
      orderValue: "zipCode",
    },
    {
      text: "Data urodzenia",
      orderValue: "birthdate",
    },
    {
      text: "Data rejestracji",
      orderValue: "created_at",
    },
    {
      text: "Status",
      orderValue: "active",
    },
    {
      text: "Ostatnie logowanie",
      orderValue: "last_logged_in",
    },
  ]

  const tableContent =
    data?.data?.data.length > 0 ? (
      data?.data?.data?.map(user => (
        <tr key={user.idUser}>
          <th scope="row">{user.idUser}</th>
          <td>{user.name}</td>
          <td>{user.surname}</td>
          <td>{user.email}</td>
          <td>{user.rankName}</td>
          <td>{user.phone}</td>
          <td>{user.zipCode}</td>
          <td>{user?.birthdate}</td>
          <td>{user?.created_at?.date.slice(0, 16)}</td>
          {user.active ? <td>Aktywny</td> : <td>Nieaktywny</td>}
          <td>{user?.last_logged_in?.slice(0, 16)}</td>
          <td>
            {
              <UiModalUsers
                {...user}
                page={page}
                order_by={order_by}
                search={search}
                amount={amount}
                order_type={order_type}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            }
          </td>
        </tr>
      ))
    ) : (
      <h1 className="lack_of_results">Brak wyników</h1>
    )

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Lista użytkowników How2Edu</title>
        </MetaTags>
        <div>
          <Breadcrumbs
            type={false}
            buttonTitle="Dodaj użytkownika"
            maintitle="Panel"
            title="Użytkownicy"
            breadcrumbItem="Lista użytkowników"
            newPerson="Nowy użytkownik"
            url={"/dashboard"}
          />

          <UniversalTab
            isLoading={isLoading}
            amountOfElementsOnPage={10}
            tabTitle={"Lista użytkowników"}
            tableStyles={" table-striped mb-0"}
            sortOrderType={"desc"}
            data={data}
            getUrlData={getUrlData}
            orderTableBy={order_by}
            isFetching={isFetching}
            setTabOrder_By={setTabOrder_By}
            setTabOrder_Type={setTabOrder_Type}
            thOrder={thOrder}
            setThOrder={setTabThOrder}
            setSearchWord={setSearch}
            thData={thData}
            tableContent={tableContent}
            search={search}
            order_by={order_by}
            order_type={order_type}
            page={page}
            setPage={setPage}
            amount={amount}
            setMaxAmountOfPages={setMaxAmountOfPages}
            maxAmountOfPages={maxAmountOfPages}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Users
