import React, { useState } from "react"

import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"

import { useContext } from "react"
import { useMutation } from "react-query"

import { StoreContext } from "../../context/Store.js"
import buildFormData from "functions/buildFormData.js"

import SweetAlert from "react-bootstrap-sweetalert"

import { useForm } from "react-hook-form"
const FormValidationsPassword = () => {
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newPassword2, setNewPassword2] = useState()
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_description] = useState("")
  const [respone, setResponse] = useState("")
  const [errorFromValidation] = useState("")

  const { api } = useContext(StoreContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" })

  const mutation = useMutation(
    () =>
      api.post(
        "/admin/change_password",
        buildFormData({
          old: oldPassword,
          new: newPassword,
          new2: newPassword2,
        })
      ),
    {
      onSuccess: () => {
        setsuccess_dlg(true)
      },
      onError: data => {
        seterror_dlg(true)

        {
          return data.response.data.errors === "INCORRECT_PASSWORD"
            ? setResponse("Nieprawidłowe stare hasło!")
            : setResponse("Nowe hasła nie zgadzają się ze sobą!")
        }
      },
    }
  )
  const handleChangePassword = e => {
    setNewPassword(e.target.value)
  }
  const handleChangePassword2 = e => {
    setNewPassword2(e.target.value)
  }
  const handleChangeOldPassword = e => {
    setOldPassword(e.target.value)
  }
  const onSubmit = () => {
    mutation.mutate({
      old: oldPassword,
      new: newPassword,
      new2: newPassword2,
    })
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h4 className="card-title  ">Podaj stare oraz nowe hasło</h4>
                  <h6 className="pb-4 pt-2">
                    Hasło powinno zawierać jedną dużą litere , małą litere
                    ,cyfre oraz znak specjalny. Minimalnie 8 znaków
                  </h6>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md="4">
                        <div className=" position-relative">
                          <Label>Stare hasło</Label>

                          <input
                            type="password"
                            className="form-control"
                            onChange={handleChangeOldPassword}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className=" position-relative">
                          <Label>Nowe hasło</Label>

                          <input
                            required
                            type="password"
                            className="form-control"
                            {...register("newPassword", {
                              minLength: 8,

                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            })}
                            style={
                              errors.newPassword ? { borderColor: "red" } : null
                            }
                            onChange={handleChangePassword}
                          />
                          <div className=" position-absolute">
                            {errors.newPassword && (
                              <p className="error">
                                Hasło nie zgadza się z ustalonym paternem!
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="position-relative">
                          <Label>Powtórz nowe hasło</Label>

                          <input
                            required
                            type="password"
                            className="form-control"
                            {...register("newPassword2", {
                              minLength: 8,
                              validate: newPassword2 =>
                                newPassword === newPassword2,
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            })}
                            disabled={errorFromValidation ? true : false}
                            onChange={handleChangePassword2}
                            style={
                              errors.newPassword2
                                ? { borderColor: "red" }
                                : null
                            }
                          />
                        </div>
                        <div className=" position-absolute">
                          {errors.newPassword2 && (
                            <p className="error">Hasła różnią się od siebie!</p>
                          )}
                        </div>
                      </Col>
                      <Row>
                        <Col className="pt-4 pe-0 d-flex justify-content-end">
                          <Button color="primary" type="submit" id="sa-success">
                            Zatwierdź zmiane hasła
                          </Button>
                        </Col>
                      </Row>
                    </Row>

                    <div>
                      {success_dlg ? (
                        <SweetAlert
                          success
                          title="Hasło zostało pomyślnie zmienione"
                          onConfirm={() => {
                            setsuccess_dlg(false)
                          }}
                        >
                          {dynamic_description}
                        </SweetAlert>
                      ) : null}

                      {error_dlg ? (
                        <SweetAlert
                          error
                          title="Wystąpił błąd"
                          onConfirm={() => {
                            seterror_dlg(false)
                          }}
                        >
                          {respone}
                        </SweetAlert>
                      ) : null}

                      {success_msg ? (
                        <SweetAlert
                          title="Operacja udana!"
                          success
                          confirmBtnBsStyle="success"
                          cancelBtnBsStyle="danger"
                          onConfirm={() => {
                            setsuccess_msg(false)
                            setsuccess_dlg(false)
                          }}
                          onCancel={() => {
                            setsuccess_msg(false)
                          }}
                        >
                          Hasło zostało wysłane na twoją skrzynke pocztową.
                        </SweetAlert>
                      ) : null}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormValidationsPassword
