import React, { useState } from "react"
import { Link } from "react-router-dom"

import { Modal } from "reactstrap"
import { useHistory } from "react-router-dom"
import { queryClient, useQueryClient } from "react-query"

const TitleModal = ({
  titleModal,
  setTitleModal,
  multibookId,
  updateExams,
  chapterType,
  updateChapter,
  isThereQuestionType,
  isThereEmptyType,
  inMultibook,
  rowInfo,
  chapterComponents,
  setChapterComponents,
  chapterID,
  chapterId,
}) => {
  const queryClient = useQueryClient()
  const [modal_backdrop, setmodal_backdrop] = useState(titleModal)

  const history = useHistory()

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <div>
      {inMultibook ? (
        <Modal
          isOpen={modal_backdrop}
          toggle={() => {
            tog_backdrop()
          }}
          scrollable={true}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Uwaga!
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setmodal_backdrop(false)
                setTitleModal(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Czy jesteś pewny że chcesz opuścić rozdział bez zapisania?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              disabled={isThereEmptyType() || isThereQuestionType()}
              onClick={() => {
                chapterType === "stage_test" || chapterType === "gallup_test"
                  ? updateExams?.mutate()
                  : updateChapter?.mutate()
                history.push(
                  multibookId
                    ? `/multibooki/multibook${multibookId}`
                    : `/multibooki/multibook`
                )
              }}
            >
              Zapisz i wyjdź
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                queryClient.invalidateQueries("chapter", multibookId, chapterId)
                history.push(
                  multibookId
                    ? `/multibooki/multibook${multibookId}`
                    : `/multibooki/multibook`
                )
              }}
            >
              Nie zapisuj i wyjdź
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setmodal_backdrop(false)
                setTitleModal(false)
              }}
            >
              anuluj
            </button>
          </div>
        </Modal>
      ) : (
        <Modal
          isOpen={modal_backdrop}
          toggle={() => {
            tog_backdrop()
          }}
          scrollable={true}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Uwaga!
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setmodal_backdrop(false)
                setTitleModal(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Czy jesteś pewny że chcesz opuścić Multibooka bez zapisania?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                history.push(
                  `/multibooki/multibook${multibookId}/rozdzial${chapterID}`
                )
              }}
            >
              Przejdź do rozdziału
            </button>

            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setmodal_backdrop(false)
                setTitleModal(false)
              }}
            >
              anuluj
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default TitleModal
