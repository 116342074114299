const tabButton = ({ orderValue, text, setOrderBy, thOrder, order_by }) => {
  return (
    <button
      style={{ background: "none", border: "none", minWidth: "40px" }}
      onClick={() => {
        setOrderBy(orderValue)
      }}
    >
      <span style={{ color: "#5b626b", fontWeight: "500" }}>{text}</span>
      <i
        className={
          order_by === orderValue
            ? thOrder
              ? "ion ion-md-arrow-dropup ms-2 "
              : "ion ion-md-arrow-dropdown ms-2"
            : null
        }
        style={{ color: " #5b626b" }}
      ></i>
    </button>
  )
}
export default tabButton
