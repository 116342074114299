import React, { useEffect, useState } from "react"
import { Row, Col, Card, Form } from "reactstrap"
import Dropzone from "react-dropzone"
import { isString } from "@microsoft/applicationinsights-core-js"

const Audio = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { idChapterContent, extended, content } = componentInfo
  const [selectedDocFiles, setselectedDocFiles] = useState(
    content ? [content] : [""]
  )
  const [isAudioSelected, setIsAudioSelected] = useState(content ? true : false)

  function handleAcceptedFiles(files) {
    inteligentChangeSensorAlpha()
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedDocFiles(files)
    updateComponent(files[0])
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(selectedDocFiles[0])
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 160
      }
      return component
    })
    setChapterComponents(newComponents)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <Form
        className=" d-flex flex-row bd-highlight"
        style={{ maxWidth: "300px" }}
      >
        {!isAudioSelected ? (
          <div>
            <Dropzone
              accept={"application/pdf"}
              onDrop={acceptedFiles => {
                setIsAudioSelected(true)
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="btn btn border-3 border-secondary"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="mdi mdi-cloud-upload display-6  text-muted"></i>
                    <h4 className="display-7">Dodaj plik PDF</h4>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        ) : (
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedDocFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border-3 border-secondary dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="d-flex flex-row bd-highlight">
                        {}
                        <p style={{ lineHeight: "35px" }}>
                          {isString(selectedDocFiles[0]) ? content : f.name}
                        </p>

                        <div className="ps-3">
                          <button
                            type="button"
                            onClick={() => {
                              inteligentChangeSensorAlpha()
                              setIsAudioSelected(false)
                              setselectedDocFiles([""])
                              updateComponent(null)
                            }}
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Usuń
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>
        )}
      </Form>
    </React.Fragment>
  )
}

export default Audio
