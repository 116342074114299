import { Link } from "react-router-dom"

const FlashCardeditButton = props => {
  return (
    <div>
      <Link
        to={`/fiszki/fiszka${props.idFlashcard}`}
        className="btn btn-primary"
      >
        Edytuj
      </Link>
    </div>
  )
}

export default FlashCardeditButton
