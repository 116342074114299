import React, { useEffect, useState } from "react"
import { Input, Label } from "reactstrap"
import Select from "react-select"
import Wysiwgw from "components/Common/Wyswigw"

const Scope = ({ result, setResult, inteligentChangeSensorAlpha }) => {
  const [numberOfScopes, setnumberOfScopes] = useState(
    result?.numberOfScopes
      ? { label: result?.numberOfScopes, value: result?.numberOfScopes }
      : { label: 1, value: 1 }
  )
  const [scopes, setScopes] = useState(result ? result?.scopes : [])
  const [colors] = useState([
    { first: "#9AC8C8", second: " #9AC8C8" },
    { first: "#FF6801", second: "  #FF8A3B" },
    { first: "#FFDD40", second: "#FFC400" },
  ])

  useEffect(() => {
    const newScopes = []
    for (let i = 0; i < numberOfScopes.value; i++) {
      newScopes.push({
        text: scopes[i]?.text ?? "",
        text2: scopes[i]?.text2 ?? "",
        color: scopes[i]?.color ?? colors[i],
      })
    }
    setScopes(newScopes)
  }, [numberOfScopes])

  useEffect(() => {
    saveResult(numberOfScopes.value, scopes)
  }, [scopes])

  if (!scopes) {
    return null
  }

  const handleChangeScopes = option => {
    setnumberOfScopes(option)
  }

  function tekstHandler(event, editor, index) {
    const data = editor.getData()
    inteligentChangeSensorAlpha()
    scopes[index].text = data
    saveResult(numberOfScopes.value, scopes)
  }

  function tekst2Handler(event, editor, index) {
    const data = editor.getData()
    inteligentChangeSensorAlpha()
    scopes[index].text2 = data
    saveResult(numberOfScopes.value, scopes)
  }

  const saveResult = (number, sco) => {
    setResult({ numberOfScopes: number, scopes: sco })
  }

  const handleChangeColor = (index, color) => {
    const newScopes = scopes.map(scope => scope)
    inteligentChangeSensorAlpha()
    newScopes[index].color = color
    setScopes(newScopes)
  }

  const makeScopeArray = () => {
    const array = []
    let ranges = []

    switch (Number(numberOfScopes.value)) {
      case 1:
        ranges = ["0 - 100%"]
        break
      case 2:
        ranges = ["0 - 49%", "50 - 100%"]
        break
      case 3:
        ranges = ["0 - 39%", "40 - 79%", "80 - 100%"]
        break
    }

    for (let i = 0; i < numberOfScopes.value; i++) {
      array.push(
        <div className="scope_item" key={i}>
          <Label className="mt-5">
            Zakres {i + 1} ({ranges[i]})
          </Label>
          <div style={{ paddingLeft: "20px" }}>
            <Label className="mt-1">Tekst 1</Label>
            <Wysiwgw
              content={(event, editor) => tekstHandler(event, editor, i)}
              value={scopes[i]?.text}
              height={"150px"}
              Toolbar={[
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "undo",
                "redo",
              ]}
            />
            <Label className="mt-2">Tekst 2</Label>
            <Wysiwgw
              content={(event, editor) => tekst2Handler(event, editor, i)}
              value={scopes[i]?.text2}
              height={"150px"}
              Toolbar={[
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "undo",
                "redo",
              ]}
            />
            <Label className="mt-1">Kolor</Label>
            <div className="scope_colors">
              <div className="scope_color">
                <Input
                  id={i + "1"}
                  type="radio"
                  name={i}
                  value={scopes[i]?.color}
                  checked={scopes[i]?.color.first == colors[0].first}
                  onChange={() => handleChangeColor(i, colors[0])}
                ></Input>
                <Label for={i + "1"}>
                  <div className="gradient color1"></div>
                </Label>
              </div>
              <div className="scope_color">
                <Input
                  id={i + "2"}
                  type="radio"
                  name={i}
                  value={scopes[i]?.color}
                  checked={scopes[i]?.color.first == colors[1].first}
                  onChange={() => handleChangeColor(i, colors[1])}
                ></Input>
                <Label for={i + "2"}>
                  <div className="gradient color2"></div>
                </Label>
              </div>
              <div className="scope_color">
                <Input
                  id={i + "3"}
                  type="radio"
                  name={i}
                  value={scopes[i]?.color}
                  checked={scopes[i]?.color.first == colors[2].first}
                  onChange={() => handleChangeColor(i, colors[2])}
                ></Input>
                <Label for={i + "3"}>
                  <div className="gradient color3"></div>
                </Label>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return array
  }

  const selectOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
  ]
  const scopeComponents = makeScopeArray()

  return (
    <div className="test_result_scope" style={{ paddingBottom: "30px" }}>
      <Label className="h5">Zakresy wyświetlania wyniku</Label>
      <div className="scope_select">
        <Label>Liczba zakresów</Label>
        <Select
          classNamePrefix="select2-selection"
          className=" w-100 mb-4"
          value={numberOfScopes}
          options={selectOptions}
          onChange={option => handleChangeScopes(option)}
        />
      </div>
      {scopeComponents}
    </div>
  )
}

export default Scope
