import React, { useState } from "react"
import { Modal } from "reactstrap"
import FormValidationsUsers from "../UserTabElements/FormValidationUsers"
import DangerAlert from "./DangerAlert"
import Loader from "./Loader"

const UiModalUsers = props => {
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          tog_xlarge()
        }}
        className="btn btn-primary waves-effect waves-light"
        data-toggle="modal"
        data-target=".bs-example-modal-xl"
      >
        Edytuj
      </button>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_fullscreen()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Użytkownicy
          </h5>
          <button
            onClick={() => {
              setmodal_xlarge(false)
            }}
            type="button"
            style={{ height: "6%" }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <DangerAlert
              {...props}
              closePop={setmodal_xlarge}
              buttonName={"Usuń użytkownika"}
              URL={"/admin/users/remove"}
              id={props.idUser}
              refresh={"users"}
            />
            {props.isLoading ? (
              <Loader />
            ) : (
              <FormValidationsUsers {...props} closePop={setmodal_xlarge} />
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UiModalUsers
