import React from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery } from "react-query"
import { useState } from "react"
import { StoreContext } from "../context/Store"
import { useContext } from "react"
import MultibookButton from "../pages/Multibook/Subcomponents/MultibookButton"
import UniversalTab from "./UniversalTab"

const Multibooks = () => {
  const { api, setMultibooksSetUp } = useContext(StoreContext)

  const [page, setPage] = useState(1)

  const [maxAmountOfPages, setMaxAmountOfPages] = useState("")

  const [order_by, setOrder_by] = useState("idMultibook")

  const [search, setSearch] = useState("")

  const [amount] = useState(40)

  const [order_type, setOrder_Type] = useState("desc")

  const [thOrder, setThOrder] = useState(false)

  const getUrlData = (order_by, page, search, order_type, amount) =>
    api.get(
      `/admin/multibooks?order_by=${order_by}&page=${page}&search=${search}&order_type=${order_type}&amount=${amount}`
    )

  const { data, isFetching, refetch, isLoading } = useQuery(
    ["multibooks", page, search, order_type, order_by],

    () => getUrlData(order_by, page, search, order_type, amount),
    {
      onSuccess: () => {
        setMultibooksSetUp(["multibooks", page, search, order_type, order_by])
      },
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )

  function setTabOrder_By(variable) {
    return setOrder_by(variable)
  }

  function setTabOrder_Type() {
    return setOrder_Type(thOrder ? "desc" : "asc")
  }

  function setTabThOrder() {
    return setThOrder(!thOrder)
  }
  const tabContent =
    data?.data?.data.length > 0 ? (
      data?.data?.data?.map((multibook, index) => (
        <tr key={index}>
          <th scope="row" key={index}>
            {multibook?.idMultibook}
          </th>
          <td>{multibook?.title}</td>
          <td>{multibook?.subtitle}</td>
          <td>{multibook?.categoryName}</td>
          <td>{multibook?.type}</td>
          <td>
            {multibook?.visibility === 3
              ? "Opublikowane"
              : multibook?.visibility === 2
              ? "Do akceptacji"
              : "Szkic"}
          </td>
          <td>{multibook?.created_at.date?.slice(0, 16)}</td>
          <td>{multibook?.updated_at.date?.slice(0, 16)}</td>
          <td>
            <MultibookButton
              {...multibook}
              buttonTitle={"Edytuj"}
              isPrimary={true}
            />
          </td>
        </tr>
      ))
    ) : (
      <h1 className="lack_of_results">Brak wyników</h1>
    )

  const thData = [
    {
      text: "ID",
      orderValue: "idMultibook",
    },
    {
      text: "Tytuł",
      orderValue: "title",
    },

    {
      text: "Podtytuł",
      orderValue: "subtitle",
    },
    {
      text: "Kategoria",
      orderValue: "categoryName",
    },
    {
      text: "Typ",
      orderValue: "type",
    },
    {
      text: "Stan",
      orderValue: "visibility",
    },
    {
      text: "Data utworzenia",
      orderValue: "created_at",
    },
    {
      text: "Ostatnia edycja",
      orderValue: "updated_at",
    },
  ]

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Lista multibooków How2Edu</title>
        </MetaTags>
        <div>
          <Breadcrumbs
            maintitle="Panel"
            title="Użytkownicy"
            breadcrumbItem="Lista multibooków"
            newPerson="Nowy użytkownik"
            url={"/dashboard"}
            MultibookButton={true}
            buttonTitle={"Dodaj multibooka"}
            isPrimary={false}
          />
          <UniversalTab
            isLoading={isLoading}
            amountOfElementsOnPage={10}
            tabTitle={"Lista mulitbooków"}
            tableStyles={" table-striped mb-0"}
            sortOrderType={"desc"}
            data={data}
            getUrlData={getUrlData}
            orderTableBy={order_by}
            isFetching={isFetching}
            setTabOrder_By={setTabOrder_By}
            setTabOrder_Type={setTabOrder_Type}
            thOrder={thOrder}
            setThOrder={setTabThOrder}
            setSearchWord={setSearch}
            thData={thData}
            tableContent={tabContent}
            refetch={refetch}
            search={search}
            order_by={order_by}
            order_type={order_type}
            page={page}
            setPage={setPage}
            amount={amount}
            setMaxAmountOfPages={setMaxAmountOfPages}
            maxAmountOfPages={maxAmountOfPages}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Multibooks
