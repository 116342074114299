import { useContext } from "react"
import { useQuery } from "react-query"
import React from "react"
import { StoreContext } from "./context/Store"
const Authorization = ({ children }) => {
  const { api, setIsUserLogged, setUserRole } = useContext(StoreContext)
  const { isLoading: tokenValidateIsLoading } = useQuery(
    "tokenValidate",
    () => api.get(`/admin/token/validate`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setUserRole(data.data.rank.idRanks)
        setIsUserLogged(true)
      },
      onError: () => {
        setIsUserLogged(false)
      },
    }
  )
  return tokenValidateIsLoading ? <div></div> : children
}
export default Authorization
