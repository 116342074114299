import React, { useEffect } from "react"
import { useState } from "react"
import Wyswigw from "components/Common/Wyswigw"

const Tekst = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  change,
  chapterComponentsToDelete,
}) => {
  let { extended, content, idChapterContent } = componentInfo
  const [tekst, setTekst] = useState(
    content ?? `<p style="text-align:justify;"></p>`
  )

  useEffect(() => {
    setTekst(content)
  }, [chapterComponentsToDelete?.length])

  function tekstHandler(event, editor) {
    const data = editor.getData()
    setTekst(data)

    updateComponent(data)
    change()
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(tekst)
      setTekst(tekst)
    }
  }, [extended, chapterComponents])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 400
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <Wyswigw
          content={tekstHandler}
          value={tekst}
          Toolbar={[
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "undo",
            "redo",
          ]}
        />
      </div>
    </React.Fragment>
  )
}

export default Tekst
