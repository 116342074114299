import { useEffect, useState } from "react"
import { Label, Input } from "reactstrap"
const Category = ({
  category,
  categoryMap,
  categotyListHandler,
  chosenCategoryList,
}) => {
  const [displayCategories, setDisplayCategories] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const categories = categoryMap(category.children)

  const handleChange = e => {
    setIsChecked(e.target.checked)
    categotyListHandler(e, category.id, category.title)
  }

  useEffect(() => {
    for (let cat of chosenCategoryList) {
      if (cat.value === category.id) {
        setIsChecked(true)
      }
    }

    if (!chosenCategoryList) {
      return null
    }
  }, [chosenCategoryList])

  const isChosenInput = (
    <Input
      id={category.title}
      name={category.title}
      className="ms-2 "
      type="checkbox"
      onChange={handleChange}
      checked={isChecked}
    />
  )
  return (
    <div style={{ marginLeft: "15px" }}>
      <div
        className={"d-flex flex-row bd-highlight align-items-center "}
        style={
          category.children.length > 0
            ? { maxHeight: "28px" }
            : { marginLeft: "28px " }
        }
      >
        {category.children.length > 0 ? (
          <a className="pe-auto ">
            <i
              className={
                displayCategories
                  ? "ti-minus mt-3 me-1   text-center p-2  "
                  : "ti-plus mt-3 me-1 text-center p-2 "
              }
              style={{ fontSize: "8px" }}
              onClick={() => setDisplayCategories(!displayCategories)}
            ></i>
          </a>
        ) : null}
        <span className=" mt-2" style={{ fontSize: "14px" }}>
          <Label htmlFor={category.title}>{category.title}</Label>
        </span>
        {isChosenInput}
      </div>

      <div className="d-flex flex-column bd-highlight">
        {displayCategories ? categories : null}
      </div>
    </div>
  )
}
export default Category
