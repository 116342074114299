import React from "react"

import { useEffect } from "react"

import Loader from "components/UI/Loader"

import { Table, Col, Card, CardBody, CardTitle } from "reactstrap"
import Pagination from "../components/Pagination/Pagination"

import SearchInput from "./../components/Common/SearchInput"
import TabButton from "./TabButton"

const UniversalTab = ({
  tabTitle,
  tableStyles,
  orderTableBy,
  isFetching,
  setTabOrder_By,
  setTabOrder_Type,
  thOrder,
  setThOrder,
  setSearchWord,
  tableContent,

  thData,

  data,

  page,
  setPage,

  setMaxAmountOfPages,
  maxAmountOfPages,
}) => {
  let PageSize = 10

  function setOrderBy(variable) {
    setTabOrder_By(variable)
    setThOrder()
    setTabOrder_Type(thOrder ? "desc" : "asc")
  }

  useEffect(() => {
    if (data?.data.pager.amount) {
      setMaxAmountOfPages(data?.data.pager.amount)
    }
  }, [data])

  return (
    <React.Fragment>
      <div>
        <div>
          <Col>
            <Card>
              <CardBody style={{ minHeight: "142px" }}>
                <div className="d-flex justify-content-between">
                  <CardTitle className="h4">{tabTitle}</CardTitle>
                  <SearchInput setSearch={setSearchWord} />
                </div>
                {isFetching ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className="table-responsive ">
                    <Table className={tableStyles}>
                      <thead>
                        <tr>
                          {thData.map((data, index) => (
                            <th key={index}>
                              <TabButton
                                text={data.text}
                                orderValue={data.orderValue}
                                setOrderBy={setOrderBy}
                                thOrder={thOrder}
                                order_by={orderTableBy}
                              />
                            </th>
                          ))}

                          <th>
                            <button
                              style={{ background: "none", border: "none" }}
                            >
                              <span
                                style={{
                                  color: "#5b626b",
                                  fontWeight: "500",
                                }}
                              >
                                Edytuj
                              </span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{tableContent}</tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <div>
        {data?.data.data.length > 0 ? (
          <Pagination
            setPage={setPage}
            className="pagination-bar"
            currentPage={page}
            totalCount={maxAmountOfPages * 10}
            pageSize={PageSize}
            onPageChange={setPage}
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  )
}
export default UniversalTab
