import React, { useState, useEffect, useRef } from "react"
import { Button } from "reactstrap"

import "katex/dist/katex.min.css"
import TeX from "@matejmazur/react-katex"
const _ = String.raw

const EquationModal = ({ equationContent, setEquationContent }) => {
  const textAreaRef = useRef(null)
  const [copySuccess, setCopySuccess] = useState(false)

  function handleChange(event) {
    setEquationContent(event.target.value)
  }
  function copyToClipboard(e) {
    textAreaRef.current.select()

    document.execCommand("copy")

    textAreaRef.current.focus()
  }
  useEffect(() => {
    setCopySuccess(false)
    if (equationContent === "") {
      setEquationContent("[math][/math]")
    }
  }, [equationContent])

  return (
    <div className="equation_modal">
      <div className="equation_modal-content">
        <div className="equation_mordal-body">
          <h5>Wpisz wyrażenie matematyczne</h5>
          <div className="math_component_center">
            <div className="math_component_row">
              <textarea
                ref={textAreaRef}
                className="math_modal_equation"
                value={equationContent}
                onChange={handleChange}
                spellCheck={false}
              />
              {/* You can pass styles or classNames */}

              <div className="tex_row">
                <TeX
                  style={{ justifyContent: "center", alignItems: "center" }}
                  block
                  className="output"
                  // you can change directly KaTeX options!
                  settings={{ macros: { "*": _`\cdot` } }}
                >
                  {equationContent === "undefined" ? " " : equationContent}
                </TeX>
                {!copySuccess ? (
                  <Button
                    className="btn btn-sm px-3 font-size-24 header-item waves-effect ion ion-md-list-box "
                    style={{ backgroundColor: "#ffff", marginLeft: "25px" }}
                    onClick={() => {
                      copyToClipboard(equationContent)
                      setCopySuccess(true)
                    }}
                  ></Button>
                ) : (
                  <p className="copySuccess">Dodano do schowka</p>
                )}
              </div>
            </div>
            <div>
              <h5 style={{ marginTop: "5px" }}>Podstawowe działania</h5>
              <div className="equation_instruction">
                <ul>
                  <li>
                    Wyświetlanie: Wzór musi być otoczony [math]wzór[math] i nie
                    może być sformatowany aby wyświetlił się poprawnie w
                    aplikacji{" "}
                    <span className="equation_bold">
                      np: [math]\frac45+\sqrt40[/math]{" "}
                    </span>
                  </li>
                  <li className="equation_ul-margin">
                    Ułamki: Wpisujemy \frac oraz licznik i mianownik{" "}
                    <span className="equation_bold">
                      np: \frac
                      {"{30}"}
                      {"{10}"}
                    </span>
                  </li>
                  <li className="equation_ul-margin">
                    Pierwiastki: Wpisujemy \sqrt oraz liczbe{" "}
                    <span className="equation_bold">np: \sqrt40</span>
                  </li>
                  <li className="equation_ul-margin">
                    Procenty: Wpisujemy \%{" "}
                    <span className="equation_bold">np: 60\%</span>
                  </li>
                </ul>
                <div>
                  <ul>
                    <li className="equation_ul-margin">
                      Index dolny: Do liczby dodajemy _zmienna{" "}
                      <span className="equation_bold">np: 40_3</span>
                    </li>

                    <li className="equation_ul-margin">
                      Potęga: Do liczby dodajmy strzałkę do góry ^
                      <span className="equation_bold"> np: 4^3</span>{" "}
                    </li>
                    <li className="equation_ul-margin">
                      tekst: Wpisujemy \text i treść w nawiasach klamrowych,
                      zamiast spacji używamy kropki{" "}
                      <span className="equation_bold">
                        np: \text
                        {"{przykladowy.tekst}"}
                      </span>
                    </li>
                    <li>
                      Dwa indeksy: Wpisujemy zmienną oraz w nawiasach wąsatych
                      pierwszą wartość dolną odzielamy ją \atop oraz wpisujemy
                      wartość górną{" "}
                      <span className="equation_bold">
                        np:
                        {"5" + "{" + "1" + "\\" + "atop2" + "}"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <a
              href="https://katex.org/docs/supported.html#operators"
              target="_blank"
              className=" btn btn-sm px-3 font-size-24 header-item waves-effect ion ion-md-help-circle equation_help "
            ></a>
          </div>
        </div>
        <div className="equation_modal-footer"></div>
      </div>
    </div>
  )
}
export default EquationModal
