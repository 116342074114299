const SidebarLinks = [
  { name: "Dashboard", icon: "ti-home", directiory: "/dashboard" },
  {
    name: "Użytkownicy",
    icon: "ti-user ",
    directiory: "/uzytkownicy",
  },
  {
    name: "Administratorzy",
    icon: "ti-settings",
    directiory: "/admnistratorzy",
  },
  { name: "Multibooki", icon: "ion ion-md-filing", directiory: "/multibooki" },
  { name: "Kategorie", icon: "ti-harddrives", directiory: "/kategorie" },
  { name: "Fiszki", icon: "ion ion-md-list-box", directiory: "/fiszki" },
  {
    name: "Samopoczucie",
    icon: "ion ion-md-clipboard",
    directiory: "/samopoczucie",
  },
  {
    name: "Abonamenty",
    icon: "ion ion-md-briefcase ",
    directiory: "/abonamenty",
  },
  {
    name: "Kategorie testów",
    icon: "ion ion-md-bookmarks  ",
    directiory: "/kategorie_testow",
  },
]

export default SidebarLinks
