import React, { useState, useEffect } from "react"
import { Input } from "reactstrap"
import Lottie from "react-lottie"

const UploadAndDisplayImage = ({ cover, setFile, changeTitle }) => {
  const [selectedImage, setSelectedImage] = useState(cover)
  const [isDeleted, setIsDeleted] = useState(true)
  const [fileType, setFileType] = useState("json")
  const [jsonContent, setJsonContent] = useState("")

  const lottieOptions = {
    loop: "true",
    autoplay: "true",
    animationData: jsonContent,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {
    setFile(selectedImage)
  }, [selectedImage])

  useEffect(() => {
    setSelectedImage(cover ? cover : null)
  }, [cover])

  useEffect(() => {
    setFileType(selectedImage?.name?.split(".").pop())
  }, [selectedImage])

  const center = {
    display: "block",
    marginLeft: "auto",
    marignRight: "auto",
    width: changeTitle ? "63%" : "64%",
    marginTop: "40px",
  }

  const showFile = e => {
    const reader = new FileReader()
    reader.onload = e => {
      const text = e.target.result
      if (text.slice(0, 1) === "{") {
        setJsonContent(JSON.parse(text))
      }
    }
    reader.readAsText(e.target.files[0])
  }

  useEffect(() => {
    if (selectedImage?.content?.nm !== undefined) {
      setJsonContent(selectedImage?.content)
      setFileType("json")
    }
  }, [selectedImage])

  return (
    <div style={{ minWidth: "310px" }}>
      {changeTitle ? null : <h4>Okładka</h4>}
      {selectedImage === null ? (
        <div className="dropzone">
          {" "}
          <i style={center} className="ion ion-md-image   display-1 "></i>
        </div>
      ) : (
        selectedImage && (
          <div>
            <div>
              {fileType !== "json" ? (
                <img
                  style={{ minWidth: "310px" }}
                  className={"dropzone"}
                  alt="Nie znaleziono!"
                  width={"275px"}
                  height={"230px"}
                  src={
                    isDeleted
                      ? selectedImage
                      : URL.createObjectURL(selectedImage)
                  }
                />
              ) : (
                <Lottie options={lottieOptions} height={210} width={275} />
              )}

              <br />
              <button
                className="btn btn-primary mt-2"
                onClick={() => {
                  setSelectedImage(null)
                  setIsDeleted(false)
                }}
              >
                Usuń
              </button>
            </div>
          </div>
        )
      )}
      {selectedImage ? null : (
        <Input
          className="mt-1"
          type="file"
          name="myImage"
          accept="image/png,image/jpeg,image/svg,image/jpg,application/json"
          onChange={event => {
            setIsDeleted(false)
            setSelectedImage(event.target.files[0])
            setFile(event.target.files[0])
            showFile(event)
          }}
        />
      )}
    </div>
  )
}

export default UploadAndDisplayImage
