import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import AddAdminButton from "components/AdminsTabElements/AddAdminButton"
import MultibookButton from "pages/Multibook/Subcomponents/MultibookButton"
import NewFlashCardButton from "./../../pages/Multibook/Subcomponents/NewFlashCardButton"
const Breadcrumb = props => {
  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb mb-0">
            {props.maintitle ? (
              <>
                <BreadcrumbItem>
                  <Link to={props.url}>{props.maintitle}</Link>
                </BreadcrumbItem>
              </>
            ) : (
              ""
            )}

            {props.secondTitle ? (
              <>
                <BreadcrumbItem>
                  <Link to={props.secondUrl}>{props.secondTitle}</Link>
                </BreadcrumbItem>
              </>
            ) : (
              ""
            )}

            {props.thirdTitle ? (
              <>
                <BreadcrumbItem>
                  <Link to={props.thirdUrl}>{props.thirdTitle}</Link>
                </BreadcrumbItem>
              </>
            ) : (
              ""
            )}

            <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
          </ol>
        </div>
      </Col>

      <Col sm={6}>
        <div className="float-end d-none d-md-block">
          {props.addAdminButton ? <AddAdminButton {...props} /> : null}
          {props.MultibookButton ? <MultibookButton {...props} /> : null}
          {props.NewFlashCard ? <NewFlashCardButton {...props} /> : null}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
