import SortableTree, {
  addNodeUnderParent,
  getVisibleNodeCount,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import CustomChaptersRenderer from "../.././../../components/CustomNodeContentRenderer/CustomChapterRenderer "
import { useHistory, useParams } from "react-router-dom"
import { Input } from "reactstrap"
import Select from "react-select"
import { MetaTags } from "react-meta-tags"
import { useEffect, useState } from "react"
import TitleModal from "components/Common/TitleModal"

const Chapters = ({
  chapters,
  setChapters,
  chaptersToDelete,
  setChaptersToDelete,
  setIsNotValid,
  setMoodTests,
  setTitleModal,
  setSomethingChanged,
  somethingChanged,
  titleModal,
}) => {
  const history = useHistory()
  const { multibookId } = useParams()
  const [chapterID, setChapterID] = useState("")

  const addChapter = rowInfo => {
    setSomethingChanged(true)
    const newChapters = addNodeUnderParent({
      treeData: chapters,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        title: "",
        parent: rowInfo.node.parent,
        children: [],
        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
    }).treeData
    setChapters(newChapters)
  }

  const removeChapter = rowInfo => {
    setSomethingChanged(true)
    const toDelete = chaptersToDelete
    if (!String(rowInfo.node.id).includes("temp")) {
      toDelete.push(rowInfo.node.id)
    }
    for (let child of rowInfo.node.children) {
      toDelete.push(child.id)
    }
    setChaptersToDelete(toDelete)
    setChapters(
      removeNodeAtPath({
        treeData: chapters,
        path: rowInfo.path,
        getNodeKey,
      })
    )
  }

  const changeTreeElement = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.id == id) {
        element.title = value
        element.isChanged = true
      }
      if (element.children) {
        changeTreeElement(element.children, id, value)
      }
    })
  }

  useEffect(() => {
    setIsNotValid(stageTestAround())

    setMoodTests(gibsAmountCheck())
  }, [chapters])

  const handleChangeName = (id, value) => {
    setSomethingChanged(true)
    const tree = chapters.map(chapter => chapter)
    changeTreeElement(tree, id, value, 1)
    setChapters(tree)
  }

  const handleSelectChange = (id, value) => {
    setSomethingChanged(true)
    const tree = chapters.map(chapter => chapter)
    changeChapterType(tree, id, value, 1)
    setChapters(tree)
  }

  const changeChapterType = (tree, id, value) => {
    setSomethingChanged(true)
    tree?.forEach(element => {
      if (element.id == id) {
        element.type = value
      }
      element.children.forEach(child => {
        if (child.id == id) {
          child.type = value
        }
      })
    })
  }

  const editChapter = (id, title) => {
    history.push(`/multibooki/multibook${multibookId}/rozdzial${id}`)
  }

  if (!chapters) {
    return null
  }

  const getNodeKey = ({ treeIndex }) => treeIndex
  const count = getVisibleNodeCount({ treeData: chapters })

  const createStageCheckArray = () => {
    const stageArray = []
    let index = 0
    let index1 = 1
    while (index1 < chapters.length) {
      stageArray.push(
        chapters[index].type === "stage_test" &&
          chapters[index1].type === "stage_test"
      )

      index++
      index1++
    }
    return stageArray
  }

  const stageTestChildren = () => {
    return (
      chapters.filter(
        chapters =>
          chapters.children.length > 0 && chapters.type === "stage_test"
      ).length > 0
    )
  }
  const stageTestAround = () => {
    if (!gibsAmountCheck()) {
      return createStageCheckArray().filter(type => type === true).length > 0
        ? createStageCheckArray().filter(type => type === true).length > 0
        : stageTestChildren
        ? stageTestChildren
        : false
    } else {
      return gibsAmountCheck()
    }
  }

  const gibsAmountCheck = () => {
    let tests = chapters.filter(
      chapters =>
        chapters.type === "gibs_test" || chapters.type === "gallup_test"
    )
    if (tests.length > 0)
      return (
        tests.filter(tests => tests.children.length > 0).length > 0 ||
        tests.length > 1 ||
        chapters.filter(
          chapters =>
            chapters.type === "gibs_test" || chapters.type === "gallup_test"
        ).length <=
          chapters.filter(
            chapters =>
              chapters.type === "stage_test" || chapters.type === "basic"
          ).length
      )
  }

  return (
    <div style={{ height: count * 65 + 150 }}>
      <MetaTags>
        <title>Lista rozdziałów How2Edu</title>
      </MetaTags>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      ></div>
      <button
        disabled={
          chapters.filter(
            chapter =>
              chapter.type === "gallup_test" || chapter.type === "gibs_test"
          ).length > 0
            ? true
            : false
        }
        onClick={() => addChapter({ path: [], node: { parent: 0 } })}
        className="btn btn-success"
      >
        Nowy Rozdział
      </button>
      <SortableTree
        nodeContentRenderer={CustomChaptersRenderer}
        treeData={chapters}
        isVirtualized={false}
        onChange={treeData => setChapters(treeData)}
        maxDepth={2}
        rowHeight={64}
        generateNodeProps={rowInfo => {
          const { path } = rowInfo

          const chapterTypes = [
            { label: "Podstawowy", value: "basic" },
            { label: "Test Etapowy", value: "stage_test" },
            { label: "Test Gallupa", value: "gallup_test" },
            { label: "Test Gibsa", value: "gibs_test" },
          ]

          if (
            chapters.filter(
              chapter =>
                chapter.type === "basic" || chapter.type === "stage_test"
            ).length > 0
          ) {
            chapterTypes.splice(2, 3)
          }

          let subtitle

          switch (rowInfo.node.type) {
            case "basic":
              subtitle = "Podstawowy"
              break
            case "stage_test":
              subtitle = "Test Etapowy"
              break
            case "gallup_test":
              subtitle = "Test Gallupa"
              break
            case "gibs_test":
              subtitle = "Test Gibsa"
              break
          }
          let nodeProps = {
            title: (
              <Input
                className="chapter_title_input"
                value={rowInfo.node.title}
                onChange={e =>
                  handleChangeName(rowInfo.node.id, e.target.value)
                }
                style={{ border: "none" }}
              />
            ),
            subtitle: !subtitle ? (
              <Select
                className="subtitle_select css-1s2u09g-control-wasik "
                options={chapterTypes}
                placeholder={"Wybierz typ"}
                onChange={e => handleSelectChange(rowInfo.node.id, e.value)}
              />
            ) : (
              <div className="chapter_subtitle_wrapper"> {subtitle}</div>
            ),
            buttons: [
              path.length < 2 ? (
                <button
                  style={{ marginRight: "10px", marginTop: "1px" }}
                  className="btn btn-primary"
                  key={rowInfo.node._id}
                  onClick={() => addChapter(rowInfo)}
                  disabled={
                    rowInfo.node.type === "gallup_test" ||
                    rowInfo.node.type === "stage_test" ||
                    rowInfo.node.type === "gibs_test"
                  }
                >
                  Dodaj podrozdział
                </button>
              ) : null,
              <button
                style={{ marginRight: "10px", marginTop: "1px" }}
                className="btn btn-primary"
                key={rowInfo.node.id}
                disabled={rowInfo.node.id[0] === "t" || !rowInfo.node.type}
                onClick={() => {
                  if (somethingChanged) {
                    setChapterID(rowInfo.node.id)
                    setTitleModal(true)
                  } else {
                    editChapter(rowInfo.node.id, rowInfo.node.title)
                  }
                }}
              >
                Edytuj
              </button>,
              <button
                style={{ marginTop: "1px" }}
                className="btn btn-primary"
                key={rowInfo.node.id}
                onClick={() => removeChapter(rowInfo)}
              >
                Usuń
              </button>,
              titleModal ? (
                <TitleModal
                  inMultibook={false}
                  titleModal={titleModal}
                  setTitleModal={setTitleModal}
                  rowInfo={rowInfo}
                  multibookId={multibookId}
                  chapterID={chapterID}
                />
              ) : null,
            ],
          }
          return nodeProps
        }}
      />
    </div>
  )
}
export default Chapters
