import { useState, useEffect } from "react"
import React from "react"
import { Input, Label } from "reactstrap"

const MoodQuestion = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { idChapterContent, extended, content } = componentInfo
  const [moodQuestion, setMoodQuestion] = useState(content)

  function moodQuestionHandler(event) {
    inteligentChangeSensorAlpha()
    setMoodQuestion(event.target.value)
    updateComponent(event.target.value)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(moodQuestion)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 150
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        <Label>Pytanie samopoczucia</Label>
        <Input
          style={{ marginBottom: "25px" }}
          onChange={moodQuestionHandler}
          value={moodQuestion}
        />
      </div>
    </React.Fragment>
  )
}

export default MoodQuestion
