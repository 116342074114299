import React, { useEffect } from "react"
import Select from "react-select"
import { Input, Col, Label } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import UploadAndDisplayImage from "../Subcomponents/UploadAndDisplayImages"
import { useState } from "react"
import { useContext } from "react"
import { StoreContext } from "../../../context/Store"
import { useMutation } from "react-query"
import buildFormData from "../../../functions/buildFormData"
import { useQuery, useQueryClient } from "react-query"
import Category from "./Category"
import { useHistory } from "react-router-dom"

const RightBar = ({
  multibook,
  multibookId,
  categoriesList,
  title,
  subtitle,
  access,
  submitChapterChanges,
  setIdMultibook,
  chapters,
  notValid,
  setTitleModal,
  setSomethingChanged,
}) => {
  const queryClient = useQueryClient()
  const { api, multibooksSetUp } = useContext(StoreContext)
  const [visibilityValue, setVisibilityValue] = useState(
    multibookId ? multibook?.visibility : "1"
  )
  const [multibookType, setMultibookType] = useState(
    multibookId ? multibook?.type : "multi"
  )
  const [multibookAccess, setMultibookAccess] = useState(
    multibookId ? access?.idRank : []
  )
  const [success_msg, setsuccess_msg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [categories, setCategories] = useState()
  const [choosenCategoryList, setChoosenCategoryList] = useState([])
  const [mainCategory, setMainCategory] = useState(
    multibookId
      ? { value: multibook?.idCategory, label: multibook?.categoryName }
      : {}
  )
  const [selectedFile, setSelectedFile] = useState("")
  const [isStudentChecked, setisStudentChecked] = useState(false)
  const [isTeacherChecked, setisTeacherChecked] = useState(false)
  const [isParentChecked, setisParentChecked] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (chapters?.length < 1) {
      setVisibilityValue("1")
    }
  }, [chapters])

  useEffect(() => {
    setisStudentChecked(
      multibookAccess?.filter(access => access === "3").length > 0
        ? true
        : false
    )
    setisParentChecked(
      multibookAccess?.filter(access => access === "2").length > 0
        ? true
        : false
    )
    setisTeacherChecked(
      multibookAccess?.filter(access => access === "4").length > 0
        ? true
        : false
    )
  }, [multibookAccess])

  useEffect(() => {
    if (multibook) {
      setMultibookAccess(access?.map(acc => String(acc.idRank)))
      setMultibookType(multibook?.type)
      setVisibilityValue(multibook?.visibility ? multibook?.visibility : "1")
      setMainCategory({
        value: multibook?.idCategory,
        label: multibook?.categoryName,
      })
      setChoosenCategoryList(
        categoriesList.map(category => {
          return { label: category.name, value: category.idCategory }
        })
      )
    }
  }, [multibook, access, categoriesList])

  function mainCategoryHandler(event) {
    setSomethingChanged(true)
    setMainCategory(event)
  }

  function multibookTypeHandler(event) {
    setSomethingChanged(true)
    setMultibookType(event.target.value)
  }

  function mulitbookStateHandler(e) {
    setSomethingChanged(true)
    setVisibilityValue(e.target.value)
  }

  const mutation = useMutation(
    () =>
      api.post(
        "/admin/multibooks/update",
        buildFormData({
          title: title,
          subtitle: subtitle,
          idMultibook: multibookId,
          cover: selectedFile,
          idCategory: mainCategory.value,
          categories: choosenCategoryList.map(category => {
            return category.value
          }),
          visibility: chapters?.length > 0 ? visibilityValue : 1,
          access: accessMembers(),
          type: multibookType,
        })
      ),

    {
      onSuccess: () => {
        queryClient.invalidateQueries("multibook")
        queryClient.invalidateQueries(
          "multibooks",
          multibooksSetUp[1],
          multibooksSetUp[2],
          multibooksSetUp[3],
          multibooksSetUp[4]
        )
        setSomethingChanged(false)
        submitChapterChanges()
        setsuccess_msg(true)
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )

  const accessMembers = () => {
    const access = []
    if (isStudentChecked) {
      access.push("3")
    }
    if (isTeacherChecked) {
      access.push("4")
    }
    if (isParentChecked) {
      access.push("2")
    }
    return access
  }

  const addMultibook = useMutation(
    data =>
      api.post(
        "/admin/multibooks/add",
        buildFormData({
          title: title,
          subtitle: subtitle,
          idCategory: mainCategory.value,
          cover: selectedFile,
          categories: choosenCategoryList.map(category => category.value),
          visibility: visibilityValue,
          access: accessMembers(),
          type: multibookType,
        })
      ),

    {
      onSuccess: data => {
        setIdMultibook(data.data.idMultibook)
        setSomethingChanged(false)

        if (data.data.idMultibook !== undefined) {
          setsuccess_msg(true)
          submitChapterChanges()
          history.push(`/multibooki/multibook${data.data.idMultibook}`)
        } else {
          seterror_dlg(true)
        }
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )
  const removeMultibook = useMutation(
    data =>
      api.post(
        "/admin/multibooks/remove",
        buildFormData({
          idMultibook: multibookId,
        })
      ),

    {
      onSuccess: data => {
        setconfirm_alert(false)
        setIsButtonEnabled(false)
        queryClient.invalidateQueries("multibooks")
        history.push(`/multibooki`)
      },

      onError: error => {
        seterror_dlg(true)
      },
    }
  )

  const { data: categoriesData } = useQuery(
    "categories",
    () => api.get(`/admin/categories`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,

      onSuccess: data => {
        setCategories(
          makeTree(
            data.data.data.map(category => {
              return {
                title: category.name,
                id: category.idCategory,
                parent: category.parent,
              }
            }),
            0
          )
        )
      },
    }
  )

  useEffect(() => {
    setCategories(
      makeTree(
        categoriesData?.data.data.map(category => {
          return {
            title: category.name,
            id: category.idCategory,
            parent: category.parent,
          }
        }),
        0
      )
    )
  }, [categoriesData])

  function makeTree(categories, parent) {
    if (categories !== undefined) {
      const nodes = categories?.map(category => category)
      return nodes
        .filter(node => node.parent === parent)
        .reduce(
          (tree, node) => [
            ...tree,
            {
              ...node,
              children: makeTree(nodes, node.id),
            },
          ],
          []
        )
    }
  }

  function categoryMap(categories) {
    return categories.map(category => {
      return (
        <Category
          key={category.id}
          chosenCategoryList={choosenCategoryList}
          category={category}
          categoryMap={categoryMap}
          categotyListHandler={categotyListHandler}
        />
      )
    })
  }

  function categotyListHandler(e, categoryId, categoryTitle) {
    let variable = choosenCategoryList.map(category => category)
    if (e.target.checked) {
      variable.push({ label: categoryTitle, value: categoryId })
    } else {
      variable = variable.filter(category => categoryId !== category.value)
      if (categoryId === mainCategory.value) {
        setMainCategory({ label: "", value: null })
      }
    }

    setChoosenCategoryList(variable)
  }

  const isStudentCheckedHanddler = e => {
    setSomethingChanged(true)
    setisStudentChecked(!isStudentChecked)
  }

  const isTeacherCheckedHanddler = e => {
    setSomethingChanged(true)
    setisTeacherChecked(!isTeacherChecked)
  }

  const isParentCheckedHanddler = e => {
    setSomethingChanged(true)
    setisParentChecked(!isParentChecked)
  }

  const categoryList = categories ? categoryMap(categories) : null

  if (!multibook && multibookId) {
    return null
  }

  const visibilityStateSelect = visibilityValue ? (
    <select
      value={visibilityValue}
      className="form-select"
      onChange={mulitbookStateHandler}
    >
      {chapters?.length > 0 ? (
        <>
          <option value="1 ">Szkic</option>
          <option value="2">Do akceptacji</option>
          <option value="3">Opublikowane</option>
        </>
      ) : (
        <>
          <option value="1 ">Szkic</option>
        </>
      )}
    </select>
  ) : null

  const multibookTypeSelect = multibookType ? (
    <select
      defaultValue={multibookType}
      className="form-select"
      onChange={multibookTypeHandler}
    >
      <option value="audio">Audiobook</option>
      <option value="multi">Multibook</option>
    </select>
  ) : null

  const checkboxes = multibookAccess ? (
    <div className="form-check">
      <div>
        <div>
          <Label for="parent" htmlFor="parent">
            Rodzic
          </Label>
          <Input
            name="parent"
            type="checkbox"
            value="2"
            id="parent"
            checked={isParentChecked}
            className="form-check-input mb-3 "
            onChange={isParentCheckedHanddler}
          />
        </div>
        <Label for="student" htmlFor="student">
          Uczeń
        </Label>
        <Input
          id="student"
          type="checkbox"
          name="student"
          value="3"
          checked={isStudentChecked}
          className="form-check-input mb-1 mt-1 "
          onChange={isStudentCheckedHanddler}
        />
      </div>
      <div>
        <Label for="teacher" htmlFor="teacher">
          Nauczyciel
        </Label>
        <Input
          id="teacher"
          type="checkbox"
          value="4"
          name="teacher"
          checked={isTeacherChecked}
          className="form-check-input mb-1 mt-1"
          onChange={isTeacherCheckedHanddler}
        />
      </div>
    </div>
  ) : null

  const mainCategorySelect = mainCategory ? (
    <div className="mt-5">
      <label className="h4">Kategoria główna</label>
      <Select
        placeholder
        classNamePrefix="select2-selection"
        options={choosenCategoryList}
        onChange={mainCategoryHandler}
        value={mainCategory}
      />
    </div>
  ) : null

  const link =
    window.location.host.includes("localhost") ||
    window.location.host.includes("dev")
      ? "https://dev.how2edu.pl/multibook-" + multibook?.idMultibook
      : window.location.host.includes("test")
      ? "https://test.how2edu.pl/multibook-" + multibook?.idMultibook
      : "https://app.how2edu.pl/multibook-" + multibook?.idMultibook
  return (
    <div
      className="d-flex align-items-end flex-column bd-highlight mt-5 "
      style={{ minHight: "100%" }}
    >
      <div className="mb-auto p-2 bd-highlight">
        <div className=" mb-2  ">
          <button
            className="btn btn-primary w-100 mb-2"
            disabled={
              mutation.isLoading ||
              addMultibook.isLoading ||
              !mainCategory?.value ||
              (!isStudentChecked && !isParentChecked && !isTeacherChecked) ||
              notValid
            }
            type="button"
            onClick={() => {
              const access = []
              if (isStudentChecked) {
                access.push("3")
              }
              if (isTeacherChecked) {
                access.push("4")
              }
              if (isParentChecked) {
                access.push("2")
              }
              setMultibookAccess(access)
              if (multibookId) {
                mutation.mutate({
                  title: title,
                  subtitle: subtitle,
                  idMultibook: multibookId,
                })
              } else {
                addMultibook.mutate({
                  title: title,
                  subtitle: subtitle,
                  idCategory: mainCategory.value,
                  cover: selectedFile,
                })
              }
            }}
          >
            Zapisz
          </button>
          <a
            className="btn btn-primary w-100 mb-2"
            href={link}
            target="_blank"
            rel="noreferrer"
            type="button"
          >
            Podgląd
          </a>
          <button
            className="btn btn-danger w-100"
            disabled={isButtonEnabled}
            onClick={() => {
              setconfirm_alert(true)
            }}
          >
            Usuń
          </button>
          {confirm_alert ? (
            <SweetAlert
              title="Jesteś pewny?"
              warning
              cancelBtnText="cofnij"
              confirmBtnText="zatwierdź"
              showCancel
              confirmButtonText="Usuń"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                return (
                  removeMultibook.mutate(),
                  setIsButtonEnabled(!isButtonEnabled),
                  setconfirm_alert(false)
                )
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              Akcja będzie nieodwracalna!
            </SweetAlert>
          ) : null}

          <Col>
            {success_msg ? (
              <SweetAlert
                title="Sukces!"
                success
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setsuccess_msg(false)
                }}
                onCancel={() => {
                  setsuccess_msg(false)
                }}
              >
                Wprowadzone dane zostały pomyślnie zapisane!
              </SweetAlert>
            ) : null}
            {error_dlg ? (
              <SweetAlert
                error
                title="Rozmiar okładki nie może przekraczać 14 MB"
                onConfirm={() => {
                  seterror_dlg(false)
                }}
              ></SweetAlert>
            ) : null}
          </Col>
        </div>
        <div className="mb-3">
          <h4>Stan widoczności</h4>
          {visibilityStateSelect}
        </div>
        <div className="mb-3">
          <h4>Typ multibooka</h4>
          {multibookTypeSelect}
        </div>
        <div>
          <h4>Widoczność dla</h4>
          {checkboxes}
        </div>

        <div className="mt-3">
          <h4>Kategorie</h4>

          <div
            style={{
              height: "auto",
              marginLeft: "0px",
            }}
          >
            {categoryList}

            {/* <Categories /> */}
          </div>

          {mainCategorySelect}
          <div className=" mt-3">
            <UploadAndDisplayImage
              cover={multibook?.cover}
              setFile={setSelectedFile}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default RightBar
