import { Label, Col, Input } from "reactstrap"

const StudentTab = ({ usersData, schoolsData }) => {
  const readyOnlyColorInput = {
    backgroundColor: "rgb(240,240,240)",
  }

  return (
    <div className="position-relative">
      <Col md="5">
        <div className="mb-3 position-relative">
          <Label>Szkoła</Label>
          <Input
            style={readyOnlyColorInput}
            required
            type="text"
            className="form-control"
            value={usersData?.data[0]?.class?.name}
            readOnly
          />
        </div>
      </Col>
    </div>
  )
}
export default StudentTab
