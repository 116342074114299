import React from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery } from "react-query"
import { useState } from "react"
import { StoreContext } from "../context/Store"
import { useContext } from "react"
import FlashCardEditButton from "./Multibook/Subcomponents/FlashCardEditButton"
import UniversalTab from "./UniversalTab"

const Flashcards = () => {
  const { api } = useContext(StoreContext)

  const [page, setPage] = useState(1)

  const [maxAmountOfPages, setMaxAmountOfPages] = useState(0)

  const [order_by, setOrder_by] = useState("idFlashcard")

  const [search, setSearch] = useState("")

  const [amount] = useState(40)

  const [order_type, setOrder_Type] = useState("desc")

  const [thOrder, setThOrder] = useState(false)

  const getUrlData = (order_by, page, search, order_type, amount, data) =>
    api.get(
      `/admin/flashcards?order_by=${order_by}&page=${page}&search=${search}&order_type=${order_type}&amount=${amount}`
    )

  const { data, isFetching, refetch, isLoading } = useQuery(
    ["flashcards", page, search, order_type, order_by],
    () => getUrlData(order_by, page, search, order_type, amount, data),
    {
      onSuccess: () => {},
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )

  function setTabOrder_By(variable) {
    return setOrder_by(variable)
  }

  function setTabOrder_Type() {
    return setOrder_Type(thOrder ? "desc" : "asc")
  }

  function setTabThOrder() {
    return setThOrder(!thOrder)
  }

  const tabContent =
    data?.data?.data.length > 0 ? (
      data?.data?.data?.map(flashcard => (
        <tr key={flashcard?.idFlashcard}>
          <td>{flashcard?.idFlashcard}</td>

          <td>{flashcard?.words}</td>
          <td
            dangerouslySetInnerHTML={{
              __html: flashcard.text,
            }}
          ></td>

          <td className="ps-1">
            <FlashCardEditButton
              {...flashcard}
              buttonTitle={"Edytuj"}
              isPrimary={true}
            />
          </td>
        </tr>
      ))
    ) : (
      <h1 className="lack_of_results">Brak wyników</h1>
    )
  const thData = [
    {
      text: "ID",
      orderValue: "idFlashcard",
    },
    {
      text: "Słowa kluczowe",
      orderValue: "words",
    },

    {
      text: "Definicja",
      orderValue: "text",
    },
  ]

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Lista fiszek How2Edu</title>
        </MetaTags>
        <div>
          <Breadcrumbs
            maintitle="Panel"
            title="Użytkownicy"
            breadcrumbItem="Lista fiszek"
            newPerson="Nowy użytkownik"
            url={"/dashboard"}
            MultibookButton={false}
            buttonTitle={"Nowa Fiszka"}
            isPrimary={false}
            NewFlashCard={true}
            searchInput={true}
          />

          <UniversalTab
            isLoading={isLoading}
            amountOfElementsOnPage={10}
            tabTitle={"Lista fiszek"}
            tableStyles={" table-striped mb-0"}
            sortOrderType={"desc"}
            data={data}
            getUrlData={getUrlData}
            orderTableBy={order_by}
            isFetching={isFetching}
            setTabOrder_By={setTabOrder_By}
            setTabOrder_Type={setTabOrder_Type}
            thOrder={thOrder}
            setThOrder={setTabThOrder}
            setSearchWord={setSearch}
            thData={thData}
            tableContent={tabContent}
            refetch={refetch}
            search={search}
            order_by={order_by}
            order_type={order_type}
            page={page}
            setPage={setPage}
            amount={amount}
            setMaxAmountOfPages={setMaxAmountOfPages}
            maxAmountOfPages={maxAmountOfPages}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default Flashcards
