import React, { useEffect } from "react"
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import CustomTestComponentRenderer from "../../../../../../components/CustomNodeContentRenderer/CustomTestComponentRenderer"
import { useState } from "react"
import { Input, Label } from "reactstrap"
import Select from "react-select"
import PointCounter from "./PointCounter"
import Wysiwgw from "components/Common/Wyswigw"

const QuestionSlider = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  chapterType,
  inteligentChangeSensorAlpha,
  invalidAnswers,
  chapterComponentsToDelete,
}) => {
  const { extended, content, content2, idChapterContent } = componentInfo
  const [correctAnswer, setCorrectAnswer] = useState({
    label: content2,
    value: content2,
  })
  const [points, setPoints] = useState(
    content ? (content?.score === 0 ? 1 : content?.score) : 1
  )
  const [question, setQuestion] = useState(content ? content?.question : "")
  const [options, setOptions] = useState(
    content?.options[0]
      ? content?.options
      : [
          {
            option: "",
            score: 1,
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
          },
          {
            option: "",
            score: 1,
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
          },
        ]
  )
  useEffect(() => {
    setQuestion(content ? content?.question : "")
  }, [chapterComponentsToDelete?.length])
 

  const selectOptions = options
    ? [
        {
          label: "--",
          value: null,
        },
        ...options?.map(options => {
          return {
            label: options.option,
            value: options.option,
          }
        }),
      ]
    : []

  useEffect(() => {
    updateComponent(options, question, correctAnswer?.value, points)
  }, [points])

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(options, question, correctAnswer?.value, points)
    }
  }, [extended])

  useEffect(() => {
    setOptions(options)
  }, [points])

  useEffect(() => {
    if (
      options?.filter(option => option.option == correctAnswer.value).length < 1
    ) {
      setCorrectAnswer({ label: "", value: "" })
    }
  }, [options])

  const updateComponent = (options, question, answer, points) => {
    let height = 0

    switch (chapterType) {
      case "stage_test":
        height = options ? options?.length * 80 + 700 : 460
        break
      case "gallup_test":
        height = options ? options?.length * 80 + 600 : 230
        break
      case "gibs_test":
        height = options ? options?.length * 80 + 700 : 230
        break
      default:
        height = options ? options?.length * 80 + 700 : 325
    }
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = {
          options: options,
          question: question,
          score: points === 0 ? 1 : points,
        }
        component.content2 = answer
        component.height = height
      }
      return component
    })
    setChapterComponents(newComponents)
  }

  const enoughAnswers = () => {
    return componentInfo?.content?.options?.length <= 2
  }

  const addOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const newOptions = addNodeUnderParent({
      treeData: options,

      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        option: "",
        score: points === 0 ? 1 : points,
        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
    }).treeData
    setOptions(newOptions, points)
    updateComponent(newOptions, question, correctAnswer?.value, points)
  }
  const changeListElement = (tree, id, value) => {
    tree = tree?.map((element, index) => {
      if (index === id) {
        return { option: value }
      } else {
        return element
      }
    })
    return tree
  }
  const handleChangeName = (index, value) => {
    inteligentChangeSensorAlpha()
    invalidAnswers()
    let tree = options.map(option => option)
    tree = changeListElement(tree, index, value)
    setOptions(tree)
  }

  const handleOnBlur = () => {
    updateComponent(options, question, correctAnswer?.value, points)
  }

  const removeOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const remove = removeNodeAtPath({
      treeData: options,
      path: rowInfo.path,
      getNodeKey,
    })
    setOptions(remove)
    updateComponent(remove, question, correctAnswer?.value)
  }
  const getNodeKey = ({ treeIndex }) => treeIndex
  const componentHeight = options?.length * 80

  const handleChangeCorrectAnswer = value => {
    inteligentChangeSensorAlpha()
    setCorrectAnswer({ label: value, value: value })
    updateComponent(options, question, value)
  }

  function handleChangeQuestion(event, editor) {
    inteligentChangeSensorAlpha()
    const data = editor.getData()
    setQuestion(data)
    updateComponent(options, data, correctAnswer?.value, points)
  }

  const selectComponent = (
    <Select
      classNamePrefix="select2-selection"
      className=" w-100 mb-4"
      value={correctAnswer}
      options={selectOptions}
      onChange={option => handleChangeCorrectAnswer(option.value)}
    />
  )

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div className="componentField">
        <Label>Pytanie</Label>
        <Wysiwgw
          content={handleChangeQuestion}
          value={question}
          maxLength={400}
        ></Wysiwgw>
      </div>
      <div>
        {" "}
        {chapterType === "stage_test" ? (
          <div className="points">
            <Label>Punkty za poprawną odpowiedź</Label>
            <PointCounter
              inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
              chapterType={chapterType}
              element={points}
              setPoints={setPoints}
            />
          </div>
        ) : null}
      </div>
      <div className="componentField">
        <Label>Odpowiedzi</Label>
      </div>

      <button
        disabled={options?.length > 9}
        onClick={() => addOption({ path: [], node: { parent: 0 } })}
        className="btn btn-success mb-1"
      >
        Dodaj
      </button>

      <div style={{ height: componentHeight + 20 + "px" }}>
        <SortableTree
          nodeContentRenderer={CustomTestComponentRenderer}
          treeData={options}
          onChange={treeData => {
            inteligentChangeSensorAlpha()
            setOptions(treeData)
            updateComponent(treeData, question, correctAnswer?.value, points)
            setPoints(points)
          }}
          maxDepth={1}
          rowHeight={80}
          generateNodeProps={rowInfo => {
            let nodeProps = {
              title: (
                <Input
                  type="text"
                  maxLength={40}
                  className="question_order_input mt-2"
                  value={rowInfo.node.option}
                  placeholder={""}
                  resize="none"
                  onChange={e => {
                    inteligentChangeSensorAlpha()
                    handleChangeName(rowInfo.treeIndex, e.target.value)
                  }}
                  onBlur={handleOnBlur}
                  style={{ border: "none" }}
                />
              ),
              subtitle:
                rowInfo.node.option?.length < 40 ? (
                  <p className="slider_char_counter slider_subtitle_wrapper ">
                    {" "}
                    {rowInfo.node.option?.length + "/40"}
                  </p>
                ) : (
                  <p className="slider_char_counter slider_subtitle_wrapper  red">
                    {" "}
                    {rowInfo.node.option.length + "/40"}
                  </p>
                ),
              buttons: [
                <button
                  className="btn btn-primary button_order_input mt-2 me-3"
                  key={rowInfo.node.id}
                  disabled={enoughAnswers()}
                  onClick={() => removeOption(rowInfo)}
                >
                  Usuń
                </button>,
              ],
            }
            return nodeProps
          }}
        />
      </div>
      {chapterType !== "gallup_test" && chapterType !== "gibs_test" ? (
        <div>
          <Label>Poprawna odpowiedź </Label>
          {selectComponent}
        </div>
      ) : null}
    </React.Fragment>
  )
}
export default QuestionSlider
