import React, { useState, useEffect } from "react"
import { Input, Label } from "reactstrap"
import Lottie from "react-lottie"

const Image = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { idChapterContent, extended, content, content2 } = componentInfo
  const [selectedImage, setSelectedImage] = useState(content ? content : null)
  const [isDeleted, setIsDeleted] = useState(true)
  const [jsonContent, setJsonContent] = useState("")
  const [fileType, setFileType] = useState("")
  const [animation, setAnimation] = useState(content2 === "true" ? true : false)

  const center = {
    display: "block",
    marginLeft: "auto",
    marignRight: "auto",
    width: "64%",
    marginTop: "40px",
  }
  const lottieOptions = {
    loop: "true",
    autoplay: "true",
    animationData: jsonContent,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const showFile = e => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = e => {
      const text = e.target.result

      setJsonContent(JSON.parse(text))
    }
    reader.readAsText(e.target.files[0])
  }
  useEffect(() => {
    setFileType(selectedImage?.name?.split(".").pop())
  }, [selectedImage])

  useEffect(() => {
    if (typeof selectedImage !== "string") {
      setIsDeleted(false)
    }
  }, [selectedImage])

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(selectedImage)
      updateComponent2(animation)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 300
      }
      return component
    })

    setChapterComponents(newComponents)
  }
  const updateComponent2 = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content2 = opt
        component.height = 300
      }
      return component
    })

    setChapterComponents(newComponents)
  }
  const animationHandler = e => {
    setAnimation(!animation)
    updateComponent2(!animation)
  }
  useEffect(() => {
    setSelectedImage(content)
  }, [content])

  useEffect(() => {
    if (selectedImage?.content?.nm !== undefined) {
      setJsonContent(selectedImage?.content)
      setFileType("json")
    }
  }, [content])

  if (!extended) {
    return null
  }

  return (
    <div style={{ maxWidth: "50%" }} className="d-flex justify-content-start">
      <div>
        {selectedImage === null ? (
          <div
            className="dropzone"
            style={{ minHeight: "50px", height: "200px", padding: "0" }}
          >
            {" "}
            <i style={center} className="ion ion-md-image display-1"></i>
          </div>
        ) : (
          selectedImage && (
            <div>
              <div>
                <Lottie options={lottieOptions} height={200} width={275} />

                <br />

                <button
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    inteligentChangeSensorAlpha()
                    setSelectedImage(null)
                    setIsDeleted(false)
                    updateComponent(null)
                  }}
                >
                  Usuń
                </button>
              </div>
            </div>
          )
        )}
        {selectedImage ? null : (
          <Input
            className="mt-1"
            type="file"
            name="myImage"
            accept="application/json,image/svg"
            onChange={event => {
              inteligentChangeSensorAlpha()
              setIsDeleted(false)
              setSelectedImage(event.target.files[0])
              updateComponent(event.target.files[0])
              showFile(event)
            }}
          />
        )}
      </div>
      <div style={{ marginLeft: "20px" }}>
        {" "}
        <Label style={{ marginRight: "10px" }} for="loop of gallows">
          Zapętlanie Animacji
        </Label>
        <input
          style={{ marginTop: "7px" }}
          type="checkbox"
          id="loop of gallows"
          onChange={animationHandler}
          checked={animation}
        ></input>
        <div style={{ marginTop: "20px" }}>
          <p>Obsługiwane typy:</p>
          <ul>
            <li>json</li>
          </ul>
          <p>Link do animacji:</p>
          {selectedImage ? (
            <a target="_blank" href={selectedImage.url}>
              {selectedImage.url}
            </a>
          ) : (
            "Link zostanie wygenerowany po zapianiu zmian"
          )}
        </div>
      </div>
    </div>
  )
}

export default Image
