import React, { useContext } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "@nosferatu500/react-sortable-tree/style.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import Dashboard from "pages/Dashboard"
import Login from "./pages/Login"
import Authorization from "./Authorization"
import "./assets/scss/theme.scss"
import Layout from "components/AppLayout/Layout/Layout"
import Sidebar from "components/AppLayout/Sidebar"
import Header from "components/AppLayout/Header"
import Admins from "pages/Admins"
import Users from "pages/Users"
import Multibook from "pages/Multibook/Multibook"
import RecoverPassword from "components/ResetRecoverpswd/RecoverPassword"
import Multibooks from "pages/Multibooks"
import Categories from "pages/Categories"
import ChapterContent from "pages/Multibook/Subcomponents/Chapters/ChapterContent/ChapterContent"
import Flashcards from "pages/Flashcards"
import NewFlashCard from "pages/Multibook/Subcomponents/NewFlashCard"
import MoodCheck from "pages/MoodCheck"
import SubscriberMenagment from "pages/SubscriberMenagment"
import TestCategory from "./pages/TestCategory"
import Health from "./pages/Health"

function App() {
  const sidebar = <Sidebar />
  const header = <Header />
  const content = (
    <div>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/admnistratorzy" component={Admins} />
        <Route exact path="/nowa_fiszka" component={NewFlashCard} />
        <Route exect path="/uzytkownicy" component={Users} />
        <Route
          exact
          path="/multibooki/multibook:multibookId/rozdzial:chapterId"
          component={ChapterContent}
        />
        <Route
          exact
          path="/multibooki/multibook/nowy_multibook"
          component={Multibook}
        />
        <Route
          exect
          path="/multibooki/multibook:multibookId"
          component={Multibook}
        />
        <Route exact path="/multibooki" component={Multibooks} />
        <Route exact path="/kategorie" component={Categories} />
        <Route exact path="/fiszki" component={Flashcards} />
        <Route exact path="/samopoczucie" component={MoodCheck} />
        <Route
          exatt
          path="/fiszki/fiszka:idFlashcard"
          component={NewFlashCard}
        />
        <Route exat path="/abonamenty" component={SubscriberMenagment} />
        <Route exact path="/kategorie_testow" component={TestCategory} />
      </Switch>
    </div>
  )
  return (
    <Authorization>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/password-recover" component={RecoverPassword} />
          <Route exact path="/health" component={Health} />
          <React.Fragment>
            <Layout header={header} sidebar={sidebar} content={content} />
          </React.Fragment>
        </Switch>
      </Router>
    </Authorization>
  )
}
export default App
