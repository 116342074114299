import React, { useEffect } from "react"
import { useState } from "react"
import Wyswigw from "components/Common/Wyswigw"

const Fact = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
  chapterComponentsToDelete,
}) => {
  const { idChapterContent, extended, content } = componentInfo
  const [curioContent, setCurioContent] = useState(
    content ?? `<p style="text-align:justify;"></p>`
  )

  useEffect(() => {
    setCurioContent(content ?? `<p style="text-align:justify;"></p>`)
  }, [chapterComponentsToDelete?.length])

  function curioHandler(event, editor) {
    const data = editor.getData()
    inteligentChangeSensorAlpha()

    setCurioContent(data)

    updateComponent(data)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(curioContent)
    }
  }, [extended, chapterComponents])

  const updateComponent = (opt, correct) => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.content2 = correct
        component.height = 400
      }
      return component
    })
    setChapterComponents(newComponents)
  }
  const wyswigw = (
    <Wyswigw
      content={curioHandler}
      value={curioContent}
      Toolbar={["bold", "italic", "undo", "redo", "alignment", "heading"]}
    />
  )

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>{wyswigw}</div>
    </React.Fragment>
  )
}

export default Fact
