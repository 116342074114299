import { StoreContext } from "context/Store"
import React, { useContext } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, CardTitle, Table } from "reactstrap"
import Loader from "../components/UI/Loader"
import "katex/dist/katex.min.css"

const Dashboard = () => {
  const { logo } = useContext(StoreContext)

  return (
    <React.Fragment>
      <MetaTags>
        <title>How2Edu</title>
      </MetaTags>
      <div className="dashboard_title">
        <h2>Witaj w panelu administracyjnym aplikacji </h2>
        <img src={logo} height={60} className="ms-2 "></img>
      </div>

      <div className="dashboard_order">
        <div className="dashboard_first_background"></div>
        <div className="dashboard_table">
          <div className="dashboard_card_body">
            <Col>
              <Card>
                <CardBody style={{ width: "525px" }}>
                  <div className="d-flex justify-content-between dashboard_table_main_title">
                    <CardTitle className="dashboard_table_header">
                      Lista adresów do wszystkich środowisk
                    </CardTitle>
                  </div>
                  {false ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <div className="table-responsive ">
                      <Table
                        className="table-striped mb-0"
                        className="dashboard_table"
                      >
                        <thead>
                          <tr>
                            <th className="dashboard_tr">Nazwa środowiska</th>
                            <th className="dashboard_tr">Link do środowiska</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="dashboard_line">
                            <th className="dashboard_table_th">Landing page</th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://www.how2edu.pl/ "
                                target="_blank"
                              >
                                https://www.how2edu.pl/
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th className="dashboard_table_th">App</th>
                            <td className="dashboard_table_content">
                              <a href="https://app.how2edu.pl " target="_blank">
                                https://app.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th className="dashboard_table_th">Store</th>
                            <td className="dashboard_table_content">
                              <a
                                href=" https://store.how2edu.pl "
                                target="_blank"
                              >
                                https://store.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr className="dashboard_line">
                            <th className="dashboard_table_th">Poweradmin</th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://poweradmin.how2edu.pl "
                                target="_blank"
                              >
                                https://poweradmin.how2edu.pl
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <th className="dashboard_table_th">Test app</th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://test.how2edu.pl "
                                target="_blank"
                              >
                                https://test.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th className="dashboard_table_th">Test store</th>
                            <td className="dashboard_table_content">
                              <a
                                href=" https://teststore.how2edu.pl "
                                target="_blank"
                              >
                                https://teststore.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr className="dashboard_line">
                            <th className="dashboard_table_th">
                              Test poweradmin
                            </th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://testpoweradmin.how2edu.pl  "
                                target="_blank"
                              >
                                https://testpoweradmin.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row" className="dashboard_table_th">
                              Dev app
                            </th>
                            <td className="dashboard_table_content">
                              <a href="https://dev.how2edu.pl" target="_blank">
                                https://dev.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th className="dashboard_table_th">Dev store</th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://devstore.how2edu.pl"
                                target="_blank"
                              >
                                https://devstore.how2edu.pl
                              </a>
                            </td>
                          </tr>
                          <tr className="dashboard_line">
                            <th className="dashboard_table_th">
                              Dev Poweradmin
                            </th>
                            <td className="dashboard_table_content">
                              <a
                                href="https://devpoweradmin.how2edu.pl "
                                target="_blank"
                              >
                                https://devpoweradmin.how2edu.pl
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
        <div className="dashboard_second_background"></div>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
