import { Table, Col, CardTitle } from "reactstrap"
import PaymentHistory from "components/UI/PaymentHistory"

const ParentTab = ({ idUser, usersData }) => {
  const styles = {
    textAlign: "center",
  }

  return (
    <Col mb="5">
      <CardTitle className="h4 pb-3">Dzieci</CardTitle>
      <Table className="table-bordered" style={styles}>
        <thead>
          <tr>
            <th>Dziecko</th>
            <th>Posiadany pakiet</th>
            <th>Aktualny abonament</th>
            <th>Aktualny do</th>
          </tr>
        </thead>
        <tbody>
          {usersData?.data[0]?.childs?.map((child, index) => (
            <tr key={index}>
              <td>
                {child.name} {child.surname}
              </td>

              <td>{child.subscription.type}</td>
              <td>
                {child.subscription.status === "active"
                  ? "aktywny"
                  : child.subscription.status === "waiting"
                  ? "niezatwierdzony"
                  : "nieaktywyny"}
              </td>
              
              <td>{child.subscription.active_to.slice(0,10)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  )
}
export default ParentTab
