
import { useHistory } from "react-router-dom"

const MultibookButton = props => {


  const history = useHistory()

  function pathCheck() {
    if (props.idMultibook) {

      history.push(`/multibooki/multibook${props.idMultibook}`)
    } else {
      history.push(`/multibooki/multibook/nowy_multibook`)
    }
  }

  return (
    <div>
      {props.idMultibook ? (
        <button className={"btn btn-primary"} onClick={() => pathCheck()}>
          {props.buttonTitle}
        </button>
      ) : (
        <button className={"btn btn-success"} onClick={() => pathCheck()}>
          {props.buttonTitle}
        </button>
      )}
    </div>
  )
}
export default MultibookButton
