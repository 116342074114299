import CounterInput from "react-counter-input"
import { useEffect } from "react"

const PointCounter = ({ element, setPoints, inteligentChangeSensorAlpha }) => {
  return (
    <div
      className="d-flex flex-row me-2 "
      style={{ marginTop: "6px", color: "#b3b3b3" }}
    >
      <CounterInput
        style={{ color: "black" }}
        inputStyle={{ color: "black" }}
        count={Number(element ? element : 1)}
        onCountChange={count => {
          if (count > 0) {
            inteligentChangeSensorAlpha()

            setPoints(count)
          }
        }}
      />
    </div>
  )
}
export default PointCounter
