import { Link } from "react-router-dom"

const MultibookButton = props => {
  return (
    <div>
      <Link to="/nowa_fiszka" className="btn btn-success">
        {props.buttonTitle}
      </Link>
    </div>
  )
}
export default MultibookButton
