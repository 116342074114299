import Wysiwgw from "components/Common/Wyswigw"
import React, { useEffect } from "react"
import { useState } from "react"

const Question = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
  chapterComponentsToDelete,
}) => {
  const { extended, content, idChapterContent } = componentInfo
  const [questionContent, setQuestionContent] = useState(content)

  useEffect(() => {
    setQuestionContent(content)
  }, [chapterComponentsToDelete?.length])

  function questionContentHandler(event, editor) {
    const data = editor.getData()
    inteligentChangeSensorAlpha()
    setQuestionContent(data)
    updateComponent(data)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(questionContent)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 400
      }
      return component
    })

    setChapterComponents(newComponents)
  }

  const wyswigw = (
    <Wysiwgw
      content={questionContentHandler}
      value={questionContent}
      Toolbar={["bold", "italic", "undo", "redo"]}
    />
  )

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div>{wyswigw}</div>
    </React.Fragment>
  )
}
export default Question
