import { useMutation, useQueryClient } from "react-query"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Label,
  Button,
  Container,
} from "reactstrap"
import { StoreContext } from "../../context/Store.js"
import { useContext } from "react"
import buildFormData from "functions/buildFormData.js"
import { useForm } from "react-hook-form"

const AddAdminButton = props => {
  const queryClient = useQueryClient()
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState(null)
  const [zipCode] = useState("")
  const [birthdate, setBirthday] = useState("")
  const [idRank, setIdRank] = useState(1)
  const [success_msg, setsuccess_msg] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_description] = useState("")
  const { api } = useContext(StoreContext)
  const [response, setResponse] = useState("")
  const [dateInput, setDateInput] = useState(birthdate)
  const [mutationIsLoading,setMutationIsLoading] = useState(false)

  const mutation = useMutation(
    () =>
      api.post(
        "/admin/add",
        buildFormData({
          idRank: idRank,
          name: name,
          surname: surname,
          email: email,
          phone: phone,
          birthdate: birthdate,
        })
      ),

    {
      onSuccess: data => {
      setMutationIsLoading(false)
        queryClient.invalidateQueries("admin")
        setsuccess_msg(true)
        
      },

      onError: error => {
        setResponse(error?.response?.data?.errors?.email)
        console.log(response)
        seterror_dlg(true)
        console.log(error.response.data.errors.email)
       
        
      
         
        //  seterror_dlg(true)
  
          
      },
    }
  )




  

  function bitrhDayChange(e) {
    setBirthday(e.target.value)
    setDateInput(birthdate)
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  function surnameChange(e) {
    setSurname(e.target.value)
  }

  function nameChange(e) {
    setName(e.target.value)
  }

  function emailChange(e) {
    setEmail(e.target.value)
  }

  function phoneChange(e) {
    const result = e.target.value.replace(/\D/g, '')
    console.log(result)
    setPhone(result)
  }
  

  const styles = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }

  function handleChange(event) {
    event.target.value === "Admin" ? setIdRank(1) : setIdRank(2)
  }

  
  return (
    <div>
      <button
        type="button"
        onClick={() => {
          tog_xlarge()
        }}
        className="btn btn-success waves-effect waves-light"
        data-toggle="modal"
        data-target=".bs-example-modal-xl"
      >
        {props.buttonName}
      </button>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_fullscreen()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            {props.newPerson}
          </h5>
          <Button
            onClick={() => {
              setmodal_xlarge(false)
              
            }}
            style={{ height: "14%" }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div style={styles}>
            <React.Fragment>
              <div style={styles}>
                <Container fluid={true}>
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <h4 className="card-title pb-4">Dane</h4>

                            <div>
                              <Row>
                                <Col md="4">
                                  <div className="mb-3 position-relative">
                                    <Label>Imię</Label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control"
                                    
                                      onChange={nameChange}
                                      placeholder="Imię"
                                    />
                                   
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3 position-relative">
                                    <Label>Nazwisko</Label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      required
                                     
                                      onChange={surnameChange}
                                      placeholder="Nazwisko"
                                    />
                                    
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className=" position-relative">
                                    <Label>E-mail</Label>

                                    <input
                                      type="text"
                                      className="form-control"
                                     required
                                      onChange={emailChange}
                                      placeholder="przykład@gmail.com"
                                    />
                                  </div>
                                 
                                </Col>
                              </Row>
                              <Row>
                                <Col md="4">
                                  <Label>Rola</Label>
                                  <select
                                    className="form-select"
                                    onChange={handleChange}
                                  >
                                    <option value="Admin">Admin</option>
                                    <option value="Redaktor">Redaktor</option>
                                  </select>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3 position-relative">
                                    <Label>Numer telefonu</Label>
                                    <input
                                      type="text"
                                      required
                                      placeholder="numer telefonu"
                                      className="form-control"
                                      pattern="[0-9]*"
                                      minLength={9}
                                      maxLength={12}
                                     value={phone}
                                      onChange={phoneChange}
                                    />
                                  
                                  </div>
                                </Col>
                                <Col md="4">
                                  <div className="mb-3 position-relative">
                                    <Label>Data urodzenia</Label>
                                    <input
                                      type="date"
                                      className="form-control"
                                    required
                                      onChange={bitrhDayChange}
                                      
                                    />
                                  
                                  </div>
                                </Col>
                              </Row>
                              <div>
                                {success_dlg ? (
                                  <SweetAlert
                                    success
                                    title="Dodano nowego Administratora!"
                                    onConfirm={() => {
                                      setsuccess_dlg(false)
                                    }}
                                  >
                                    {dynamic_description}
                                  </SweetAlert>
                                ) : null}

                                {error_dlg ? (
                                  <SweetAlert
                                    error
                                    title= {response}
                                    onConfirm={() => {
                                      seterror_dlg(false)
                                    }}
                                  >
                              
                                  </SweetAlert>
                                ) : null}
                                <div className="d-flex justify-content-end pt-3  ">
                                  <Button
                                    color="success"
                                   
                                    id="sa-success"
                                    onClick={mutation.mutate
  
                                      
                                    }
                                    disabled={mutation.isLoading}
                                   
                                  >
                                    Dodaj Administartora
                                  </Button>
                                </div>
                                {success_msg ? (
                                  <SweetAlert
                                    title="Operacja udana!"
                                    success
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() => {
                                      setsuccess_msg(false)
                                      setmodal_xlarge(false)
                                    }}
                                    onCancel={() => {
                                      setsuccess_msg(false)
                                    }}
                                  >
                                    Administrator stworzony
                                  </SweetAlert>
                                ) : null}
                              </div>
                            </div>
                   
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </React.Fragment>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddAdminButton
