import React, { useState } from "react"
import ToolDropMenu from "../Common/ToolDropMenu"
import { useContext } from "react"
import { StoreContext } from "../../context/Store"
import { Form, Dropdown, DropdownMenu, Input, Button } from "reactstrap"
import { Link } from "react-router-dom"
import logoImg from "../../assets/images/how2edu.svg"
import EquationModal from "components/Common/EquationModal"

const Header = () => {
  const { setIsSidebarOpen, isSidebarOpen, logo } = useContext(StoreContext)
  const [search, setsearch] = useState(false)
  const [displayEquationModal, setDisplayEquationModal] = useState(false)
  const [equationContent, setEquationContent] = useState("[math][/math]")

  function displayEquationModalHanlder() {
    setDisplayEquationModal(!displayEquationModal)
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 997) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-disable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" style={{ padding: "0" }}>
              <Link to="/dashboard" className="logo logo-dark">
                <span
                  className="logo-sm "
                  style={isSidebarOpen ? { marginLeft: "50px" } : null}
                >
                  <img src={logoImg} alt="" height="35" />
                </span>
                <span className="logo-lg">
                  <img src={logoImg} alt="" height="40" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              style={isSidebarOpen ? { marginLeft: "50px" } : null}
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
                setIsSidebarOpen(!isSidebarOpen)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu"></i>
            </button>
            <div className="equation_button">
              <Button
                type="button "
                style={
                  displayEquationModal
                    ? { backgroundColor: "lightgray", color: " #555b6d" }
                    : null
                }
                className="btn btn-sm px-3 font-size-24 header-item  ion ion-md-calculator calc_button "
                onClick={displayEquationModalHanlder}
              ></Button>
            </div>
            {displayEquationModal ? (
              <EquationModal
                equationContent={equationContent}
                setEquationContent={setEquationContent}
                setDisplayEquationModal={setDisplayEquationModal}
                displayEquationModal={displayEquationModal}
              />
            ) : null}
          </div>

          <div className="d-flex">
            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <div />
              </button>
              <div className="btn header-item noti-icon ">
                <ToolDropMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
