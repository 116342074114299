import React, { useEffect, useState } from "react"

import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useContext } from "react"
import { StoreContext } from "context/Store"
import Loader from "../components/UI/Loader"

import { Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import buildFormData from "../functions/buildFormData"
import SweetAlert from "react-bootstrap-sweetalert"
import { MetaTags } from "react-meta-tags"
import doodle_1 from "./../assets/images/doodle-sub_1.png"
import doodle_2 from "./../assets/images/doodle-sub_2.png"
import doodle_3 from "./../assets/images/doodle-sub_3.png"

const SubscriberMenagment = () => {
  const { api } = useContext(StoreContext)
  const queryClient = useQueryClient()
  const [success_msg, setsuccess_msg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [idSubscriptionType, setIdSubscriptionType] = useState("")
  const [monthPrice, setMonthPrice] = useState("")
  const [halfYearPrice, setHalfYearPrice] = useState("")
  const [yearPrice, setYearPrice] = useState()

  const {
    data: subTypes,
    isLoading,
    isFetching,
  } = useQuery("subTypes", () => api.get(`/admin/subscriptions/get_types`), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: data => {
      setSubscriptions(data.data)
      setYearPrice(data?.data[2]?.subscriptionPrice)
      setMonthPrice(data?.data[0]?.subscriptionPrice)
      setHalfYearPrice(data?.data[1]?.subscriptionPrice)
    },
  })

  const updateSubscriptionsPrice = useMutation(
    subscriptionData =>
      api.post(
        "/admin/subscriptions/save_type",
        buildFormData(idPriceHandler(subscriptionData))
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subTypes")
      },
      onError: () => {
        seterror_dlg(true)
      },
    }
  )
  function idPriceHandler(subscriptionData) {
    return {
      price: subscriptionData.price,
      idSubscriptionType: subscriptionData.idSubscriptionType,
    }
  }

  useEffect(() => {
    setYearPrice(subTypes?.data[2]?.subscriptionPrice / 100)
    setMonthPrice(subTypes?.data[0]?.subscriptionPrice / 100)
    setHalfYearPrice(subTypes?.data[1]?.subscriptionPrice / 100)
  }, [subTypes])

  function monthPriceHandler(e) {
    const result = e.target.value.replace(/\D/g, "")
    setMonthPrice(result)
  }
  function halfYearHandler(e) {
    const result = e.target.value.replace(/\D/g, "")
    setHalfYearPrice(result)
  }
  function yearHandler(e) {
    const result = e.target.value.replace(/\D/g, "")
    setYearPrice(result)
  }

  return (
    <div>
      <MetaTags>
        <title>Zarządzanie abonamentami How2Edu</title>
      </MetaTags>

      <Breadcrumbs
        maintitle="Panel"
        breadcrumbItem="Zarządzanie abonamentami"
        url={"/dashboard"}
      />
      <Col style={{ minHeight: "730px" }}>
        <Card>
          <CardBody style={{ minHeight: "142px", position: "relative" }}>
            <CardTitle className="h3" style={{ fontSize: "18px" }}>
              Abonamenty
            </CardTitle>
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-center">
                  <div className="abonament_area">
                    <div className="abonament_type">Miesięczny</div>
                    <div>
                      <img src={doodle_1} height={163} />
                    </div>
                    <div className="abonament_text">Obecna cena</div>
                    <div className="abonament_price">
                      {subTypes?.data[0]?.subscriptionPrice / 100 + " zł"}
                    </div>
                    <div className="abonament_text">Nowa cena</div>
                    <div>
                      <input
                        type="text"
                        className="abonament_input"
                        placeholder={
                          subTypes?.data[0]?.subscriptionPrice / 100 + " zł"
                        }
                        value={monthPrice + " zł"}
                        maxLength={7}
                        onChange={monthPriceHandler}
                      ></input>
                    </div>
                    <div>
                      {isFetching && idSubscriptionType === 1 ? (
                        <Loader />
                      ) : (
                        <button
                          className="abonament_button"
                          onClick={() => {
                            setIdSubscriptionType(1)
                            let subscriptionData = {
                              price: monthPrice,
                              idSubscriptionType: 1,
                            }
                            updateSubscriptionsPrice.mutate(subscriptionData)
                          }}
                        >
                          Zapisz
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="abonament_area">
                    <div className="abonament_type">Półroczny</div>
                    <div>
                      <img src={doodle_2} height={163} />
                    </div>
                    <div className="abonament_text">Obecna cena</div>
                    <div className="abonament_price">
                      {subTypes.data[1]?.subscriptionPrice / 100 + " zł"}
                    </div>
                    <div className="abonament_text">Nowa cena</div>
                    <div>
                      <input
                        className="abonament_input"
                        placeholder={
                          subTypes[1]?.subscriptionPrice / 100 + " zł"
                        }
                        value={halfYearPrice + " zł"}
                        onChange={halfYearHandler}
                        maxLength={7}
                      ></input>
                    </div>
                    <div>
                      {isFetching && idSubscriptionType === 2 ? (
                        <Loader />
                      ) : (
                        <button
                          className="abonament_button"
                          onClick={() => {
                            setIdSubscriptionType(2)
                            let subscriptionData = {
                              price: halfYearPrice,
                              idSubscriptionType: 2,
                            }
                            updateSubscriptionsPrice.mutate(subscriptionData)
                          }}
                        >
                          Zapisz
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="abonament_area">
                    <div className="abonament_type">Roczny</div>

                    <div>
                      <img src={doodle_3} height={163} />
                    </div>
                    <div className="abonament_text">Obecna cena</div>
                    <div className="abonament_price">
                      {subTypes.data[2]?.subscriptionPrice / 100 + " zł"}
                    </div>
                    <div className="abonament_text">Nowa cena</div>
                    <div>
                      <input
                        className="abonament_input"
                        placeholder={
                          subTypes.data[2]?.subscriptionPrice / 100 + " zł"
                        }
                        value={yearPrice + " zł"}
                        onChange={yearHandler}
                        maxLength={7}
                      ></input>
                    </div>
                    <div>
                      {isFetching && idSubscriptionType === 3 ? (
                        <Loader />
                      ) : (
                        <button
                          className="abonament_button"
                          onClick={() => {
                            setIdSubscriptionType(3)
                            let subscriptionData = {
                              price: yearPrice,
                              idSubscriptionType: 3,
                            }
                            updateSubscriptionsPrice.mutate(subscriptionData)
                          }}
                        >
                          Zapisz
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      {error_dlg ? (
        <SweetAlert
          error
          title="wystąpił błąd"
          onConfirm={() => {
            seterror_dlg(false)
          }}
        ></SweetAlert>
      ) : null}

      {success_msg ? (
        <SweetAlert
          title="Operacja udana!"
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        ></SweetAlert>
      ) : null}
    </div>
  )
}

export default SubscriberMenagment
