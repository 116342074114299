import RightBar from "../Multibook/Subcomponents/RightBar"
import { Card, Col, CardBody, Input } from "reactstrap"
import { useContext, useEffect, useState } from "react"
import { useQuery, useMutation } from "react-query"
import { StoreContext } from "../../context/Store"
import Chapters from "./Subcomponents/Chapters/chapters"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useParams } from "react-router-dom"
import buildFormData from "../../functions/buildFormData"
import Loader from "components/UI/Loader"
import MetaTags from "react-meta-tags"
import { Alert } from "reactstrap"
import TitleModal from "components/Common/TitleModal"
const Multibook = props => {
  const { api } = useContext(StoreContext)
  const [multibook, setMultibook] = useState()
  const [title, setTitle] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const [multibookType, setMultibookType] = useState()
  const [visiblityState, setVisiblityState] = useState()
  const [access, setAccess] = useState()
  const [chapters, setChapters] = useState([])
  const [chaptersToDelete, setChaptersToDelete] = useState([])
  const [categories, setCategories] = useState([])
  const { multibookId } = useParams()
  const [idMultibook, setIdMultibook] = useState(multibookId)
  const [notValid, setIsNotValid] = useState(false)
  const [moodTests, setMoodTests] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState("")
  const [somethingChanged, setSomethingChanged] = useState(false)
  const [titleModal, setTitleModal] = useState(false)

  const {
    refetch: refetchMultibook,
    data: multibookData,
    isLoading,
  } = useQuery(
    ["multibook", multibookId],
    () =>
      api.post(
        `/admin/multibooks/show`,
        buildFormData({
          idMultibook: multibookId,
        })
      ),
    {
      enabled: multibookId ? true : false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setMultibook(data.data.multibook)
        setTitle(data.data.multibook.title)
        setSubtitle(data.data.multibook.subtitle)
        setMultibookType(data.data.multibook.type)
        setVisiblityState(data.data.multibook.visibility)
        setAccess(data.data.access)
        setCategories(data.data.categories)
      },
      onError: error => {},
    }
  )

  const {
    refetch,
    isLoading: isChaptersLoading,
    data: chaptersData,
  } = useQuery(
    ["chapters", multibookId],
    () =>
      api.post(
        `/admin/multibooks/chapters`,
        buildFormData({
          idMultibook: multibookId,
        })
      ),
    {
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        let newTree = makeTree(
          data.data.data?.map((chapter, index) => {
            return {
              title: chapter.title,
              id: chapter.idChapter,
              parent: chapter.parent,
              order: chapter.order,
              expanded: false,
              type: chapter.type,
              subject_id: chapter.subject_id,
            }
          }),
          0
        )

        newTree = newTree?.map((chapter, index) => {
          return {
            children: chapter.children,
            expanded: chapters[index]?.expanded
              ? chapters[index].expanded
              : false,
            id: chapter.id,
            order: chapter.order,
            parent: chapter.parent,
            title: chapter.title,
            type: chapter.type,
            subject_id: chapter.subject_id,
          }
        })

        setChapters(newTree)
      },
    }
  )

  useEffect(() => {
    if (multibookData !== undefined && chaptersData !== undefined)
      setMultibook(multibookData?.data.multibook)
    setTitle(multibookData?.data.multibook.title)
    setSubtitle(multibookData?.data.multibook.subtitle)
    setMultibookType(multibookData?.data.multibook.type)
    setVisiblityState(multibookData?.data.multibook.visibility)
    setAccess(multibookData?.data.access)
    setCategories(multibookData?.data.categories)
  }, [multibookData, chaptersData])

  useEffect(() => {
    if (chaptersData !== undefined) {
      let newTree = makeTree(
        chaptersData?.data.data?.map((chapter, index) => {
          return {
            title: chapter.title,
            id: chapter.idChapter,
            parent: chapter.parent,
            order: chapter.order,
            expanded: false,
            type: chapter.type,
            subject_id: chapter.subject_id,
          }
        }),
        0
      )

      newTree = newTree?.map((chapter, index) => {
        return {
          children: chapter.children,
          expanded: chapters[index]?.expanded
            ? chapters[index].expanded
            : false,
          id: chapter.id,
          order: chapter.order,
          parent: chapter.parent,
          title: chapter.title,
          type: chapter.type,
          subject_id: chapter.subject_id,
        }
      })

      setChapters(newTree)
    }
  }, [chaptersData, multibookData])

  const updateTree = useMutation(
    () =>
      api.post(
        "/admin/multibooks/chapters/update",
        buildFormData({
          idMultibook: idMultibook,
          chapters: convertData(chapters),
        })
      ),
    {
      onSuccess: data => {
        refetch()
      },
    }
  )

  const deleteChapters = useMutation(() =>
    api.post(
      "/admin/multibooks/chapters/remove",
      buildFormData({
        idChapter: chaptersToDelete,
      })
    )
  )

  const convertData = chapters => {
    return chapters.map(chapter => {
      if (String(chapter.id).includes("temp")) {
        return {
          title: chapter.title,
          childs: convertData(chapter.children),
          type: chapter.type,
          subject_id: chapter.subject_id,
        }
      }
      return {
        idChapter: chapter.id,
        title: chapter.title,
        childs: convertData(chapter.children),
        type: chapter.type,
        subject_id: chapter.subject_id,
      }
    })
  }

  function makeTree(categories, parent) {
    if (categories !== undefined) {
      const nodes = categories
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map(category => category)
      return nodes

        .filter(node => node.parent === parent)
        .reduce(
          (tree, node) => [
            ...tree,
            {
              ...node,
              children: makeTree(nodes, node.id),
            },
          ],
          []
        )
    }
  }

  const handleChangeTitle = e => {
    setSomethingChanged(true)
    setTitle(e.target.value)
  }

  const handleChangeSubtitle = e => {
    setSomethingChanged(true)
    setSubtitle(e.target.value)
  }

  const handleSubmitAbort = () => {
    setSomethingChanged(true)
    setChaptersToDelete([])
    refetch()
    refetchMultibook()
  }

  const handleSubmitChanges = () => {
    if (chaptersToDelete.length > 0) {
      deleteChapters.mutate()
    }
    if (chapters.length > 0) {
      updateTree.mutate()
    }

    setChaptersToDelete([])
  }

  return (
    <>
      <Breadcrumbs
        maintitle="Panel"
        secondTitle={"Lista multibooków"}
        secondUrl={"/multibooki"}
        breadcrumbItem={title ? title : " Nowy multibook"}
        url={"/dashboard"}
      />
      <MetaTags>
        <title>
          {idMultibook ? "Edycja multibooka How2Edu" : "Nowy multibook How2Edu"}
        </title>
      </MetaTags>
      <Col>
        <div className="d-flex  justify-content-between h-100">
          <Card style={{ width: "100%", margin: "0" }}>
            <CardBody style={{ minHeight: "142px" }}>
              {isLoading || isChaptersLoading ? (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <div>
                  <h4>Tytuł:</h4>
                  <h3>
                    <Input
                      style={{ margin: "10px 20px 40px", width: "90%" }}
                      value={title}
                      onChange={handleChangeTitle}
                    ></Input>
                  </h3>
                  <h4>Opis:</h4>
                  <h3>
                    <Input
                      style={{ margin: "10px 20px 40px", width: "90%" }}
                      value={subtitle}
                      onChange={handleChangeSubtitle}
                    ></Input>
                  </h3>
                  {chapters?.length === 0 || notValid ? (
                    <div>
                      <Alert color="warning" className="mb-2">
                        {chapters?.length === 0
                          ? "Uwaga: Multibook musi posiadać chociaż jeden Rozdział inaczej nie będzie wyświetlony w aplikacji!"
                          : moodTests
                          ? "Testy Gibsa i Gallupa nie mogą mieć podrozdziałów oraz mogą występować tylko pojedyńczo!"
                          : notValid
                          ? "Rozdziały testowe nie mogą posiadać dzieci oraz nie powinny ze sobą sąsiadować"
                          : null}
                      </Alert>
                    </div>
                  ) : null}
                  <h4>Rozdziały:</h4>
                  <Chapters
                    visibilityState={visiblityState}
                    setVisiblityState={setVisiblityState}
                    multibookId={multibook?.idMultibook}
                    chapters={chapters}
                    setChapters={setChapters}
                    chaptersToDelete={chaptersToDelete}
                    setChaptersToDelete={setChaptersToDelete}
                    setIsNotValid={setIsNotValid}
                    notValid={notValid}
                    setMoodTests={setMoodTests}
                    setSelectedIdSubject={setSelectedSubject}
                    setSomethingChanged={setSomethingChanged}
                    setTitleModal={setTitleModal}
                    somethingChanged={somethingChanged}
                    titleModal={titleModal}
                  />{" "}
                </div>
              )}
            </CardBody>
          </Card>

          <div className="d-flex align-items-end flex-column bd-highlight ">
            <Card className="ms-2 " style={{ minHeight: "100%" }}>
              <RightBar
                notValid={notValid}
                chapters={chapters}
                className="h-100"
                title={title}
                subtitle={subtitle}
                multibookTypes={multibookType}
                access={access}
                visibilityState={visiblityState}
                submitChapterChanges={handleSubmitChanges}
                abortChanges={handleSubmitAbort}
                multibookId={multibookId}
                multibook={multibook}
                categoriesList={categories}
                setIdMultibook={setIdMultibook}
                selectedSubject={selectedSubject}
                setSomethingChanged={setSomethingChanged}
                setTitleModal={setTitleModal}
              />
            </Card>
          </div>
        </div>
      </Col>
    </>
  )
}
export default Multibook
