const SearchInput = props => {
  function debounce(func, timeout = 800) {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }
  const processChange = debounce(e => keyWordHandler(e))
  function keyWordHandler(e) {
    props.setSearch(e.target.value)
  }

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
        className="app-search d-none d-lg-block"
        style={{ width: "100%" }}
      >
        <div className="position-relative">
          <input
            type="text"
            className="form-control"
            style={{ backgroundColor: "rgb(240, 240, 240)" }}
            placeholder="Wyszukaj!"
            onChange={e => {
              processChange(e)
            }}
          />
          <span className="fa fa-search"></span>
        </div>
      </form>
    </div>
  )
}
export default SearchInput
