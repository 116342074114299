import React, { useEffect, useRef } from "react"

import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree"
import CustomTestComponentRenderer from "../../../../../../components/CustomNodeContentRenderer/CustomTestComponentRenderer"

import { useState } from "react"
import { Input, Label } from "reactstrap"
import Switch from "react-switch"
import PointCounter from "./PointCounter"
import Wysiwgw from "components/Common/Wyswigw"

const QuestionChoice = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  chapterType,
  inteligentChangeSensorAlpha,
  chapterComponentsToDelete,
}) => {
  const divRef = useRef()
  const { extended, content, idChapterContent } = componentInfo
  const [options, setOptions] = useState(
    content
      ? content?.options
      : [
          {
            content: "",
            isCorrect: "false",
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
          },
          {
            content: "",
            isCorrect: "false",
            id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
          },
        ]
  )
  const [question, setQuestion] = useState(content ? content?.question : "")
  const [points, setPoints] = useState(content ? content?.score : 1)

  useEffect(() => {
    setQuestion(content ? content?.question : "")
  }, [chapterComponentsToDelete?.length])

  useEffect(() => {
    if (chapterType === "gallup_test" && options?.length === 0) {
      const opt = [
        {
          content: "Tak",
          isCorrect: "true",
          score: points,
        },
        {
          content: "Nie",
          isCorrect: "false",
          score: points,
        },
      ]
      updateComponent(opt, question)
      setOptions(opt)
    }
  }, [])

  useEffect(() => {
    updateComponent(options, question, points)
  }, [points, options])

  const updateComponent = (options, question) => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = {
          options: options,
          question: question,
          score: points,
        }
        component.height =
          chapterType === "stage_test"
            ? options
              ? options?.length * 90 + 700
              : 380
            : options
            ? options?.length * 90 + 550
            : 225
      }
      return component
    })
    setChapterComponents(newComponents)
  }

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(options, question, points)
    }
  }, [extended])

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Nie
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Tak
      </div>
    )
  }
  const addOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const newOptions = addNodeUnderParent({
      treeData: options,

      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        content: "",
        isCorrect: "false",
        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
    }).treeData
    setOptions(newOptions, points)
    updateComponent(newOptions, question)
    setPoints(points)
  }

  const changeListElement = (tree, id, value) => {
    tree = tree?.map((element, index) => {
      if (index === id) {
        value = [...value]
        value = value.filter(char => char != "\n")

        return {
          content: value.join(""),
          isCorrect: element.isCorrect,
          id: index,
        }
      } else {
        return element
      }
    })
    return tree
  }

  const handleChangeName = (index, value) => {
    inteligentChangeSensorAlpha()
    let tree = options.map(option => option)
    tree = changeListElement(tree, index, value)
    setOptions(tree)
  }

  const handleOnBlur = () => {
    updateComponent(options, question)
  }

  const removeOption = rowInfo => {
    inteligentChangeSensorAlpha()
    const remove = removeNodeAtPath({
      treeData: options,
      path: rowInfo.path,
      getNodeKey,
    })
    setOptions(remove)
    updateComponent(remove, question)
  }

  const enoughAnswers = () => {
    return componentInfo?.content?.options.length <= 2
  }

  function handleChangeQuestion(event, editor) {
    inteligentChangeSensorAlpha()
    const data = editor.getData()
    if (question.length < 400) {
      setQuestion(data)
      updateComponent(options, data)
    }
  }

  const getNodeKey = ({ treeIndex }) => treeIndex
  const componentHeight = options?.length * 90

  if (!extended) {
    return null
  }

  return (
    <React.Fragment>
      <div className="componentField">
        <Label>Pytanie</Label>
        <Wysiwgw content={handleChangeQuestion} value={question}></Wysiwgw>
      </div>
      {chapterType === "stage_test" ? (
        <div className="points">
          <Label>Punkty za poprawną odpowiedź</Label>
          <PointCounter
            inteligentChangeSensorAlpha={inteligentChangeSensorAlpha}
            chapterType={chapterType}
            element={points}
            setPoints={setPoints}
          />
        </div>
      ) : null}
      <div className="componentField">
        <Label>Odpowiedzi</Label>
      </div>
      <div
        style={{
          height:
            chapterType === "gallup_test"
              ? componentHeight + 300 + "px"
              : componentHeight + 100 + "px",
        }}
        ref={divRef}
      >
        <button
          disabled={options?.length > 9}
          onClick={() => addOption({ path: [], node: { parent: 0 } })}
          className="btn btn-success"
        >
          Dodaj
        </button>
        <SortableTree
          nodeContentRenderer={CustomTestComponentRenderer}
          treeData={options}
          onChange={treeData => {
            setOptions(treeData)
            updateComponent(treeData, question)
            setPoints(points)
          }}
          maxDepth={1}
          rowHeight={90}
          generateNodeProps={rowInfo => {
            let nodeProps = {
              title: (
                <Input
                  type="textarea"
                  maxLength={160}
                  rows={2}
                  className="question_order_input"
                  value={rowInfo.node.content}
                  placeholder={"Nowy komponent"}
                  resize="none"
                  onChange={e =>
                    handleChangeName(rowInfo.treeIndex, e.target.value)
                  }
                  onBlur={handleOnBlur}
                  style={{ border: "none" }}
                />
              ),

              subtitle:
                rowInfo.node.content?.length < 400 ? (
                  <p className="char_counter subtitle_wrapper">
                    {" "}
                    {rowInfo.node.content?.length + "/160"}
                  </p>
                ) : (
                  <p className="char_counter subtitle_wrapper red">
                    {" "}
                    {rowInfo.node.content?.length + "/160"}
                  </p>
                ),

              buttons: [
                <div className="switch_button">
                  {chapterType !== "gibs_test" ? (
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor="#02a499"
                      offColor="#ec4561"
                      onChange={() => {
                        inteligentChangeSensorAlpha()
                        let opt = options.map(option => option)
                        opt[rowInfo.treeIndex].isCorrect =
                          opt[rowInfo.treeIndex].isCorrect === "true"
                            ? "false"
                            : "true"
                        setOptions(opt, points)
                        updateComponent(opt, question)
                      }}
                      checked={rowInfo.node.isCorrect === "true"}
                    />
                  ) : null}
                </div>,

                <button
                  className="btn btn-primary button_order_input"
                  key={rowInfo.node.id}
                  onClick={() => removeOption(rowInfo)}
                  disabled={enoughAnswers()}
                >
                  Usuń
                </button>,
              ],
            }
            return nodeProps
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default QuestionChoice
