import React, { useState, useEffect } from "react"

import { Input } from "reactstrap"

const Image = ({
  componentInfo,
  chapterComponents,
  setChapterComponents,
  inteligentChangeSensorAlpha,
}) => {
  const { idChapterContent, extended, content } = componentInfo
  const [selectedImage, setSelectedImage] = useState(content ? content : null)
  const [svgObject, setSvgObject] = useState(content ? content : null)
  const [isDeleted, setIsDeleted] = useState(true)
  const [svgContent, setSvgContent] = useState("")
  const [order, setOrder] = useState(componentInfo.order)

  const center = {
    display: "block",
    marginLeft: "auto",
    marignRight: "auto",
    width: "64%",
    marginTop: "40px",
  }
  useEffect(() => {
    setOrder(componentInfo.order)
  }, [componentInfo.order])

  useEffect(() => {
    if (typeof selectedImage !== "string") {
      setIsDeleted(false)
    }
  }, [selectedImage])

  useEffect(() => {
    if (svgObject?.content !== undefined) {
      setSelectedImage(svgObject)
      const svgToDataURL = require("svg-to-dataurl")
      const dataUrl = svgToDataURL(svgObject?.content)
      setSvgContent(dataUrl)
    }
  }, [selectedImage])

  useEffect(() => {
    if (componentInfo.height === 53 || !componentInfo.height) {
      updateComponent(selectedImage)
    }
  }, [extended])

  const updateComponent = opt => {
    const newComponents = chapterComponents.map(component => {
      if (component.idChapterContent === idChapterContent) {
        component.content = opt
        component.height = 300
      }
      return component
    })

    setChapterComponents(newComponents)
  }
  useEffect(() => {
    if (componentInfo.order === order) {
      setSelectedImage(content)
      setSvgObject(content)
    }
  }, [order])

  if (!extended) {
    return null
  }

  return (
    <div style={{ maxWidth: "50%" }} className="d-flex justify-content-start">
      <div>
        {selectedImage === null ? (
          <div
            className="dropzone"
            style={{ minHeight: "50px", height: "200px", padding: "0" }}
          >
            {" "}
            <i style={center} className="ion ion-md-image display-1"></i>
          </div>
        ) : (
          selectedImage && (
            <div>
              <div>
                {selectedImage.content === undefined ? (
                  <img
                    style={{
                      minHeight: "50px",
                      height: "200px",
                      padding: "0",
                      maxWidth: "300px",
                    }}
                    className={"dropzone"}
                    alt="Nie znaleziono!"
                    src={
                      typeof selectedImage === "string"
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                    }
                  />
                ) : (
                  <img
                    style={{
                      minHeight: "50px",
                      height: "200px",
                      padding: "0",
                      maxWidth: "300px",
                    }}
                    className={"dropzone"}
                    alt="Nie znaleziono svg!"
                    src={svgContent}
                  />
                )}

                <br />
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    inteligentChangeSensorAlpha()
                    setSelectedImage(null)
                    setIsDeleted(false)
                    updateComponent(null)
                    setSvgObject(null)
                  }}
                >
                  Usuń
                </button>
              </div>
            </div>
          )
        )}
        {selectedImage ? null : (
          <Input
            className="mt-1"
            type="file"
            name="myImage"
            accept="image/png,image/jpeg,image/jpg,image/svg+xml"
            onChange={event => {
              inteligentChangeSensorAlpha()
              setIsDeleted(false)
              setSelectedImage(event.target.files[0])
              updateComponent(event.target.files[0])
            }}
          />
        )}
      </div>
      <div style={{ marginLeft: "15px" }}>
        <p>Obsługiwane typy plików:</p>
        <ul>
          <li>image/png</li>
          <li>image/jpeg</li>
          <li>image/jpg</li>
          <li>image/svg+xml</li>
        </ul>

        <p>Link do zdjęcia:</p>
        {selectedImage ? (
          selectedImage.url ? (
            <a target="_blank" href={selectedImage.url}>
              {selectedImage.url}
            </a>
          ) : typeof selectedImage === "string" ? (
            <a target="_blank" href={selectedImage}>
              {selectedImage}
            </a>
          ) : (
            "Link zostanie wygenerowany po zapisaniu zmian"
          )
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default Image
