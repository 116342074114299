import React, { useEffect, useState } from "react"
import SortableTree, {
  addNodeUnderParent,
  getVisibleNodeCount,
} from "@nosferatu500/react-sortable-tree"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useContext } from "react"
import { StoreContext } from "context/Store"
import Loader from "../components/UI/Loader"

import { removeNodeAtPath } from "react-sortable-tree"
import { Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import buildFormData from "../functions/buildFormData"
import SweetAlert from "react-bootstrap-sweetalert"
import { MetaTags } from "react-meta-tags"
import { DndContext } from "react-dnd"

const Categories = () => {
  const { api } = useContext(StoreContext)
  const queryClient = useQueryClient()
  const [categories, setCategories] = useState([])
  const [categoriesToDelete, setCategoriesToDelete] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [errorContent, setErrorContent] = useState("")
  const {
    isFetching,
    data: categoryData,
    refetch,
  } = useQuery("categories", () => api.get(`/admin/categories`), {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: data => {
      setCategories(
        makeTree(
          data?.data?.data?.map(category => {
            return {
              title: category.name,
              id: category.idCategory,
              parent: category.parent,
              order: category.order,
            }
          }),
          0
        )
      )
    },
  })

  useEffect(() => {
    if (categoryData !== undefined) {
      setCategories(
        makeTree(
          categoryData?.data?.data?.map(category => {
            return {
              title: category.name,
              id: category.idCategory,
              parent: category.parent,
              order: category.order,
            }
          }),
          0
        )
      )
    }
  }, [categoryData])

  const updateTree = useMutation(
    () =>
      api.post(
        "/admin/categories/update",
        buildFormData({
          categories: convertData(categories),
          deleted: categoriesToDelete,
        })
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("categories")
        setCategoriesToDelete([])
        setsuccess_msg(true)
      },
      onError: error => {
        queryClient.invalidateQueries("categories")
        seterror_dlg(true)
        setCategoriesToDelete([])

        if (
          error.response.data ===
          '<html>\r\n<head><title>404 Not Found</title></head>\r\n<body bgcolor="white">\r\n<center><h1>404 Not Found</h1></center>\r\n<hr><center>nginx/1.14.2</center>\r\n</body>\r\n</html>\r\n'
        ) {
          setErrorContent(
            "Wybrana kategoria jest aktualnie przypisana do multibooków i nie może być usunięta"
          )
        }
      },
    }
  )

  // const deleteCategories = useMutation(
  //   () =>
  //     api.post(
  //       "/admin/categories/remove",
  //       buildFormData({
  //         idCategory: categoriesToDelete,
  //       })
  //     ),
  //   {
  //     onSuccess: () => {
  //       refetch()
  //       queryClient.invalidateQueries("categories")
  //       setsuccess_msg(true)
  //     },
  //     onError: error => {
  //       if (error.response.data.message === "Cannot delete category in use") {
  //         setErrorContent(
  //           "Wybrana kategoria jest aktualnie przypisana do multibooków i nie może być usunięta"
  //         )
  //       }

  //       seterror_dlg(true)
  //     },
  //   }
  // )

  function makeTree(categories, parent) {
    const nodes = categories
      .sort((a, b) => (a.order < b.order ? -1 : 1))
      .map(category => category)
    return nodes
      .filter(node => node.parent === parent)
      .reduce(
        (tree, node) => [
          ...tree,
          {
            ...node,
            children: makeTree(nodes, node.id),
          },
        ],
        []
      )
  }

  if (!categories) {
    return null
  }
  const getNodeKey = ({ treeIndex }) => treeIndex

  const addSubategory = rowInfo => {
    const newCategories = addNodeUnderParent({
      treeData: categories,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      newNode: {
        title: "",
        parent: rowInfo.node.parent,
        children: [],
        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
      getNodeKey,
    }).treeData

    setCategories(newCategories)
  }

  const remove = (element, toDelete) => {
    if (element.id[0] !== "t") {
      toDelete.push(element.id)
    }

    for (let child of element.children) {
      remove(child, toDelete)
    }
  }

  const removeCategory = rowInfo => {
    const toDelete = categoriesToDelete
    remove(rowInfo.node, toDelete)
    setCategoriesToDelete(toDelete)
    setCategories(
      removeNodeAtPath({
        treeData: categories,
        path: rowInfo.path,
        getNodeKey,
      })
    )
  }

  const changeTreeElement = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.id === id) {
        element.title = value
        element.isChanged = true
      }
      if (element.children) {
        changeTreeElement(element.children, id, value)
      }
    })
  }

  const handleChangeName = (id, value) => {
    const tree = categories.map(category => category)
    changeTreeElement(tree, id, value, 1)
    setCategories(tree)
  }

  const convertData = categories => {
    return categories.map(category => {
      if (String(category.id).includes("temp")) {
        return { name: category.title, childs: convertData(category.children) }
      }
      return {
        idCategory: category.id,
        name: category.title,
        childs: convertData(category.children),
      }
    })
  }

  const handleSubmitChanges = () => {
    updateTree.mutate()
  }

  const count = getVisibleNodeCount({ treeData: categories })
  return (
    <div>
      <MetaTags>
        <title>Kategorie How2Edu</title>
      </MetaTags>

      <Breadcrumbs
        maintitle="Panel"
        breadcrumbItem="Lista Kategorii"
        url={"/dashboard"}
      />
      <Col style={{ minHeight: "730px" }}>
        <Card>
          <CardBody style={{ minHeight: "142px", position: "relative" }}>
            <CardTitle className="h4">Kategorie</CardTitle>
            {isFetching ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div style={{ height: count * 65 + 60 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    disabled={updateTree.isLoading}
                    className="btn btn-danger"
                    onClick={handleSubmitChanges}
                  >
                    Zapisz
                  </button>
                </div>

                <button
                  onClick={() =>
                    addSubategory({ path: [], node: { parent: 0 } })
                  }
                  className="btn btn-success"
                >
                  Nowa kategoria
                </button>
                <DndContext.Consumer>
                  {({ dragDropManager }) => (
                    <SortableTree
                      dragDropManager={dragDropManager}
                      treeData={categories}
                      isVirtualized={false}
                      onChange={treeData => setCategories(treeData)}
                      generateNodeProps={rowInfo => {
                        let nodeProps = {
                          title: (
                            <Input
                              value={rowInfo.node.title}
                              onChange={e =>
                                handleChangeName(
                                  rowInfo.node.id,
                                  e.target.value
                                )
                              }
                              style={{ border: "none" }}
                            />
                          ),
                          buttons: [
                            <button
                              style={{ marginRight: "10px" }}
                              className="btn btn-primary"
                              key={rowInfo.node._id}
                              onClick={() => addSubategory(rowInfo)}
                            >
                              Dodaj podkategorię
                            </button>,
                            <button
                              className="btn btn-primary"
                              key={rowInfo.node.id}
                              onClick={() => removeCategory(rowInfo)}
                              disabled={rowInfo.node.id === 1}
                            >
                              Usuń
                            </button>,
                          ],
                        }
                        return nodeProps
                      }}
                    />
                  )}
                </DndContext.Consumer>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      {error_dlg ? (
        <SweetAlert
          error
          title={errorContent !== "" ? errorContent : "Wystąpił błąd"}
          onConfirm={() => {
            seterror_dlg(false)
          }}
        ></SweetAlert>
      ) : null}

      {success_msg ? (
        <SweetAlert
          title="Operacja udana!"
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        ></SweetAlert>
      ) : null}
    </div>
  )
}

export default Categories
