import React, { useState, useEffect } from "react"
import SortableTree, {
  addNodeUnderParent,
  getVisibleNodeCount,
} from "@nosferatu500/react-sortable-tree"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useContext } from "react"
import { StoreContext } from "context/Store"
import Loader from "../components/UI/Loader"
import { DndContext } from "react-dnd"

import { removeNodeAtPath } from "react-sortable-tree"
import { Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import buildFormData from "../functions/buildFormData"
import SweetAlert from "react-bootstrap-sweetalert"
import { MetaTags } from "react-meta-tags"

const TestCategory = () => {
  const { api } = useContext(StoreContext)
  const queryClient = useQueryClient()
  const [categories, setCategories] = useState([])
  const [categoriesToDelete, setCategoriesToDelete] = useState([])
  const [success_msg, setsuccess_msg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)

  const { isLoading, data: testCategoryData } = useQuery(
    "testCategories",
    () => api.get(`/admin/section_categories`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        setCategories(
          data?.data?.data?.map(category => {
            return {
              title: category.name,
              id: category.idSectionCategory,
            }
          }),
          0
        )
      },
    }
  )

  useEffect(() => {
    setCategories(
      testCategoryData?.data?.data?.map(category => {
        return {
          title: category.name,
          id: category.idSectionCategory,
        }
      }),
      0
    )
  }, [testCategoryData])

  const updateTree = useMutation(
    () =>
      api.post(
        "/admin/section_categories/update",
        buildFormData({
          categories: convertData(categories),
        })
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("testCategories")
        if (categoriesToDelete.length > 0) {
          deleteCategories.mutate()
          setCategoriesToDelete([])
        } else {
          setsuccess_msg(true)
        }
      },
      onError: () => {
        seterror_dlg(true)
      },
    }
  )

  const deleteCategories = useMutation(
    () =>
      api.post(
        "/admin/section_categories/remove",
        buildFormData({
          idSectionCategory: categoriesToDelete,
        })
      ),
    {
      onSuccess: () => {
        setsuccess_msg(true)
      },
    }
  )

  if (!categories) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Loader />
      </div>
    )
  }
  const getNodeKey = ({ treeIndex }) => treeIndex

  const addSubategory = rowInfo => {
    const newCategories = addNodeUnderParent({
      treeData: categories,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      newNode: {
        title: "",

        id: "temp" + Math.floor(Math.random() * (1000000 - 0)) + 0,
      },
      getNodeKey,
    }).treeData

    setCategories(newCategories)
  }

  const remove = (element, toDelete) => {
    if (element.id[0] !== "t") {
      toDelete.push(element.id)
    }
  }

  const removeCategory = rowInfo => {
    const toDelete = categoriesToDelete
    remove(rowInfo.node, toDelete)
    setCategoriesToDelete(toDelete)
    setCategories(
      removeNodeAtPath({
        treeData: categories,
        path: rowInfo.path,
        getNodeKey,
      })
    )
  }

  const changeTreeElement = (tree, id, value) => {
    tree?.forEach(element => {
      if (element.id === id) {
        element.title = value
      }
      if (element.children) {
        changeTreeElement(element.children, id, value)
      }
    })
  }

  const handleChangeName = (id, value) => {
    const tree = categories.map(category => category)
    changeTreeElement(tree, id, value, 1)
    setCategories(tree)
  }

  const convertData = categories => {
    return categories.map(category => {
      if (String(category.id).includes("temp")) {
        return { name: category.title }
      }
      return {
        idSectionCategory: category.id,
        name: category.title,
      }
    })
  }

  const handleSubmitChanges = () => {
    updateTree.mutate()
  }

  const count = getVisibleNodeCount({ treeData: categories })
  return (
    <div>
      <MetaTags>
        <title>Kategorie testów How2Edu</title>
      </MetaTags>

      <Breadcrumbs
        maintitle="Panel"
        breadcrumbItem="Lista Kategorii testów"
        url={"/dashboard"}
      />
      <Col style={{ minHeight: "730px" }}>
        <Card>
          <CardBody style={{ minHeight: "142px", position: "relative" }}>
            <CardTitle className="h4">Kategorie testów Gallupa</CardTitle>
            {isLoading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div style={{ height: count * 65 + 60 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    disabled={
                      updateTree.isLoading || deleteCategories.isLoading
                    }
                    className="btn btn-danger"
                    onClick={handleSubmitChanges}
                  >
                    Zapisz
                  </button>
                </div>

                <button
                  onClick={() =>
                    addSubategory({ path: [], node: { parent: 0 } })
                  }
                  className="btn btn-success"
                >
                  Nowa kategoria
                </button>
                <DndContext.Consumer>
                  {({ dragDropManager }) => (
                    <SortableTree
                      dragDropManager={dragDropManager}
                      canDrag={false}
                      treeData={categories}
                      isVirtualized={false}
                      onChange={treeData => setCategories(treeData)}
                      generateNodeProps={rowInfo => {
                        let nodeProps = {
                          title: (
                            <Input
                              value={rowInfo.node.title}
                              onChange={e =>
                                handleChangeName(
                                  rowInfo.node.id,
                                  e.target.value
                                )
                              }
                              style={{ border: "none" }}
                            />
                          ),
                          buttons: [
                            <button
                              className="btn btn-primary"
                              key={rowInfo.node.id}
                              onClick={() => removeCategory(rowInfo)}
                            >
                              Usuń
                            </button>,
                          ],
                        }
                        return nodeProps
                      }}
                    />
                  )}
                </DndContext.Consumer>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      {error_dlg ? (
        <SweetAlert
          error
          title="wystąpił błąd"
          onConfirm={() => {
            seterror_dlg(false)
          }}
        ></SweetAlert>
      ) : null}

      {success_msg ? (
        <SweetAlert
          title="Operacja udana!"
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        ></SweetAlert>
      ) : null}
    </div>
  )
}

export default TestCategory
