import React, { useState } from "react"
import { Modal, Button } from "reactstrap"
import FormValidations from "../AdminsTabElements/FormValidations"
import DangerAlert from "./DangerAlert"
import { useForm } from "react-hook-form"

const UiModal = props => {
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  const { clearErrors } = useForm()
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  const styles = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          tog_xlarge()
        }}
        className="btn btn-primary waves-effect waves-light"
        data-toggle="modal"
        data-target=".bs-example-modal-xl"
      >
        Edytuj
      </button>
      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_fullscreen()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Administratorzy
          </h5>
          <Button
            onClick={() => {
              setmodal_xlarge(false)
              clearErrors()
            }}
            type="button"
            style={{ height: "10%" }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
        <div className="modal-body">
          <div style={styles}>
            <DangerAlert
              {...props}
              closePop={setmodal_xlarge}
              buttonName={"Usuń admina"}
              URL={"/admin/remove"}
              id={props.idAdmin}
              refresh={"admin"}
            />
            <FormValidations {...props} closePop={setmodal_xlarge} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UiModal
