import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import { useQuery } from "react-query"
import { Col, Row, Card, Alert } from "reactstrap"
import { useContext } from "react"
import { StoreContext } from "context/Store"
import buildFormData from "functions/buildFormData"
import { useCookies } from "react-cookie"
import Loader from "components/UI/Loader"
import bg from "../assets/images/bg.jpg"

const Login = () => {
  const { api, isUserLogged, setIsUserLogged, logo, setUserRole } =
    useContext(StoreContext)
  const [cookie, setCookie] = useCookies()
  const history = useHistory()
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [response, setResponse] = useState("")
  const [error, setError] = useState(false)

  useEffect(() => {
    if (isUserLogged) {
      history.replace("/dashboard")
    }
  }, [isUserLogged])

  const { refetch: sendData, isFetching } = useQuery(
    "login",
    () =>
      api.post(
        `/admin/login`,
        buildFormData({
          email: login,
          password: password,
        })
      ),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCookie("token", data.data.token, { path: "/", secure: true })
        setUserRole(data.data.rank.idAdminRank)
        setIsUserLogged(true)
        history.replace("/dashboard")
        setError(false)
      },
      onError: error => {
        setError(true)

        return error?.response?.data?.errors?.email ===
          "The email field must contain a previously existing value in the database."
          ? setResponse("Wprowadzono nieprawidłowy adres Email!")
          : setResponse("Wprowadzone hasło jest nieprawidłowe!")
      },
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    sendData()
  }

  const handleChangeUserName = e => {
    setLogin(e.target.value)
  }

  const handleChangePassword = e => {
    setPassword(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="account-pages">
        <MetaTags>
          <title>How2Edu Logowanie</title>
        </MetaTags>

        <div
          className="accountbg"
          style={{
            background: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>

        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logo} height="52" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">Witaj!</h4>
                    <p className="text-muted text-center">
                      Zaloguj się do panela administracyjnego How2Edu
                    </p>

                    <form className="mt-4" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="username">
                          Nazwa użytkownika
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="username"
                          placeholder="Nazwa użytkownika"
                          required
                          value={login}
                          onChange={handleChangeUserName}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="userpassword">
                          Hasło
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="userpassword"
                          placeholder="Hasło"
                          required
                          value={password}
                          onChange={handleChangePassword}
                        />
                      </div>
                      {isFetching ? (
                        <Loader />
                      ) : (
                        <Row className="mb-3">
                          <Col sm={6}></Col>

                          <Col sm="6" className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Logowanie
                            </button>
                          </Col>
                        </Row>
                      )}
                      <div className="pt-3">
                        {error ? (
                          <Alert color="danger" className="mb-0">
                            {response}
                          </Alert>
                        ) : null}
                      </div>

                      <Row className="mb-3 mt-2 mb-0">
                        <div className="col-12 mt-3">
                          <Link to="/password-recover">
                            <i className="mdi mdi-lock"></i> Zapomniałeś hasła?
                          </Link>
                        </div>
                      </Row>
                    </form>

                    <div className="mt-5 pt-4 text-center">
                      <p>© {new Date().getFullYear()} How2Edu</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
